import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from './CustomButtonStyle';

const customButton = (props) => {
  const { label, typeColor, variant, startIcon, endIcon, size, handleOnClickAction, enabled, classes, ref, ...rest } = props;

  return (
    <Button
      className={clsx(classes.button, classes[typeColor])}
      variant={variant}
      ref={ref}
      color={typeColor}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      onClick={handleOnClickAction}
      disabled={enabled !== true}
      {...rest}>
      {label}
    </Button>
  );
};

customButton.propTypes = {
  label: PropTypes.string.isRequired,
  typeColor: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.object,
  endIcon: PropTypes.string,
  size: PropTypes.string,
  handleOnClickAction: PropTypes.func,
  enabled: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

export const CustomButton = withStyles(styles)(customButton);
