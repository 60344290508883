export class ConsumerGroup {
  groupId;
  groupName;
  consumerId;
  active;

  constructor({ id = '', groupId = '', groupName = '', consumerId = '', active = null }) {
    this.id = id;
    this.groupId = groupId;
    this.groupName = groupName;
    this.consumerId = consumerId;
    this.active = active;
  }

  getViewModel() {
    return {
      id: this.id,
      consumerId: this.consumerId ?? '',
      groupId: this.groupId ?? '',
      groupName: this.groupName ?? ''
    };
  }
}

export const transform = ({ id, consumerId, groupId, groupName }) => {
  return { id: id, groupId: groupId, consumerId: consumerId, groupName: groupName };
};
