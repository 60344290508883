import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { CustomModal } from '../../shared';
import { UserForm } from '../forms/UserForm';
import { styles } from './styles';

const CreateUser = ({ openDialog, onCloseDialog, classes, users, onSubmit, scopesList }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const onValidationChange = (isFormValid) => {
    setEnableButton(isFormValid);
  };

  return (
    <Grid container spacing={2}>
      <CustomModal
        key="modal-create-user"
        className={classes.root}
        dialogTitle="Create User"
        openDialog={openDialog}
        onCloseDialog={onCloseDialog}
        arrayOfButtons={[
          {
            label: 'Cancel',
            color: 'default',
            enabled: true,
            action: () => onCloseDialog()
          },
          {
            label: 'Ok',
            variant: 'contained',
            color: 'primary',
            enabled: enableButton,
            action: () => setIsSaving(true)
          }
        ]}>
        <Grid item xs={12} key="modal-form-wrapper">
          <UserForm
            classes={classes}
            isSaving={isSaving}
            users={users}
            scopesList={scopesList}
            onSave={() => setIsSaving(false)}
            onValidationChange={onValidationChange}
            onSubmit={onSubmit}
          />
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export const CreateUserModal = withStyles(styles)(CreateUser);
