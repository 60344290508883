import * as COLORS from '../sharedConstants';

export const styles = {
  root: {
    alignItems: 'center',
    border: 'solid 1px #d9d8da',
    display: 'flex',
    padding: '1px 2px'
  },
  inputText: {
    flex: 1,
    width: '100%'
  },
  label: {
    marginBottom: '5px',
    fontSize: '1rem',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
  },
  labelControl: {
    color: COLORS.ERROR_MEDIUM
  }
};
