import { InputLabel, Snackbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

import { CustomButton } from '../CustomButton';
import { convertBytesToMbsOrKbs, readFile } from './CustomFileUploadHelpers';
import SnackbarContentWrapper from './CustomFileUploadSnackbarContent';
import { defaultSnackbarAnchorOrigin, styles } from './CustomFileUploadStyle';

/**
 * This components creates a Material-UI Dropzone
 */

const CustomFileUploadComponent = (props) => {
  const [fileObjects, setFileObjects] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState('success');
  const [fileName, setFileName] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const INITIAL_STATE = [];

  useEffect(
    () =>
      // returned function will be called on component unmount
      () => {
        if (props.clearOnUnmount) {
          setFileObjects(INITIAL_STATE);

          if (props.onChange) {
            props.onChange(INITIAL_STATE);
          }
        }
      },
    []
  );

  const handleDropAccepted = async (acceptedFiles) => {
    const totalFiles = fileObjects.length + acceptedFiles.length;
    const isSingleFile = props.filesLimit === 1;

    if (isSingleFile) {
      setFileObjects(INITIAL_STATE);
    }

    // Valide don't reach the limit of allowed files
    if (totalFiles > props.filesLimit && !isSingleFile) {
      setOpenSnackBar(true);
      setSnackbarMessage(props.getFileLimitExceedMessage(props.filesLimit));
      setSnackbarVariant('error');

      return;
    }

    // Retrieve fileObjects data
    const currentFilesSelected = await Promise.all(
      acceptedFiles.map(async (currentFile) => {
        const data = await readFile(currentFile);

        return {
          fileName: currentFile.name,
          type: currentFile.type,
          data
        };
      })
    );

    // Display message
    const message = currentFilesSelected.reduce((msg, fileObj) => {
      setFileName(fileObj.fileName);
      setFileUploaded(true);

      return msg + props.getFileAddedMessage(fileName);
    }, '');

    setOpenSnackBar(true);
    setSnackbarMessage(message);
    setSnackbarVariant('success');

    setFileObjects(currentFilesSelected);

    if (props.onChange) {
      props.onChange(currentFilesSelected);
    }
  };

  const handleDropRejected = (rejectedFiles, evt) => {
    let message = '';

    rejectedFiles.forEach((rejectedFile) => {
      message = props.getDropRejectMessage(rejectedFile, props.acceptedMimeTypes, props.maxFileSize);
    });
    if (props.onDropRejected) {
      props.onDropRejected(rejectedFiles, evt);
    }

    setOpenSnackBar(true);
    setSnackbarMessage(message);
    setSnackbarVariant('error');
  };

  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };

  return (
    <>
      <InputLabel variant="standard" className={props.classes.labelText}>
        {props.label}
      </InputLabel>
      <Dropzone
        {...props.dropzoneProps}
        accept={props.acceptedMimeTypes}
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
        maxSize={props.maxFileSize}
        multiple={false}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            {...getRootProps()}
            className={clsx(
              props.classes.root,
              props.dropzoneClass,
              isDragActive && props.classes.active,
              !props.disableRejectionFeedback && isDragReject && props.classes.invalid
            )}>
            <input {...props.inputProps} {...getInputProps()} />
            <div className={props.classes.textContainer}>
              <CustomButton
                label="Choose file"
                size="small"
                variant="outlined"
                className={props.classes.button}
                enabled
                handleOnClickAction={() => {}}
              />
              <Typography variant="caption" component="p" className={clsx(props.classes.text, props.dropzoneParagraphClass)}>
                {fileUploaded ? `${fileName} loaded` : props.dropzoneText}
              </Typography>
            </div>
          </div>
        )}
      </Dropzone>

      {((typeof props.showAlerts === 'boolean' && props.showAlerts) ||
        (Array.isArray(props.showAlerts) && props.showAlerts.includes(snackbarVariant))) && (
        <Snackbar
          anchorOrigin={defaultSnackbarAnchorOrigin}
          autoHideDuration={6000}
          {...props.alertSnackbarProps}
          open={openSnackBar}
          onClose={handleCloseSnackbar}>
          <SnackbarContentWrapper onClose={handleCloseSnackbar} variant={snackbarVariant} message={snackbarMessage} />
        </Snackbar>
      )}
    </>
  );
}; // End dropzoneArea component

CustomFileUploadComponent.defaultProps = {
  label: 'File upload',
  acceptedMimeTypes: ['application/json'],
  filesLimit: 2,
  maxFileSize: 3000000,
  dropzoneText: 'or drag and drop files here',
  disableRejectionFeedback: false,
  showAlerts: true,
  alertSnackbarProps: {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom'
    },
    autoHideDuration: 6000
  },
  clearOnUnmount: true,
  initialFiles: [],
  getFileLimitExceedMessage: (filesLimit) => `Maximum allowed number of files exceeded. Only ${filesLimit} allowed`,
  getFileAddedMessage: (fileName) => `File ${fileName} successfully added.`,
  getDropRejectMessage: (rejectedFile, acceptedMimeTypes, maxFileSize) => {
    let message = rejectedFile?.errors[0]?.message;

    if (!acceptedMimeTypes.includes(rejectedFile.file.type)) {
      message += `. Please upload a .json extension file. `;
    }

    if (rejectedFile.size > maxFileSize) {
      message += `File is too big. Size limit is ${convertBytesToMbsOrKbs(maxFileSize)}. `;
    }

    return message;
  }
};

CustomFileUploadComponent.propTypes = {
  /** @ignore */
  classes: PropTypes.object.isRequired,
  /** The text for the label at the top of the component. */
  label: PropTypes.string,
  /** A list of file types to accept.
   * @see See [here](https://react-dropzone.js.org/#section-accepting-specific-file-types) for more details.
   */
  acceptedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  /** Maximum number of files that can be loaded into the dropzone. */
  filesLimit: PropTypes.number,
  /** Maximum file size (in bytes) that the dropzone will accept. */
  maxFileSize: PropTypes.number,
  /** Text inside the dropzone. */
  dropzoneText: PropTypes.string,
  /** Custom CSS class name for dropzone container. */
  dropzoneClass: PropTypes.string,
  /** Custom CSS class name for text inside the container. */
  dropzoneParagraphClass: PropTypes.string,
  /** Disable feedback effect when dropping rejected files. */
  disableRejectionFeedback: PropTypes.bool,
  /** Shows styled Material-UI Snackbar when files are dropped, deleted or rejected. */
  showAlerts: PropTypes.bool, // PropTypes.oneOf([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Props to pass to the Material-UI Snackbar components.<br/>Requires `showAlerts` prop to be `true`.
   *
   * @see See [Material-UI Snackbar](https://material-ui.com/api/snackbar/#props) for available values.
   */
  alertSnackbarProps: PropTypes.object,
  /**
   * Props to pass to the Dropzone component.
   *
   * @see See [Dropzone props](https://react-dropzone.js.org/#src) for available values.
   */
  dropzoneProps: PropTypes.object,
  /**
   * Attributes applied to the input element.
   *
   * @see See [MDN Input File attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Additional_attributes) for available values.
   */
  inputProps: PropTypes.object,
  /** Clear uploaded files when component is unmounted. */
  clearOnUnmount: PropTypes.bool,
  /** List of URLs of already uploaded images.<br/>**Note:** Please take care of CORS. */
  initialFiles: PropTypes.arrayOf(PropTypes.string),
  /**
   * Get alert message to display when files limit is exceed.
   *
   * *Default*: "Maximum allowed number of files exceeded. Only ${filesLimit} allowed"
   *
   * @param {number} filesLimit The `filesLimit` currently set for the component.
   */
  getFileLimitExceedMessage: PropTypes.func,
  /**
   * Get alert message to display when a new file is added.
   *
   * *Default*: "File ${fileName} successfully added."
   *
   * @param {string} fileName The newly added file name.
   */
  getFileAddedMessage: PropTypes.func,
  /**
   * Get alert message to display when a file is rejected onDrop.
   *
   * *Default*: "File ${rejectedFile.name} was rejected."
   *
   * @param {Object} rejectedFile The file that got rejected
   * @param {string[]} acceptedFiles The `acceptedFiles` prop currently set for the component
   * @param {number} maxFileSize The `maxFileSize` prop currently set for the component
   */
  getDropRejectMessage: PropTypes.func,
  /**
   * Fired when the files inside dropzone change.
   *
   * @param {Array[ {fileName:string, data:string, type:string } ]} loadedFiles All the files currently loaded into the dropzone.
   */
  onChange: PropTypes.func,
  /**
   * Fired when the user drops files into the dropzone.
   *
   * @param {File[]} droppedFiles All the files dropped into the dropzone.
   */
  onDrop: PropTypes.func,
  /**
   * Fired when a file is rejected because of wrong file type, size or goes beyond the filesLimit.
   *
   * @param {File[]} rejectedFiles All the rejected files.
   */
  onDropRejected: PropTypes.func
};

export const CustomFileUpload = withStyles(styles)(CustomFileUploadComponent);
