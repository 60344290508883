import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import * as eventsConstants from './constants';

export const initialState = {
  data: [],
  isLoading: false,
  error: { hasError: false, message: '' },
  isCreateModalOpen: false,
  isDeleteModalOpen: false,
  isDetailsPanelOpen: false,
  isResultModalOpen: false,
  resultModalMode: '',
  isEditableMode: false,
  activeEventId: null,
  changes: {},
  isMajorUpdate: true
};

const failureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.activeEventId = initialState.activeEventId;
    draft.isResultModalOpen = true;
    draft.error = {
      hasError: true,
      message
    };
  });

const createFailureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = initialState.isLoading;
    draft.activeEventId = initialState.activeEventId;
    draft.isResultModalOpen = true;
    draft.error = {
      hasError: true,
      message
    };
  });

const updateFailureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = initialState.isLoading;
    draft.error = {
      hasError: true,
      message
    };
  });

const deleteSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    const filteredData = state.data.filter((e) => e.id !== action.payload);
    const hasPanelState = state.isDetailsPanelOpen && filteredData.length > 0;

    draft.isLoading = false;
    draft.error = initialState.error;
    draft.activeEventId = hasPanelState ? filteredData[0].id : initialState.activeEventId;
    draft.isDetailsPanelOpen = hasPanelState;
    draft.data = filteredData;
  });

const createSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.changes = initialState.changes;
    draft.data = [action.payload].concat(state.data);
    draft.isResultModalOpen = true;
    draft.isCreateModalOpen = initialState.isCreateModalOpen;
  });

const updateSuccessHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.changes = initialState.changes;
    draft.resultModalMode = 'update';
    draft.isResultModalOpen = true;
    draft.isCreateModalOpen = initialState.isCreateModalOpen;
    draft.isEditableMode = initialState.isEditableMode;
  });

const fetchSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.data = action.payload;
  });

const actionStartedHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = true;
  });

const closeCreateModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.activeEventId = initialState.activeEventId;
    draft.isDetailsPanelOpen = initialState.isDetailsPanelOpen;
    draft.changes = initialState.changes;
    draft.isCreateModalOpen = !action.payload;
  });

const closeDeleteModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isDeleteModalOpen = !action.payload;
  });

const closeResultModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isResultModalOpen = !action.payload;
    draft.resultModalMode = initialState.resultModalMode;
    draft.isEditableMode = initialState.isEditableMode;
    draft.isMajorUpdate = initialState.isMajorUpdate;
  });

const openDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = true;
  });

const closeDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = false;
  });

const downloadSuccessHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = false;
  });

const setActiveHandler = (state, action) =>
  produce(state, (draft) => {
    draft.changes = initialState.changes;
    draft.error = initialState.error;
    draft.activeEventId = action.payload;
  });

const formChangeHandler = (state, action) => {
  const { field, value } = action.payload;

  if (field === 'isMajorUpdate')
    return produce(state, (draft) => {
      draft.isMajorUpdate = value;
    });

  return produce(state, (draft) => {
    draft.changes = { ...state.changes, [field]: value };
  });
};

const validationSuccesHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
  });

const setEventEditable = (state) =>
  produce(state, (draft) => {
    draft.isEditableMode = true;
    draft.changes = { json: '' };
  });

const formCancelEdit = (state) =>
  produce(state, (draft) => {
    draft.changes = initialState.changes;
    draft.error = initialState.error;
    draft.isEditableMode = false;
    draft.isMajorUpdate = initialState.isMajorUpdate;
  });

export const eventReducer = handleActions(
  {
    [eventsConstants.EVENTS_CLOSE_DELETE_REDUCER]: closeDeleteModalHandler,
    [eventsConstants.EVENTS_CLOSE_EDIT_REDUCER]: closeCreateModalHandler,
    [eventsConstants.EVENTS_CLOSE_RESULT_REDUCER]: closeResultModalHandler,
    [eventsConstants.EVENTS_CREATE_FAILURE]: createFailureHandler,
    [eventsConstants.EVENTS_CREATE_STARTED]: actionStartedHandler,
    [eventsConstants.EVENTS_CREATE_SUCCESS]: createSuccessHandler,
    [eventsConstants.EVENTS_DELETE_FAILURE]: failureHandler,
    [eventsConstants.EVENTS_DELETE_STARTED]: actionStartedHandler,
    [eventsConstants.EVENTS_DELETE_SUCCESS]: deleteSuccessHandler,
    [eventsConstants.EVENTS_DETAILS_CLOSE_REDUCER]: closeDetailsPanelHandler,
    [eventsConstants.EVENTS_DETAILS_OPEN_REDUCER]: openDetailsPanelHandler,
    [eventsConstants.EVENTS_DOWNLOAD_FAILURE]: failureHandler,
    [eventsConstants.EVENTS_DOWNLOAD_STARTED]: actionStartedHandler,
    [eventsConstants.EVENTS_DOWNLOAD_SUCCESS]: downloadSuccessHandler,
    [eventsConstants.EVENTS_FETCH_FAILURE]: failureHandler,
    [eventsConstants.EVENTS_FETCH_STARTED]: actionStartedHandler,
    [eventsConstants.EVENTS_FETCH_SUCCESS]: fetchSuccessHandler,
    [eventsConstants.EVENTS_FORM_CANCEL_EDIT_REDUCER]: formCancelEdit,
    [eventsConstants.EVENTS_FORM_CHANGE_REDUCER]: formChangeHandler,
    [eventsConstants.EVENTS_SET_ACTIVE_REDUCER]: setActiveHandler,
    [eventsConstants.EVENTS_VALIDATION_FAILURE]: createFailureHandler,
    [eventsConstants.EVENTS_VALIDATION_SUCCESS]: validationSuccesHandler,
    [eventsConstants.EVENTS_SET_EDITABLE_REDUCER]: setEventEditable,
    [eventsConstants.EVENTS_UPDATE_SUCCESS]: updateSuccessHandler,
    [eventsConstants.EVENTS_UPDATE_FAILURE]: updateFailureHandler,
    [eventsConstants.EVENTS_UPDATE_STARTED]: actionStartedHandler
  },
  initialState
);
