import { CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider, createGenerateClassName, createTheme } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router/immutable';
import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';

import { PrivateRoute } from '../../components/shared/privateRoute';
import { store } from '../../configureStore';
import { commonSaga } from '../../redux/common/sagas';
import { withErrorBoundary } from '../../redux/errorBoundary';
import { browserHistory } from '../../utils/history';
import { injectSaga } from '../../utils/injectSaga';
import { Consumer } from './../consumers';
import { User } from './../users';
import { Callback, LoginContainer, currentUserManager } from '../auth';
import { EventType } from '../events';
import { Authentication, Fatal, Header, NavTabs, Unauthorized, UserLogOut } from '../shared';
import { Subscription } from '../subscriptions';
import { Topic } from '../topics';

const generateClassName = createGenerateClassName({
  productionPrefix: 'ens',
  disableGlobal: true
});

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});

const OidcProviderWrapper = (props) => {
  if (!currentUserManager) {
    return props.children;
  }

  return (
    <OidcProvider store={store} userManager={currentUserManager}>
      {props.children}
    </OidcProvider>
  );
};

const Routes = () => {
  const routes = [
    <Route key="callback" path="/callback" component={Callback} />,
    <Route key="login" path="/login" component={LoginContainer} exact />,
    <Route key="fatal" path="/fatal/:errorId?" component={Fatal} exact />,
    <Route key="unauthorized" path="/unauthorized" component={Unauthorized} exact />,
    <Route key="logout" path="/logout" component={UserLogOut} exact />,
    <PrivateRoute key="subscriptions" path="/subscriptions" component={Subscription} exact />,
    <PrivateRoute key="events" path="/events" component={EventType} exact />,
    <PrivateRoute key="consumers" path="/consumers" component={Consumer} exact />,
    <PrivateRoute key="users" path="/users" component={User} exact />,
    <PrivateRoute key="topics" path="/topics" component={Topic} exact />,
    <Route key="redirect" path="/" render={() => <Redirect to="/login" />} exact />
  ];

  return <>{routes}</>;
};

const RoutesWithCommonSaga = injectSaga({ key: 'commonSaga', saga: commonSaga })(Routes);

const App = () => (
  <Provider store={store}>
    <StylesProvider generateClassName={generateClassName}>
      <OidcProviderWrapper>
        <ConnectedRouter history={browserHistory}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <Header />
            <NavTabs />
            <Authentication ignoredRoutes={['/callback', '/login']} />
            <RoutesWithCommonSaga />
          </ThemeProvider>
        </ConnectedRouter>
      </OidcProviderWrapper>
    </StylesProvider>
  </Provider>
);

export default withErrorBoundary(App);
