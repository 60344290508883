import * as COLORS from '../sharedConstants';

export const styles = {
  root: {
    width: '100%'
  },
  inputLabel: {
    fontSize: '1rem',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif',
    marginBottom: '5px'
  },
  formControl: {
    color: 'black',
    width: 'inherit'
  },
  nativeSelect: {
    border: '1px solid #d9d9d9',
    display: 'flex',
    padding: '2px 4px',
    borderRadius: '5px',
    width: 'inherit',
    marginBottom: '5px'
  },
  formHelperText: {
    marginTop: '2px'
  },
  labelControl: {
    color: COLORS.ERROR_MEDIUM
  }
};
