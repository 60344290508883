import * as request from './genericRequest';

const SubscriptionsRoute = 'subscriptions';

export const getAllSubscriptions = async () => request.getApi(SubscriptionsRoute);
export const getAllSubscriptionsByCustomer = async (customer) => request.getApi(`${SubscriptionsRoute}/customer`, { customer });
export const getAllSubscriptionsById = async (id) => request.getApi(`${SubscriptionsRoute}/id`, { id });
export const putSubscriptionById = async (id, subscription) => request.putApi(SubscriptionsRoute, subscription, { id });
export const postSubscription = async (subscription) => request.postApi(SubscriptionsRoute, subscription);
export const deleteSubscriptionById = async (id) => request.deleteApi(SubscriptionsRoute, { id });
