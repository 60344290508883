import { Grid, Typography } from '@material-ui/core';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { CONSUMER_SCOPE, PUBLISHER_SCOPE } from '../../../utils/constants';
import { FormikAutocompleteField, FormikInputField } from '../../shared/formikInput';

export const UserForm = ({
  classes,
  onSave,
  isSaving,
  onSubmit,
  onValidationChange,
  users,
  scopesList,
  isEditMode = false,
  readOnly = false,
  initialValues = { clientId: '', scopesArray: [] }
}) => {
  const [disableScope, setDisableScope] = useState('');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: (values) => {
      const errors = {};
      if (!values.clientId) {
        errors.clientId = 'The ClientId is required.';
      } else if (users.some((c) => c.clientId === values.clientId) && !readOnly) {
        errors.clientId = 'The ClientId already exists.';
      } else if (!/^[a-zA-Z0-9.-]+$/i.test(values.clientId)) {
        errors.clientId = 'ClientId has invalid characters. Allowed characters: . - ';
      }
      if (!values.scopesArray.length) {
        errors.scopesArray = 'You must select at least one scope.';
      }
      return errors;
    },
    onSubmit: (values) => {
      const { clientId, scopesArray } = values;
      onSubmit({ clientId: clientId, scopes: scopesArray.map((s) => s.value), displayName: clientId });
    }
  });

  useEffect(() => {
    if (!readOnly) {
      onValidationChange(formik.dirty && formik.isValid);
    }
  }, [formik.dirty, formik.isValid, readOnly]);

  useEffect(() => {
    if (isSaving) {
      formik.handleSubmit();
      onSave();
    }
  }, [isSaving]);

  useEffect(() => {
    const selectedValues = formik.values.scopesArray.map((x) => x.name);
    if (selectedValues.find((element) => element === CONSUMER_SCOPE)) {
      setDisableScope(PUBLISHER_SCOPE);
    } else if (selectedValues.find((element) => element === PUBLISHER_SCOPE)) {
      setDisableScope(CONSUMER_SCOPE);
    } else {
      setDisableScope('');
    }
  }, [formik.values.scopesArray]);

  return (
    <>
      <FormikProvider value={formik}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={1}>
            <Grid key="userForm" item xs={12} className={classes.row}>
              <FormikInputField
                required={true}
                value={formik.values.clientId}
                placeholder="ClientId"
                name="clientId"
                label="ClientId"
                readOnly={readOnly}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{ inputProps: { maxLength: '60' } }}
                touched={formik.touched.clientId}
              />
              {!isEditMode && (
                <Typography align="justify" variant="caption" color="textSecondary">
                  The recommended naming pattern for clients is: &#123;customer name&#125;-consumer-&#123;incremental number&#125;
                </Typography>
              )}
              {isEditMode && (
                <FormikInputField
                  required={true}
                  value={formik.values.clientSecret}
                  placeholder="ClientSecret"
                  name="clientSecret"
                  label="ClientSecret"
                  readOnly={readOnly}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.clientSecret}
                />
              )}
              <FormikAutocompleteField
                getOptionDisabled={(option) => option.name === disableScope}
                required={true}
                multiple
                name="scopesArray"
                label="Scopes"
                options={scopesList}
                readOnly={readOnly}
                defaultValue={initialValues.scopesArray}
                value={formik.values.scopesArray}
                touched={formik.touched.scopesArray}
                filterSelectedOptions
              />
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </>
  );
};
