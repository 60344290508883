export const styles = {
  gridItem: {
    padding: '1em'
  },
  topicTitle: {
    marginLeft: '20px',
    marginBottom: '20px'
  },
  divider:{
    marginBottom: '15px',
    marginTop: '15px'
  }
};
