import { Box, Container, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React from 'react';

import { styles } from './styles';

const customPanelHeader = ({ title, classes, children }) => (
  <Container className={classes.items}>
    <Box className={classes.title}>
      <Typography variant="inherit">{title}</Typography>
    </Box>
    <Box className={classes.children}>{children}</Box>
  </Container>
);

customPanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  classes: PropTypes.object.isRequired
};

export const CustomPanelHeader = withStyles(styles)(customPanelHeader);
