import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomModal } from '../../shared';
import { SubscriptionForm } from '../forms/SubscriptionForm';
import { styles } from './styles';

const CreateSubscription = ({
  openDialog,
  onCloseDialog,
  onCreate,
  classes,
  subscription,
  handleChange,
  hasFormChanged,
  hasErrorFunc,
  customers,
  products,
  sponsors,
  studies,
  error
}) => (
  <Grid container spacing={2}>
    <CustomModal
      key="modal-create-subscription"
      className={classes.root}
      dialogTitle="Create Subscription"
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
      arrayOfButtons={[
        {
          label: 'Cancel',
          color: 'default',
          enabled: true,
          action: () => {
            onCloseDialog();
          }
        },
        {
          label: 'Ok',
          variant: 'contained',
          color: 'primary',
          enabled: hasFormChanged && !hasErrorFunc(subscription),
          action: () => {
            onCreate(subscription);
          }
        }
      ]}>
      <Grid item xs={12} key="modal-form-wrapper">
        <SubscriptionForm
          key="modal-create-subscription-form"
          handleChange={handleChange}
          subscription={subscription}
          classes={classes}
          customers={customers}
          products={products}
          sponsors={sponsors}
          studies={studies}
          error={error}
        />
      </Grid>
    </CustomModal>
  </Grid>
);

CreateSubscription.defaultProps = {
  openDialog: false
};

CreateSubscription.propTypes = {
  /**
   * @ignore
   */
  classes: PropTypes.object.isRequired,
  /**
   * openDialog indicate is the dialog is open or closed
   */
  openDialog: PropTypes.bool,
  /**
   * onCloseDialog fired when the user hits the close button from dialog component
   */
  onCloseDialog: PropTypes.func,
  /**
   * onCreate fired when the user hits the ok button from dialog component
   */
  onCreate: PropTypes.func,
  /**
   * handle change fired when the user changes a field
   */
  handleChange: PropTypes.func,
  /**
   * load subscription data
   */
  subscription: PropTypes.object,
  /**
   * load customers data
   */
  customers: PropTypes.array,
  /**
   * load products data
   */
  products: PropTypes.array,
  /**
   * load sponsors data
   */
  sponsors: PropTypes.array,
  /**
   * load sponsors data
   */
  studies: PropTypes.array,
  /**
   * load sponsors data
   */
  hasFormChanged: PropTypes.bool,
  /**
   * Checks changes have been done to the form
   */
  hasErrorFunc: PropTypes.func,
  /**
   * General error
   */
  error: PropTypes.object
};

export const CreateSubscriptionModal = withStyles(styles)(CreateSubscription);
