export const storage = {
  isSupported() {
    return hasStorage;
  },
  set(name, value) {
    if (hasStorage) {
      localStorage.setItem(name, value);
    }
  },
  get(name) {
    if (hasStorage) {
      const ret = localStorage.getItem(name);

      switch (ret) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return ret;
      }
    }

    return null; // no storage so can't get anything
  },
  delete(name) {
    if (hasStorage) {
      localStorage.removeItem(name);
    }
  }
};

const hasStorage = (() => {
  try {
    const x = '__storage_test__';

    localStorage.setItem(x, x);
    localStorage.removeItem(x);

    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
})();
