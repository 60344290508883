import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomComboBox, CustomSelect } from '../../shared';
import { FormError } from '../../shared/forms';
import { styles } from '../styles';
import { SourceCheckBoxGroup } from './SourceCheckBoxGroup';



const CreateUpdateSubscriptionForm = ({ classes, subscription, handleChange, customers, products, sponsors, studies, error }) => {

  const { productName, customer } = subscription;

  return (
    <form key="subscription-form-element" className={classes.root} noValidate autoComplete="off">
      <Grid key="subscription-form-grid" container spacing={2}>
        <Grid key="subscription-productName" item xs={12} className={classes.row}>
          <CustomSelect
              id="productName"
              key="product-name-select"
              enableLabelText
              labelText={"Product"}
              placeholderText="Select one..."
              visible
              isRequired
              options={products}
              onChangeEvent={(s) => handleChange('productName', s.value)}
              value={productName ?? ''}
          />
          <CustomComboBox
            id="subscription-customer"
            key="subscription-customer"
            enableLabelText
            labelText="Customer"
            placeholderText="Select one..."
            visible
            isRequired
            options={customers}
            value={customer ?? ''}
            onChangeEvent={(s) => handleChange('customer', s.value)}
          />
          <SourceCheckBoxGroup
            key="event-type-source"
            subscription={subscription}
            handleSelectionChange={handleChange}
            sponsors={sponsors}
            studies={studies}
          />
        </Grid>
        {error?.hasError && <FormError message={error.message} classes={classes} />}
      </Grid>
    </form>
  );
};

CreateUpdateSubscriptionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
  customers: PropTypes.array,
  products: PropTypes.array,
  sponsors: PropTypes.array,
  studies: PropTypes.array,
  error: PropTypes.object
};

export const SubscriptionForm = withStyles(styles)(CreateUpdateSubscriptionForm);
