import { TableColumnModel } from '../tableColumsModel';

const GroupNameColumn = new TableColumnModel('groupName', 'GroupName', 170, true, false);
const GroupIdColumn = new TableColumnModel('groupId', 'GroupId', 170, true, false);
const ConsumerGroupIdColumn = new TableColumnModel('id', 'Id', 170, true, false);
const ConsumerGroupActions = new TableColumnModel('actions', '', 170, false, true);

export const ConsumersGroupTableColumns = () => {
  return [ConsumerGroupIdColumn, GroupIdColumn, GroupNameColumn, ConsumerGroupActions];
};
