import * as subscriptionsConstants from './constants';

// Started
export const handleCreateSubscriptionStarted = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_CREATE_STARTED,
  payload
});
export const handleFetchSubscriptionStarted = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_FETCH_STARTED,
  payload
});
export const handleUpdateSubscriptionStarted = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_UPDATE_STARTED,
  payload
});
export const handleDeleteSubscriptionStarted = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_DELETE_STARTED,
  payload
});

// Success
export const handleCreateSubscriptionSuccess = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_CREATE_SUCCESS,
  payload
});

export const handleFetchSubscriptionSuccess = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_FETCH_SUCCESS,
  payload
});

export const handleUpdateSubscriptionSuccess = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_UPDATE_SUCCESS,
  payload
});

export const handleDeleteSubscriptionSuccess = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_DELETE_SUCCESS,
  payload
});

// Failure
export const handleCreateSubscriptionFailure = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_CREATE_FAILURE,
  payload
});

export const handleFetchSubscriptionFailure = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_FETCH_FAILURE,
  payload
});

export const handleUpdateSubscriptionFailure = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_UPDATE_FAILURE,
  payload
});

export const handleDeleteSubscriptionFailure = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_DELETE_FAILURE,
  payload
});

export const handleCloseCreateModal = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_CLOSE_EDIT_REDUCER,
  payload
});

export const handleCloseDeleteModal = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_CLOSE_DELETE_REDUCER,
  payload
});

export const handleCloseResultModal = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_CLOSE_RESULT_REDUCER,
  payload
});

export const handleSetActiveSubscription = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_SET_ACTIVE_REDUCER,
  payload
});

export const handleOpenDetailsPanel = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_DETAILS_OPEN_REDUCER,
  payload
});

export const handleCloseDetailsPanel = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_DETAILS_CLOSE_REDUCER,
  payload
});

export const handleFormChange = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_FORM_CHANGE_REDUCER,
  payload
});

export const handleCancelEdit = (payload) => ({
  type: subscriptionsConstants.SUBSCRIPTIONS_FORM_CANCEL_EDIT_REDUCER,
  payload
});
