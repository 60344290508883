import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomModal } from '../ui/ModalComponent/CustomModal';
import { styles } from './ResultModalstyles';

const ActionResultModal = ({ classes, openDialog, onCloseDialog, hasError, message, children }) => (
  <>
    <CustomModal
      maxWidth="md"
      className={classes.root}
      variant={hasError ? 'error' : 'success'}
      dialogTitle={hasError ? 'Error' : 'Success'}
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
      arrayOfButtons={[
        {
          label: 'Ok',
          variant: 'contained',
          color: 'primary',
          enabled: true,
          action: () => {
            onCloseDialog();
          }
        }
      ]}>
      <Grid key="resultModal" item xs={12}>
        <pre>
          <Typography variant="caption">{message}</Typography>
        </pre>

        {children}
      </Grid>
    </CustomModal>
  </>
);

ActionResultModal.propTypes = {
  /**
   * @ignore
   */
  classes: PropTypes.object.isRequired,
  /**
   * openDialog indicate is the dialog is open or closed
   */
  openDialog: PropTypes.bool,
  /**
   * onCloseDialog fired when the user hits the close button from dialog component
   */
  onCloseDialog: PropTypes.func,
  /**
   * hasError indicates if the form or component has any errors.
   */
  hasError: PropTypes.bool,
  /**
   * message to display on the result modal.
   */
  message: PropTypes.string,
  /**
   * children additional components to display on the modal.
   */
  children: PropTypes.element
};

export const ResultModal = withStyles(styles)(ActionResultModal);
