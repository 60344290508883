const prefix = 'EVENTS';

// Sagas
export const EVENTS_CREATE_FAILURE = `${prefix}_CREATE_FAILURE`;
export const EVENTS_CREATE_STARTED = `${prefix}_CREATE_STARTED`;
export const EVENTS_CREATE_SUCCESS = `${prefix}_CREATE_SUCCESS`;

export const EVENTS_DELETE_FAILURE = `${prefix}_DELETE_FAILURE`;
export const EVENTS_DELETE_STARTED = `${prefix}_DELETE_STARTED`;
export const EVENTS_DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;

export const EVENTS_UPDATE_FAILURE = `${prefix}_UPDATE_FAILURE`;
export const EVENTS_UPDATE_STARTED = `${prefix}_UPDATE_STARTED`;
export const EVENTS_UPDATE_SUCCESS = `${prefix}_UPDATE_SUCCESS`;

export const EVENTS_DOWNLOAD_FAILURE = `${prefix}_DOWNLOAD_FAILURE`;
export const EVENTS_DOWNLOAD_STARTED = `${prefix}_DOWNLOAD_STARTED`;
export const EVENTS_DOWNLOAD_SUCCESS = `${prefix}_DOWNLOAD_SUCCESS`;

export const EVENTS_FETCH_FAILURE = `${prefix}_FETCH_FAILURE`;
export const EVENTS_FETCH_STARTED = `${prefix}_FETCH_STARTED`;
export const EVENTS_FETCH_SUCCESS = `${prefix}_FETCH_SUCCESS`;

export const EVENTS_VALIDATION_FAILURE = `${prefix}_VALIDATION_FAILURE`;
export const EVENTS_VALIDATION_STARTED = `${prefix}_VALIDATION_STARTED`;
export const EVENTS_VALIDATION_SUCCESS = `${prefix}_VALIDATION_SUCCESS`;

// Reducer
export const EVENTS_CLOSE_DELETE_REDUCER = `${prefix}_CLOSE_DELETE_REDUCER`;
export const EVENTS_CLOSE_EDIT_REDUCER = `${prefix}_CLOSE_EDIT_REDUCER`;
export const EVENTS_CLOSE_RESULT_REDUCER = `${prefix}_CLOSE_RESULT_REDUCER`;

export const EVENTS_CREATE_REDUCER = `${prefix}_CREATE_REDUCER`;
export const EVENTS_CREATE_STATE_REDUCER = `${prefix}_CREATE_STATE_REDUCER`;
export const EVENTS_DELETE_REDUCER = `${prefix}_DELETE_REDUCER`;
export const EVENTS_DETAILS_CLOSE_REDUCER = `${prefix}_DETAILS_CLOSE_REDUCER`;
export const EVENTS_DETAILS_OPEN_REDUCER = `${prefix}_DETAILS_OPEN_REDUCER`;
export const EVENTS_FETCH_REDUCER = `${prefix}_FETCH_REDUCER`;
export const EVENTS_FORM_CHANGE_REDUCER = `${prefix}_FORM_CHANGE_REDUCER`;
export const EVENTS_SET_ACTIVE_REDUCER = `${prefix}_SET_ACTIVE_REDUCER`;
export const EVENTS_SET_EDITABLE_REDUCER = `${prefix}_SET_EDITABLE_REDUCER`;
export const EVENTS_FORM_CANCEL_EDIT_REDUCER = `${prefix}_FORM_CANCEL_EDIT_REDUCER`;
