import { TableColumnModel } from '../tableColumsModel';
const ConsumerIdColumn = new TableColumnModel('id', 'Id', 170, true, false);
const UserNameColumn = new TableColumnModel('userName', 'ClientId', 170, true, false);
const TopicNameColumn = new TableColumnModel('topicName', 'TopicName', 170, true, false);
const ConsumerSubscriptionsCountColumn = new TableColumnModel('subscriptionsCount', 'Subscriptions Count', 170, true, false);
const ConsumerActions = new TableColumnModel('actions', '', 170, false, true);

export const ConsumersTableColumns = () => {
  return [ConsumerIdColumn, UserNameColumn, TopicNameColumn, ConsumerSubscriptionsCountColumn, ConsumerActions];
};
