export const headerStyles = {
  root: {
    padding: '16px'
  },
  title: {
    flex: '1 1',
    fontWeight: '600',
    margin: 'auto'
  }
};
