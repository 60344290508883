/* eslint-disable no-prototype-builtins */
import { call, put, takeLatest } from 'redux-saga/effects';

import { putEventById } from '../../../services/api/event';
import * as eventActions from '../actions';
import * as eventConstants from '../constants';

// Consideration: "IsMajorUpdate" should be sent in a new object to the paylod ( Create a new object with the event info + the IsMajorUpdate)
function* updateEvent(action) {
  const { payload } = action;

  try {
    if (!payload) throw new Error('Incorrect Event.');

    const response = yield call(putEventById, payload.id, payload.isMajorUpdate, payload);

    if (response?.status === 200) {
      yield put(eventActions.handleUpdateEventSuccess(new Event(payload)));
      yield put(eventActions.handleFetchEventStarted());
      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(eventActions.handleUpdateEventailure(error));
  }
}

export function* watchUpdateEvent() {
  yield takeLatest(eventConstants.EVENTS_UPDATE_STARTED, updateEvent);
}
