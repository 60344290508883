import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { getTokenMappingStatus } from '../../redux/auth/selectors';
import { customTabStyles, customTabsStyles, navTabsStyles } from './navigationTabsStyle';

const tabValues = ['/subscriptions', '/events', '/consumers', '/topics', '/users'];

const CustomTabs = withStyles(customTabsStyles)(Tabs);

const CustomTab = withStyles(customTabStyles)((props) => <Tab {...props} />);

const NavigationTabs = ({ history, location: { pathname }, classes }) => {
  const [value, setValue] = useState(pathname);
  const tokenMappingStatus = useSelector(getTokenMappingStatus);

  const handleChange = (event, newValue) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      history.push(newValue);
    }

    setValue(newValue);
  };

  if (!tabValues.includes(value)) handleChange(null, tabValues[0]);

  return (tokenMappingStatus === 'OK' &&
    <div className={classes.root}>
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Subscriptions" component={Link} to="/subscriptions" value="/subscriptions" />
        <CustomTab label="Events" component={Link} to="/events" value="/events" />
        <CustomTab label="Consumers" component={Link} to="/consumers" value="/consumers" />
        <CustomTab label="Users" component={Link} to="/users" value="/users" />
        <CustomTab label="Admin" component={Link} to="/topics" value="/topics" />
      </CustomTabs>
    </div>
  );
};

export const NavTabs = compose(withStyles(navTabsStyles))(withRouter(NavigationTabs));
