export const CreateHttpErrorResponseHandler = (history) => {
  const unauthorizedResponse = () => history.push('/unauthorized');

  const unauthenticatedResponse = () => {
    if (window.location.pathname.endsWith('login')) {
      return;
    }

    history.push('/login', { state: window.location.pathname });
  };

  const serverErrorResponse = (response) => {
    let route = '/fatal';

    if (response.referenceId) {
      route = `${route}/${response.referenceId}`;
    }

    history.push(route);
  };

  return (response) => {
    if (response.status === 401) {
      unauthenticatedResponse();
    }

    if (response.status === 403) {
      unauthorizedResponse();
    }

    if (response.status === 500) {
      serverErrorResponse(response);
    }
  };
};
