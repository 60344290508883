import { all } from 'redux-saga/effects';

import { watchCreateSubscription } from './create';
import { watchDeleteSubscription } from './delete';
import { watchFetchSubscription } from './fetch';
import { watchUpdateSubscription } from './update';

export function* subscriptionSaga() {
  yield all([watchFetchSubscription(), watchDeleteSubscription(), watchCreateSubscription(), watchUpdateSubscription()]);
}
