export const styles = (theme) => ({
  panel: {
    overflow: 'auto',
    height: '66vh',
    padding: '1em',
    [theme.breakpoints.only('xs')]: {
      height: '60vh'
    },
    [theme.breakpoints.only('sm')]: {
      height: '65vh'
    },
    [theme.breakpoints.up('md')]: {
      height: '70vh'
    }
  },
  items: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '6em'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    alignSelf: 'center'
  },
  children: {
    alignSelf: 'center'
  }
});
