import { Box, FormControl, FormHelperText, InputLabel, NativeSelect } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from './CustomSelectStyle';

const CustomSelectComponent = (props) => {
  const {
    id,
    enableLabelText,
    labelText,
    defaultValue,
    placeholderText,
    isRequired,
    options,
    onChangeEvent,
    visible,
    classes,
    value,
    disabled,
    ...rest
  } = props;
  const [isNoneValueSelected, setIsNoneValueSelected] = React.useState(false);
  const error = isRequired && isNoneValueSelected;

  const handleOnChange = (event) => {
    const { value } = event.target;

    setIsNoneValueSelected(value === '');
    onChangeEvent({ value: value });
  };

  const handleOnBlur = (event) => {
    const { value } = event.target;

    setIsNoneValueSelected(value === '');
  };

  return (
    <>
      {visible ? (
        <Box component="div" className={classes.root}>
          {enableLabelText ? (
            <InputLabel className={classes.inputLabel} htmlFor={id}>
              {labelText}
            </InputLabel>
          ) : null}
          <FormControl required={isRequired} className={classes.formControl} error={error}>
            <NativeSelect
              disabled
              className={classes.nativeSelect}
              value={value || ''}
              placeholder={placeholderText}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              inputProps={{
                id
              }}
              {...rest}>
              <option value="" style={{ color: '#d9d9d9' }}>
                Select one...
              </option>
              {options.map((e, i) => (
                <option key={`${e.key}_${i}`} value={e.value}>
                  {e.name}
                </option>
              ))}
            </NativeSelect>
            {error && <FormHelperText className={classes.labelControl}>One option must be selected</FormHelperText>}
          </FormControl>
        </Box>
      ) : null}
    </>
  );
};

// Typed props
CustomSelectComponent.propTypes = {
  id: PropTypes.string,
  enableLabelText: PropTypes.bool,
  labelText: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholderText: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChangeEvent: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

export const CustomSelect = withStyles(styles)(CustomSelectComponent);
