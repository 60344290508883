import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './redux/rootReducer';
import { browserHistory } from './utils/history';
import { CreateHttpErrorResponseHandler, HttpClientConfig, httpClient } from './utils/httpClient';

export function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  if (window.NODE_ENV !== 'production' && typeof window === 'object') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  const errorResponseHandler = CreateHttpErrorResponseHandler(browserHistory);

  const store = createStore(createReducer({}, browserHistory), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  const config = {
    tokenCallback: () => {
      const state = store.getState();

      if (state.oidc && state.oidc.user) {
        return state.oidc.user.id_token;
      }

      return null;
    },
    baseURL: window.REACT_APP_BASE_ROUTE,
    standardErrorResponseHandler: errorResponseHandler
  };

  httpClient.initialize(new HttpClientConfig(config));

  if (module.hot) {
    module.hot.accept('./redux/rootReducer', () => {
      store.replaceReducer(createReducer(store.injectedReducers, browserHistory));
    });
  }

  return store;
}

export const store = configureStore({}, browserHistory);
