import React from 'react';

export const Unauthorized = () => {
  return (
    <div style={{ paddingLeft: '15px' }}>
      <h1>Sorry,</h1>
      <p>you do not have access to do that.</p>
      <p>Please contact your manager to request access.</p>
    </div>
  );
};
