import * as request from './genericRequest';

const ConsumersRoute = 'consumers';
const SubscribedEventsRoute = 'subscribedEvents';

export const getAllConsumers = async () => request.getApi(ConsumersRoute);
export const postConsumer = async (consumer) => request.postApi(ConsumersRoute, consumer);
export const putConsumerByUserName = async (userName, consumer) => request.putApi(`${ConsumersRoute}/${userName}`, consumer);
export const deleteConsumerByUserName = async (userName) => request.deleteApi(`${ConsumersRoute}/${userName}`);
export const getSubscribedEventsByTopicName = async (topicName) => request.getApi(`${SubscribedEventsRoute}/${topicName}`);
