import { all } from 'redux-saga/effects';

import { watchCreateConsumer } from './create';
import { watchDeleteConsumer } from './delete';
import { watchFetchConsumer } from './fetch';
import { watchUpdateConsumer } from './update';

export function* consumerSaga() {
  yield all([watchCreateConsumer(), watchUpdateConsumer(), watchDeleteConsumer(), watchFetchConsumer()]);
}
