export const styles = (theme) => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440,
    '& table > thead > tr > th:nth-child(1)': {
      display: 'none'
    },
    '& table > tbody > tr > td:nth-child(1)': {
      display: 'none'
    },
    '& table > tbody > tr > td': {
      cursor: 'pointer'
    }
  }
});
