import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  getAllProducts,
  getAllCustomers,
  getAllStudies,
  getAllSponsors,
  getStudiesByCustomer,
  getStudiesByCustomerAndSponsor,
  getSponsorsByCustomer
} from '../../../services/api/common';

import * as authConstants from '../../auth/constants';
import * as subscriptionsConstants from '../../subscriptions/constants';
import * as commonActions from '../actions';
import * as commonConstants from '../constants';
import { getAllUsers } from '../../../services/api/user';

function* fetchCommonData(method, successAction, failureAction, field, params) {
  try {
    let data = [];
    let response = {};

    if (params !== undefined) {
      response = yield call(method, params);
    } else {
      response = yield call(method);
    }

    if (response?.status === 200) {
      data = response.data.payload.map((value, index) => ({ key: index, value, name: value }));
      const payload = { field, value: data };

      yield put(successAction(payload));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(failureAction(error));
  }
}

function* fetchCustomers() {
  yield call(
    fetchCommonData,
    getAllCustomers,
    commonActions.handleFetchCustomerSuccess,
    commonActions.handleFetchCustomerFailure,
    'customers'
  );
}

function* fetchProducts() {
  yield call(fetchCommonData, getAllProducts, commonActions.handleFetchProductSuccess, commonActions.handleFetchProductFailure, 'products');
}

function* fetchSponsors() {
  yield call(fetchCommonData, getAllSponsors, commonActions.handleFetchSponsorSuccess, commonActions.handleFetchSponsorFailure, 'sponsors');
}

function* fetchStudies() {
  yield call(fetchCommonData, getAllStudies, commonActions.handleFetchStudySuccess, commonActions.handleFetchStudyFailure, 'studies');
}

function* fetchSponsorsByCustomer(params) {
  return yield call(
    fetchCommonData,
    getSponsorsByCustomer,
    commonActions.handleFetchSponsorSuccess,
    commonActions.handleFetchSponsorFailure,
    'sponsors',
    params
  );
}

function* fetchStudiesByCustomerAndSponsor(params) {
  return yield call(
    fetchCommonData,
    getStudiesByCustomerAndSponsor,
    commonActions.handleFetchStudySuccess,
    commonActions.handleFetchStudyFailure,
    'studies',
    params
  );
}

function* fetchInitialization() {
  const fetchParallelData = {};
  const { customers, products, sponsors, studies, clientIds } = yield select((state) => state.common);

  if (customers.length === 0) {
    fetchParallelData.customers = fetchCustomers();
  }

  if (products.length === 0) {
    fetchParallelData.products = fetchProducts();
  }

  if (sponsors.length === 0) {
    fetchParallelData.sponsors = fetchSponsors();
  }

  if (studies.length === 0) {
    fetchParallelData.studies = fetchStudies();
  }

  if (clientIds.length === 0) {
    fetchParallelData.clientIds = fetchClients();
  }

  yield all({
    ...fetchParallelData
  });
}

function* fetchClients() {
  try {
    let data = [];
    const response = yield call(getAllUsers);

    if (response?.status === 200) {
      data = response.data.payload.filter((s) => s.scopes.includes("scp:ens-consumer")).map((s) => s.clientId);
      const payload = { field: 'clientIds', value: data };
      yield put(commonActions.handleFetchClientIdSuccess(payload));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(commonActions.handleFetchClientIdFailure(error));
    // TODO: navigate to error page
  }
}

function* fetchSponsorsAndStudies(action) {
  const fetchParallelData = {};
  const { field, value } = action.payload.fieldChanged;

  if (field === 'customer') {
    fetchParallelData.sponsors = fetchSponsorsByCustomer(value);
    yield put(commonActions.handleSelectedCustomer(value));
  } else if (field === 'sponsor') {
    const customer = yield select((state) => state.common.selectedCustomer);
    const params = { customer, sponsor: value };

    fetchParallelData.studies = fetchStudiesByCustomerAndSponsor(params);
  } else {
    return;
  }

  yield all({ ...fetchParallelData });
}

function* fetchSuscriptionsDetails(action) {
  const fetchParallelData = {};
  const { customer, sponsor } = action.payload;

  if (customer) {
    fetchParallelData.sponsors = fetchSponsorsByCustomer(customer);
    yield put(commonActions.handleSelectedCustomer(customer));
  }
  if (sponsor) {
    const params = { customer, sponsor };
    fetchParallelData.studies = fetchStudiesByCustomerAndSponsor(params);
  }
  yield all({ ...fetchParallelData });
}

export function* watchFetchCommon() {
  yield takeLatest(subscriptionsConstants.SUBSCRIPTIONS_DETAILS_OPEN_REDUCER, fetchSuscriptionsDetails);
  yield takeLatest(authConstants.GET_CURRENT_USER_WORKFLOW_FINISHED, fetchInitialization);
  yield takeLatest(subscriptionsConstants.SUBSCRIPTIONS_FORM_CHANGE_REDUCER, fetchSponsorsAndStudies);
  yield takeLatest(commonConstants.COMMON_PRODUCTS_FETCH_STARTED, fetchProducts);
  yield takeLatest(commonConstants.COMMON_CUSTOMERS_FETCH_STARTED, fetchCustomers);
  yield takeLatest(commonConstants.COMMON_CLIENTIDS_FETCH_STARTED, fetchClients);
}
