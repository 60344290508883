import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { processSilentRenew } from 'redux-oidc';

import App from './app/components/main/App';
// import { GlobalErrorHandler } from './app/services/onError';
import * as serviceWorker from './serviceWorker';

require('./index.css');
// window.onerror = GlobalErrorHandler;
moment.locale(window.navigator.userLanguage || window.navigator.language);

if (window.location.href.includes('resource=renew')) {
  processSilentRenew();
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
