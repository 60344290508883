import FileSaver from 'file-saver';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as eventsActions from '../actions';
import * as eventsConstants from '../constants';
import { getJsonSelector } from '../selectors';

function* downloadEventSchema() {
  try {
    const { name, json } = yield select(getJsonSelector);
    const jsonString = JSON.stringify(json);
    const jsonData = new Blob([jsonString], { type: 'application/json' });

    FileSaver.saveAs(jsonData, `${typeof name === 'string' ? name.toLowerCase().replace(/[\W_]+/g, '-') : 'unknown'}.schema.json`);

    yield put(eventsActions.handleDownloadSchemaSuccess());
  } catch (error) {
    yield put(eventsActions.handleDownloadSchemaFailure(error));
  }
}

export function* watchDownloadSchema() {
  yield takeLatest(eventsConstants.EVENTS_DOWNLOAD_STARTED, downloadEventSchema);
}
