export const styles = {
  root: {
    flexGrow: 1
  },
  headerRoot: {
    background: 'linear-gradient(to right, #250056 , #0076ae) !important'
  },
  toolbar: {
    paddingLeft: '16px !important',
    paddingRight: '10px !important',
    minHeight: '56px !important'
  },
  textLogo: {
    fontSize: '22px !important',
    fontWeight: '200 !important',
    verticalAlign: 'sub',
    flexGrow: 1,
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif !important',
    cursor: 'pointer'
  },
  logo: {
    maxWidth: '150px',
    maxHeight: '56px',
    verticalAlign: 'middle',
    cursor: 'pointer'
  },
  displayName: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    marginLeft: '2px'
  },
  menuInformation: {
    outline: 'none',
    padding: '8px 16px 8px 16px',
    '& h4': {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: 1.5,
      margin: 0,
      display: 'block'
    },
    '& p': {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: 1.71,
      margin: 0,
      display: 'block',
      color: '#969696;',
      fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
    },
    '& hr': {
      height: '1px',
      margin: 0,
      border: 'none',
      flexShrink: 0,
      backgroundColor: '#e9e9e9',
      marginTop: '16px'
    }
  },
  logoutButton: {
    width: '248px',
    margin: '8px 16px 8px 16px',
    color: '#444444',
    boxShadow: 'none',
    backgroundColor: '#f2f2f2',
    height: '40px',
    padding: '0 16px',
    fontSize: '16px',
    minWidth: '56px',
    boxSizing: 'border-box',
    lineHeight: '24px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    borderRadius: '4px',
    textTransform: 'none',
    cursor: 'pointer',
    borderColor: '#f2f2f2',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#00000024'
    }
  }
};
