import { Container, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import ReactJson from 'react-json-view';

import { styles } from './styles';
import { theme } from './theme';

const jsonViewer = ({ error, json, classes }) => (
  <Container key="json-viewer-main-container">
    {error.hasError ? (
      <Typography>{error.message}</Typography>
    ) : (
      <ReactJson
        key="json-viewer-widget"
        src={json}
        name={false}
        displayDataTypes={false}
        displayObjectSize={false}
        sortKeys={false}
        enableClipboard
        theme={theme}
      />
    )}
  </Container>
);

export const JsonViewer = withStyles(styles)(jsonViewer);
