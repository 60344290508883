import { TableColumnModel } from '../tableColumsModel';
const IdColumn = new TableColumnModel('id', 'Id', 170, true, false);
const NameColumn = new TableColumnModel('name', 'Event', 170, true, false);
const RevisionColumn = new TableColumnModel('revision', 'Revision', 170, true, false);
const ProductColumn = new TableColumnModel('product', 'Product', 170, true, false);
const EventActions = new TableColumnModel('actions', '', 170, false, true);

export const EventsTableColumns = () => {
  return [IdColumn, NameColumn, RevisionColumn, ProductColumn, EventActions];
};
