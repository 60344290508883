import React, { Component } from 'react';

export class Fatal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorId: undefined,
      errorDetails: undefined
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    let errorDetails;

    if (window.NODE_ENV !== 'production') {
      try {
        errorDetails = JSON.parse(sessionStorage.getItem('ErrorDetails'));
        if (errorDetails != null) {
          sessionStorage.removeItem('ErrorDetails');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    this.setState({ errorId: params.errorId, errorDetails });
  }

  render() {
    return (
      <>
        <div>
          <h1>Sorry,</h1>
          <p>an error occurred that we could not deal with.</p>
          <p>Please contact so we can try to fix it.{this.state.errorId && <span> ReferenceId: {this.state.errorId}</span>}</p>
          <a href="/login">Go to login</a>
        </div>
        {window.NODE_ENV !== 'production' && this.state.errorDetails && (
          <div>
            <h4>Development Error details</h4>
            <p>
              <strong>Message:</strong> {this.state.errorDetails.message}
            </p>
            <p>
              <strong>Stack:</strong> {this.state.errorDetails.stack}
            </p>
          </div>
        )}
      </>
    );
  }
}
