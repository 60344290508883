import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteForever from '@material-ui/icons/DeleteForever';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { Subscription as SubscriptionModel, SubscriptionsTableColumns, hasError as hasModelError } from '../../models/subscriptions';
import * as commonActions from '../../redux/common/actions';
import * as commonSelectors from '../../redux/common/selectors';
import { subscriptionSaga } from '../../redux/subscriptions';
import * as actions from '../../redux/subscriptions/actions';
import * as selectors from '../../redux/subscriptions/selectors';
import { SUBSCRIPTION_SUCCESS_MESSAGE } from '../../utils/constants';
import { injectSaga } from '../../utils/injectSaga';
import { DetailsPanel } from '../shared';
import { FormValidatorContext } from '../shared/forms/FormValidatorContext';
import { ResultModal } from '../shared/modals/ResultModal';
import { CustomTable } from '../shared/table/customTable';
import { SubscriptionForm } from './forms/SubscriptionForm';
import { CreateSubscriptionModal } from './modals/CreateSubscriptionModal';
import { DeleteSubscriptionModal } from './modals/DeleteSubscriptionModal';
import { styles } from './styles';

const SubscriptionPage = (props) => {
  const InitialState = {
    isNotValid: false,
    hasFormChanged: false,
    hasErrorFunc: hasModelError
  };

  const { classes } = props;
  // Hooks
  const dispatch = useDispatch();
  const [formValidatorState, setFormValidatorState] = useState(InitialState);

  // Redux Selectors
  const subscriptions = useSelector(selectors.getSubscriptions);
  const isCreateModalOpen = useSelector(selectors.getIsCreateModalOpen);
  const isDeleteModalOpen = useSelector(selectors.getIsDeleteModalOpen);
  const isLoading = useSelector(selectors.getIsLoading);
  const activeSubscriptionId = useSelector(selectors.getActiveSubscriptionId);
  const selectedSubscription = useSelector(selectors.getSelectedSubscription);
  const isDetailsPanelOpen = useSelector(selectors.getIsDetailsPanelOpen);
  const customers = useSelector(commonSelectors.getCustomers);
  const products = useSelector(commonSelectors.getProducts);
  const sponsors = useSelector(commonSelectors.getSponsors);
  const studies = useSelector(commonSelectors.getStudies);
  const changes = useSelector(selectors.getChanges);
  const error = useSelector(selectors.getError);
  const isResultModalOpen = useSelector(selectors.getIsResultModalOpen);

  useEffect(() => {
    handleRefresh();
  }, []);

  // Interaction handlers Redux
  const handleDeleteStart = ({ id = null }) => {
    if (id) dispatch(actions.handleSetActiveSubscription(id));
    handleCloseDeleteModal(false);
  };

  const handleCloseCreateModal = (payload) => {
    setFormValidatorState(InitialState);
    dispatch(actions.handleCloseCreateModal(payload));
  };

  const handleCloseDeleteModal = (payload) => {
    dispatch(actions.handleCloseDeleteModal(payload));
  };

  const handleCloseResultModal = (payload) => {
    batch(() => {
      dispatch(actions.handleCloseResultModal(payload));
      dispatch(commonActions.handleFetchCustomerStarted());
    });
  };

  const handleCreate = (payload) => {
    if (!payload) return;

    dispatch(actions.handleCreateSubscriptionStarted(payload));
    setFormValidatorState({ ...formValidatorState, hasFormChanged: false, isNotValid: error.hasError });
  };

  const handleUpdate = () => {
    if (!subscriptionUnderEdit) return;
    if (subscriptionUnderEdit.id && subscriptionUnderEdit.id.trim() !== '') {
      dispatch(actions.handleUpdateSubscriptionStarted(subscriptionUnderEdit));
      setFormValidatorState({ ...formValidatorState, hasFormChanged: false, isNotValid: error.hasError });
    }
  };

  const handleDelete = () => {
    dispatch(actions.handleDeleteSubscriptionStarted(activeSubscriptionId));
  };

  const handleRefresh = () => {
    dispatch(actions.handleFetchSubscriptionStarted());
  };

  const handleOpenDetailsPanel = (subscription) => {
    const { id = null } = subscription;
    if (id) dispatch(actions.handleSetActiveSubscription(id));
    dispatch(actions.handleOpenDetailsPanel(subscription));
  };

  const handleCloseDetailsPanel = () => {
    dispatch(actions.handleCloseDetailsPanel());
  };

  const handleChange = (field, value) => {
    const cleanValue = !value ? '' : value;
    const fieldChanged = { field, value: cleanValue };
    let newFormState = { [field]: cleanValue };

    if (field === 'productName') {
      newFormState = { ...newFormState, customer: '', sponsor: '', study: '' };
    } else if (field === 'customer') {
      newFormState = { ...newFormState, sponsor: '', study: '' };
    } else if (field === 'sponsor') {
      newFormState = { ...newFormState, study: '' };
    }

    dispatch(actions.handleFormChange({ fieldChanged, newFormState }));
    const formError = subscriptionUnderEdit ? subscriptionUnderEdit.hasError() : formValidatorState.error;

    setFormValidatorState({ ...formValidatorState, hasFormChanged: true, isNotValid: formError });
  };

  const handleCancelEdit = () => {
    handleCloseCreateModal(true);
    dispatch(actions.handleCancelEdit());
  };

  // Static fields
  const subscriptionUnderEdit = new SubscriptionModel({ ...selectedSubscription, ...changes });
  const columns = SubscriptionsTableColumns();
  const actionTableButtons = createActionsButtons(handleDeleteStart);
  const tableStaticDefinition = {
    title: 'Subscriptions',
    defaultSortedColumnName: 'customer',
    defaultSortOrder: 'asc',
    columns,
    onClickFunc: () => handleCloseCreateModal(false),
    isButtonDisabled: isLoading,
    isLoading,
    arrayOfActions: actionTableButtons,
    onRowClick: handleOpenDetailsPanel
  };
  const detailsPanelButtons = createDetailsPanelButtons(
    formValidatorState.hasFormChanged,
    !isLoading && !formValidatorState.hasErrorFunc(subscriptionUnderEdit),
    handleUpdate,
    handleCancelEdit
  );

  return (
    <FormValidatorContext.Provider value={[formValidatorState, setFormValidatorState]}>
      <CreateSubscriptionModal
        key="main-modal-create-subscription"
        handleChange={handleChange}
        subscription={subscriptionUnderEdit}
        openDialog={isCreateModalOpen}
        onCloseDialog={() => handleCancelEdit()}
        onCreate={handleCreate}
        customers={customers}
        products={products}
        sponsors={sponsors}
        studies={studies}
        hasFormChanged={formValidatorState.hasFormChanged}
        hasErrorFunc={formValidatorState.hasErrorFunc}
        error={error}
      />
      <DeleteSubscriptionModal openDialog={isDeleteModalOpen} onCloseDialog={() => handleCloseDeleteModal(true)} onDelete={handleDelete} />
      <ResultModal
        openDialog={isResultModalOpen}
        onCloseDialog={() => handleCloseResultModal(true)}
        hasError={error.hasError}
        message={!error.hasError ? SUBSCRIPTION_SUCCESS_MESSAGE : error.message}
      />
      <Grid container spacing={0} alignItems="flex-start" justifyContent="center">
        <Grid item xs={isDetailsPanelOpen ? 8 : 12} className={classes.gridItem}>
          <CustomTable
            {...tableStaticDefinition}
            data={subscriptions.map((s) => s.getViewModel())}
            dataCount={subscriptions?.length ? subscriptions.length : 10}
          />
        </Grid>
        {isDetailsPanelOpen && (
          <Grid key="subscription-panel-grid" item xs={4} className={classes.gridItem}>
            <DetailsPanel
              key="subscription-panel"
              hideDefaultCancelButton={formValidatorState.hasFormChanged}
              title="Subscription Details"
              closePanel={handleCloseDetailsPanel}
              arrayOfButtons={detailsPanelButtons}>
              <SubscriptionForm
                key="panel-subscription-form"
                handleChange={handleChange}
                subscription={subscriptionUnderEdit}
                classes={classes}
                customers={customers}
                products={products}
                sponsors={sponsors}
                studies={studies}
                error={error}
              />
            </DetailsPanel>
          </Grid>
        )}
      </Grid>
    </FormValidatorContext.Provider>
  );
};

// Setup functions
const handleDeleteWrapperStart = (event, row, handleDeleteStart) => {
  event.preventDefault();
  event.stopPropagation();
  handleDeleteStart(row);
};

const createDetailsPanelButtons = (hasFormChanged, enabled, handleUpdate, handleCancelEdit) =>
  hasFormChanged
    ? [
        {
          label: 'Cancel',
          variant: 'outlined',
          enabled: true,
          action: () => handleCancelEdit()
        },
        {
          label: 'Save',
          variant: 'contained',
          color: 'primary',
          enabled,
          action: () => handleUpdate()
        }
      ]
    : [];

const createActionsButtons = (handleDeleteStart) => [
  {
    icon: <DeleteForever />,
    title: 'Delete Subscription',
    ariaLabel: 'delete',
    action: (event, row) => handleDeleteWrapperStart(event, row, handleDeleteStart)
  }
];

// HOCs and Export
const styledSubscription = withStyles(styles);
const withSubscriptionSaga = injectSaga({ key: 'subscriptionSaga', saga: subscriptionSaga });

export const Subscription = compose(styledSubscription, withSubscriptionSaga)(SubscriptionPage);
