const prefix = 'SUBSCRIPTIONS';

// Sagas
export const SUBSCRIPTIONS_CREATE_FAILURE = `${prefix}_CREATE_FAILURE`;
export const SUBSCRIPTIONS_CREATE_STARTED = `${prefix}_CREATE_STARTED`;
export const SUBSCRIPTIONS_CREATE_SUCCESS = `${prefix}_CREATE_SUCCESS`;

export const SUBSCRIPTIONS_DELETE_FAILURE = `${prefix}_DELETE_FAILURE`;
export const SUBSCRIPTIONS_DELETE_STARTED = `${prefix}_DELETE_STARTED`;
export const SUBSCRIPTIONS_DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;

export const SUBSCRIPTIONS_FETCH_FAILURE = `${prefix}_FETCH_FAILURE`;
export const SUBSCRIPTIONS_FETCH_STARTED = `${prefix}_FETCH_STARTED`;
export const SUBSCRIPTIONS_FETCH_SUCCESS = `${prefix}_FETCH_SUCCESS`;

export const SUBSCRIPTIONS_UPDATE_FAILURE = `${prefix}_UPDATE_FAILURE`;
export const SUBSCRIPTIONS_UPDATE_STARTED = `${prefix}_UPDATE_STARTED`;
export const SUBSCRIPTIONS_UPDATE_SUCCESS = `${prefix}_UPDATE_SUCCESS`;

// Reducers
export const SUBSCRIPTIONS_CLOSE_DELETE_REDUCER = `${prefix}_CLOSE_DELETE_REDUCER`;
export const SUBSCRIPTIONS_CLOSE_EDIT_REDUCER = `${prefix}_CLOSE_EDIT_REDUCER`;
export const SUBSCRIPTIONS_CLOSE_RESULT_REDUCER = `${prefix}_CLOSE_RESULT_REDUCER`;
export const SUBSCRIPTIONS_CREATE_REDUCER = `${prefix}_CREATE_REDUCER`;
export const SUBSCRIPTIONS_CREATE_STATE_REDUCER = `${prefix}_CREATE_STATE_REDUCER`;
export const SUBSCRIPTIONS_DELETE_REDUCER = `${prefix}_DELETE_REDUCER`;
export const SUBSCRIPTIONS_DETAILS_CLOSE_REDUCER = `${prefix}_DETAILS_CLOSE_REDUCER`;
export const SUBSCRIPTIONS_DETAILS_OPEN_REDUCER = `${prefix}_DETAILS_OPEN_REDUCER`;
export const SUBSCRIPTIONS_FETCH_REDUCER = `${prefix}_FETCH_REDUCER`;
export const SUBSCRIPTIONS_FORM_CANCEL_EDIT_REDUCER = `${prefix}_FORM_CANCEL_EDIT`;
export const SUBSCRIPTIONS_FORM_CHANGE_REDUCER = `${prefix}_FORM_CHANGE_REDUCER`;
export const SUBSCRIPTIONS_SET_ACTIVE_REDUCER = `${prefix}_SET_ACTIVE_REDUCER`;
export const SUBSCRIPTIONS_UPDATE_REDUCER = `${prefix}_UPDATE_REDUCER`;
