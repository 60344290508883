import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomModal } from '../../shared';
import { EventForm } from '../forms/EventForm';
import { styles } from './styles';

const CreateEvent = ({ openDialog, onCloseDialog, onCreate, classes, event, handleChange, hasFormChanged, hasErrorFunc, duplicatedFormat, products }) => (
  <Grid container spacing={2}>
    <CustomModal
      className={classes.root}
      dialogTitle="Create Event"
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
      arrayOfButtons={[
        {
          label: 'Cancel',
          color: 'default',
          enabled: true,
          action: () => {
            onCloseDialog();
          }
        },
        {
          label: 'Ok',
          variant: 'contained',
          color: 'primary',
          enabled: hasFormChanged && !hasErrorFunc(event) && !duplicatedFormat.hasError,
          action: () => {
            onCreate(event);
          }
        }
      ]}>
      <Grid key="eventform" item xs={12}>
        <EventForm handleChange={handleChange} event={event} classes={classes} duplicatedFormat={duplicatedFormat} products={products} />
      </Grid>
    </CustomModal>
  </Grid>
);

CreateEvent.defaultProps = {
  openDialog: false
};

CreateEvent.propTypes = {
  /**
   * @ignore
   */
  classes: PropTypes.object.isRequired,
  /**
   * openDialog indicate is the dialog is open or closed
   */
  openDialog: PropTypes.bool,
  /**
   * onCloseDialog fired when the user hits the close button from dialog component
   */
  onCloseDialog: PropTypes.func,
  /**
   * onCreate fired when the user hits the ok button from dialog component
   */
  onCreate: PropTypes.func,
  /**
   * handle change fired when the user changes a field
   */
  handleChange: PropTypes.func,
  /**
   * load event data
   */
  event: PropTypes.object,
  /**
   * Indicates changes have been done to the form
   */
  hasFormChanged: PropTypes.bool,
  /**
   * Checks changes have been done to the form
   */
  hasErrorFunc: PropTypes.func,
  products: PropTypes.array
};

export const CreateEventModal = withStyles(styles)(CreateEvent);
