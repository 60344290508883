import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getTokenMapping } from '../../services/userService';

class PrivateRouteView extends Component {
  render() {
    const { user, component: Component, ...rest } = this.props;

    if(!user) return null;

    const tokenMapping = getTokenMapping(user);
    
    return (
      <Route
        {...rest}
        component={(props) =>
            // TODO: Import 'OK' as a constant for validation.
            tokenMapping && tokenMapping.status === 'OK' ? (
            <div>
              <Component {...props} />
            </div>
          ) : (
            <Redirect to="/unauthorized" />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.oidc.user
  };
};

export const PrivateRoute = connect(mapStateToProps, null)(PrivateRouteView);
