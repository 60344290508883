import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';

import { authSaga } from '../../redux/auth/saga';
import { injectSaga } from '../../utils/injectSaga';
import { storage } from '../../utils/storage';
import { AuthRealmKey } from '../auth/userManager';

class AuthenticationView extends Component {
  ignoredRoute = (pathname) =>
    this.props.ignoredRoutes.some((match) => {
      const matchProfile = matchPath(pathname, {
        path: match,
        exact: false,
        strict: false
      });

      return matchProfile !== null;
    });

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.userLoggedIn === false || this.props.userLoggedIn !== nextProps.userLoggedIn;
  }

  render() {
    const { userLoggedIn, loginUrl = '/login' } = this.props;

    if (userLoggedIn || this.ignoredRoute(window.location.pathname)) return null;

    const issuer = storage.get(AuthRealmKey);

    return <Redirect to={{ pathname: loginUrl, state: { issuer, redirectUrl: window.location.pathname + window.location.hash } }} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const mapping = state.auth.tokenMapping;

  return {
    userLoggedIn: mapping && mapping.status === 'OK',
    ignoredRoutes: ownProps.ignoredRoutes,
    loginUrl: ownProps.loginUrl
  };
};

const withAuthenticationSaga = injectSaga({ key: 'authentication', saga: authSaga });

export const Authentication = compose(connect(mapStateToProps), withAuthenticationSaga)(AuthenticationView);
