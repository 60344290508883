import { call, put, takeLatest } from 'redux-saga/effects';

import { ConsumerGroup } from './../../../models/consumerGroups';
import { getAllConsumerGroups, getConsumerGroupsByConsumerId } from '../../../services/api/consumerGroup';
import * as consumerGroupActions from '../actions';
import * as consumerGroupConstants from '../constants';

function* fetchConsumerGroups() {
  try {
    let data = [];
    const response = yield call(getAllConsumerGroups);

    if (response?.status === 200) {
      data = response.data.payload.map((s) => new ConsumerGroup(s));
      yield put(consumerGroupActions.handleFetchConsumerGroupSuccess(data));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(consumerGroupActions.handleFetchConsumerGroupFailure(error));
    // TODO: navigate to error page
  }
}

function* fetchConsumerGroupsByConsumerId(action) {
  try {
    let data = [];
    const response = yield call(getConsumerGroupsByConsumerId, action.payload);
    if (response?.status === 200) {
      data = response.data.payload.map((s) => new ConsumerGroup(s));
      yield put(consumerGroupActions.handleFetchConsumerGroupsByConsumerIdSuccess(data));

      return;
    }
  } catch (error) {
    yield put(consumerGroupActions.handleFetchConsumerGroupsByConsumerIdFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchFetchConsumerGroup() {
  yield takeLatest(consumerGroupConstants.CONSUMERGROUPS_FETCH_STARTED, fetchConsumerGroups);
  yield takeLatest(consumerGroupConstants.CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_STARTED, fetchConsumerGroupsByConsumerId);
}
