const prefix = 'CONSUMERS';

// Sagas
export const CONSUMERS_CREATE_FAILURE = `${prefix}_CREATE_FAILURE`;
export const CONSUMERS_CREATE_STARTED = `${prefix}_CREATE_STARTED`;
export const CONSUMERS_CREATE_SUCCESS = `${prefix}_CREATE_SUCCESS`;

export const CONSUMERS_DELETE_FAILURE = `${prefix}_DELETE_FAILURE`;
export const CONSUMERS_DELETE_STARTED = `${prefix}_DELETE_STARTED`;
export const CONSUMERS_DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;

export const CONSUMERS_FETCH_FAILURE = `${prefix}_FETCH_FAILURE`;
export const CONSUMERS_FETCH_STARTED = `${prefix}_FETCH_STARTED`;
export const CONSUMERS_FETCH_SUCCESS = `${prefix}_FETCH_SUCCESS`;

export const CONSUMERS_UPDATE_FAILURE = `${prefix}_UPDATE_FAILURE`;
export const CONSUMERS_UPDATE_STARTED = `${prefix}_UPDATE_STARTED`;
export const CONSUMERS_UPDATE_SUCCESS = `${prefix}_UPDATE_SUCCESS`;

export const CONSUMERS_SUBSCRIBED_EVENTS_FETCH_FAILURE = `${prefix}_SUBSCRIBED_EVENTS_FETCH_FAILURE`;
export const CONSUMERS_SUBSCRIBED_EVENTS_FETCH_STARTED = `${prefix}_SUBSCRIBED_EVENTS_FETCH_STARTED`;
export const CONSUMERS_SUBSCRIBED_EVENTS_FETCH_SUCCESS = `${prefix}_SUBSCRIBED_EVENTS_FETCH_SUCCESS`;

// Reducers
export const CONSUMERS_CLOSE_DELETE_REDUCER = `${prefix}_CLOSE_DELETE_REDUCER`;
export const CONSUMERS_CLOSE_EDIT_REDUCER = `${prefix}_CLOSE_EDIT_REDUCER`;
export const CONSUMERS_CLOSE_RESULT_REDUCER = `${prefix}_CLOSE_RESULT_REDUCER`;
export const CONSUMERS_DETAILS_CLOSE_REDUCER = `${prefix}_DETAILS_CLOSE_REDUCER`;
export const CONSUMERS_DETAILS_OPEN_REDUCER = `${prefix}_DETAILS_OPEN_REDUCER`;
export const CONSUMERS_SET_ACTIVE_REDUCER = `${prefix}_SET_ACTIVE_REDUCER`;
