export class Event {
  id;
  documentType;
  eventTypeName;
  revision;
  productName;
  json;

  constructor({ id = null, eventTypeName = '', productName = '', json = '', revision = 'LATEST' }) {
    this.id = id;
    this.eventTypeName = eventTypeName;
    this.revision = revision;
    this.productName = productName;
    this.json = json;
    this.documentType = 'eventType';
  }

  hasError() {
    hasError(this);
  }

  getViewModel() {
    return {
      id: this.id,
      name: this.eventTypeName,
      product: this.productName ?? '',
      revision: this.revision,
      json: this.json ? this.json : {}
    };
  }
}

function isValid(str) {
  try {
    if (typeof str === 'object') return true;
    if (typeof str !== 'string') return false;

    const jsonObject = JSON.parse(str);

    return typeof jsonObject === 'object';
  } catch (e) {
    return false;
  }
}

export const hasError = ({ eventTypeName = '', productName = '', json = '' }) => {
  let isNotValid = true;

  isNotValid = !eventTypeName || eventTypeName.trim() === '';
  isNotValid = isNotValid || !productName || productName.trim() === '';
  isNotValid = isNotValid || !isValid(json);

  return isNotValid;
};
