export class Subscription {
  id;
  documentType;
  customer;
  productName;
  sponsor;
  study;

  constructor({ id = null, customer = null, productName = null, sponsor = null, study = null }) {
    this.id = id;
    this.documentType = 'subscription';
    this.customer = customer;
    this.productName = 'econsent';
    this.sponsor = sponsor;
    this.study = study;
  }

  hasSameRoute(other, isCreate = false) {
    const defaultResult = { isDuplicate: false, message: '' };

    if (!isCreate) return defaultResult;

    const routeSegments = ['productName', 'customer', 'sponsor', 'study'];
    const routeDiffs = routeSegments.map((field) => areStringsEqual(this[field], other[field])).filter((v) => v === false);

    const areDuplicates = routeDiffs.length === 0;

    if (areDuplicates) {
      return {
        isDuplicate: true,
        message: `A Subscription with this data already exists.`
      };
    }

    return defaultResult;
  }

  isDuplicate(other) {
    let result = { isDuplicate: false, message: '' };

    result = this.hasSameRoute(other, true);

    return result;
  }

  hasError() {
    return hasError(this);
  }

  getViewModel() {
    let eventSource = `${this.productName ? `${this.productName}/` : ''}${this.customer ? `${this.customer}/` : ''}${this.sponsor ? `${this.sponsor}/` : ''}${this.study ? `${this.study}` : ''}`;

    eventSource = eventSource.replace(/^\/|\/$/g, '');

    return {
      id: this.id ?? '',
      customer: this.customer ?? '',
      sponsor: this.sponsor ?? '',
      eventSource: eventSource.trim()
    };
  }
}

export const hasError = ({ productName = null, customer = '', sponsor = null, study = null, checkSourceOnly = false }) => {
  let isNotValid = true;
  const isSourceInvalid = (!productName || productName.trim() === '') || (!customer || customer.trim() === '');

  if (checkSourceOnly) return isSourceInvalid;

  isNotValid = !customer || customer.trim() === '';

  isNotValid = isNotValid || isSourceInvalid;

  return isNotValid;
};

export const areStringsEqual = (original, target, useNullAsEmpty = true) => {
  if (useNullAsEmpty && !original && !target ) return true;
  if (typeof original !== 'string' || typeof target !== 'string') return false;

  return original.trim().toLowerCase() === target.trim().toLowerCase();
};
