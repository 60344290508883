export const DAEMON = '@@saga-injector/daemon';
export const EVENT_SUCCESS_MESSAGE = 'Event has been successfully created.';
export const EVENT_UPDATED_SUCCESS_MESSAGE = 'Event has been successfully updated.';
export const CONSUMER_SUCCESS_MESSAGE = 'Consumer has been successfully created.';
export const CONSUMER_UPDATED_SUCCESS_MESSAGE = 'Consumer has been successfully updated.';
export const CONSUMERGROUP_RESETED_SUCCESS_MESSAGE = 'Consumer group has been successfully reset.';
export const USER_SUCCESS_MESSAGE = 'User has been successfully created.';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const TEST_MODE = '@@saga-injector/test-mode';
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const SUBSCRIPTION_SUCCESS_MESSAGE = 'Subscription has been successfully created.';
export const CONSUMER_SCOPE = 'ens-consumer';
export const PUBLISHER_SCOPE = 'ens-publisher';
export const ADMIN_SCOPE = 'ens-admin';
export const SCOPES_OPTIONS_LIST = [CONSUMER_SCOPE, PUBLISHER_SCOPE, ADMIN_SCOPE];
