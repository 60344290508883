/* eslint-disable no-prototype-builtins */
import { call, put, takeLatest } from 'redux-saga/effects';

import { deleteSubscriptionById } from '../../../services/api/subscription';
import * as subscriptionsActions from '../actions';
import * as subscriptionsConstants from '../constants';

export function* deleteSubscription(action) {
  const { payload } = action;

  try {
    const response = yield call(deleteSubscriptionById, payload);

    if (response?.status === 200) {
      yield put(subscriptionsActions.handleDeleteSubscriptionSuccess(payload));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(subscriptionsActions.handleDeleteSubscriptionFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchDeleteSubscription() {
  yield takeLatest(subscriptionsConstants.SUBSCRIPTIONS_DELETE_STARTED, deleteSubscription);
}
