export class AdminData {
  groups;
  topics;

  constructor({ groups = [], topics = [] }) {
    this.groups = groups;
    this.topics = topics;
  }

  getViewModel = () => this.topics.filter((t) => !t || !t.name.startsWith('__'))
}
