import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Realm, initializeUserManager } from './userManager';

class Login extends Component {
  signinRedirect(realm) {
    const userManager = initializeUserManager(realm);

    let state = {};

    if (this.props.location && this.props.location.state) {
      state = this.props.location.state;
    }

    state.issuer = realm;

    userManager.signinRedirect({ data: state });
  }

  componentDidMount() {
    if (this.isAutoLogin()) {
      const userManager = initializeUserManager(this.props.location.state.issuer);

      userManager.signinRedirect({ data: this.props.location.state });
    } else {
      this.signinRedirect(Realm);
    }
  }

  isAutoLogin() {
    return this.props.location && this.props.location.state && this.props.location.state.issuer;
  }

  render() {
    return <div></div>;
  }
}

export const LoginContainer = connect()(Login);
