export const GET_TOKEN_MAPPING_WORKFLOW_STARTED = 'GET_TOKEN_MAPPING_WORKFLOW_STARTED';
export const GET_TOKEN_MAPPING_WORKFLOW_SUCCESED = 'GET_TOKEN_MAPPING_WORKFLOW_SUCCESED';
export const GET_TOKEN_MAPPING_WORKFLOW_ERROR = 'GET_TOKEN_MAPPING_WORKFLOW_ERROR';
export const GET_TOKEN_MAPPING_WORKFLOW_FINISHED = 'GET_TOKEN_MAPPING_WORKFLOW_FINISHED';
export const GET_TOKEN_MAPPING_STATE_UPDATE = 'GET_TOKEN_MAPPING_STATE_UPDATE';

export const GET_CURRENT_USER_WORKFLOW_STARTED = 'GET_CURRENT_USER_WORKFLOW_STARTED';
export const GET_CURRENT_USER_WORKFLOW_SUCCESED = 'GET_CURRENT_USER_WORKFLOW_SUCCESED';
export const GET_CURRENT_USER_WORKFLOW_ERROR = 'GET_CURRENT_USER_WORKFLOW_ERROR';
export const GET_CURRENT_USER_WORKFLOW_FINISHED = 'GET_CURRENT_USER_WORKFLOW_FINISHED';
export const GET_CURRENT_USER_STATE_UPDATE = 'GET_CURRENT_USER_STATE_UPDATE';

export const CHECK_USER_STATUS_WORKFLOW_STARTED = 'CHECK_USER_STATUS_WORKFLOW_STARTED';
export const CHECK_USER_STATUS_WORKFLOW_SUCCESED = 'CHECK_USER_STATUS_WORKFLOW_SUCCESED';
export const CHECK_USER_STATUS_WORKFLOW_ERROR = 'CHECK_USER_STATUS_WORKFLOW_ERROR';
export const CHECK_USER_STATUS_WORKFLOW_FINISHED = 'CHECK_USER_STATUS_WORKFLOW_FINISHED';
export const CHECK_USER_STATUS_STATE_UPDATE = 'CHECK_USER_STATUS_STATE_UPDATE';

export const USER_FOUND_STATE_UPDATE = 'redux-oidc/USER_FOUND';
