import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import * as consumerGroupConstants from './constants';

export const initialState = {
  data: [],
  isLoading: false,
  activeConsumerGroupId: null,
  isResetModalOpen: false,
  isResultModalOpen: false,
  error: { hasError: false, message: '' }
};

const actionStartedHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = true;
  });

const fetchSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.data = action.payload;
  });

const resetSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.isResultModalOpen = true;
  });

const failureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.isResetModalOpen = initialState.isResetModalOpen;
    draft.isResultModalOpen = initialState.isResultModalOpen;
    draft.error = {
      hasError: true,
      message
    };
  });

const resetFailureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.isResetModalOpen = initialState.isResetModalOpen;
    draft.isResultModalOpen = true;
    draft.error = {
      hasError: true,
      message
    };
  });

const closeResetModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isResetModalOpen = !action.payload;
  });

const closeResultModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isResultModalOpen = !action.payload;
  });

const setActiveHandler = (state, action) =>
  produce(state, (draft) => {
    draft.error = initialState.error;
    draft.activeConsumerGroupId = action.payload;
  });

export const consumerGroupReducer = handleActions(
  {
    [consumerGroupConstants.CONSUMERGROUPS_FETCH_STARTED]: actionStartedHandler,
    [consumerGroupConstants.CONSUMERGROUPS_FETCH_SUCCESS]: fetchSuccessHandler,
    [consumerGroupConstants.CONSUMERGROUPS_FETCH_FAILURE]: failureHandler,
    [consumerGroupConstants.CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_STARTED]: actionStartedHandler,
    [consumerGroupConstants.CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_SUCCESS]: fetchSuccessHandler,
    [consumerGroupConstants.CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_FAILURE]: failureHandler,
    [consumerGroupConstants.CONSUMERGROUPS_RESET_STARTED]: actionStartedHandler,
    [consumerGroupConstants.CONSUMERGROUPS_RESET_SUCCESS]: resetSuccessHandler,
    [consumerGroupConstants.CONSUMERGROUPS_RESET_FAILURE]: resetFailureHandler,
    [consumerGroupConstants.CONSUMERGROUPS_CLOSE_RESET_REDUCER]: closeResetModalHandler,
    [consumerGroupConstants.CONSUMERGROUPS_CLOSE_RESULT_REDUCER]: closeResultModalHandler,
    [consumerGroupConstants.CONSUMERGROUPS_SET_ACTIVE_REDUCER]: setActiveHandler
  },
  initialState
);
