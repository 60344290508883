const prefix = 'USERS';

// Sagas
export const USERS_CREATE_FAILURE = `${prefix}_CREATE_FAILURE`;
export const USERS_CREATE_STARTED = `${prefix}_CREATE_STARTED`;
export const USERS_CREATE_SUCCESS = `${prefix}_CREATE_SUCCESS`;

export const USERS_DELETE_FAILURE = `${prefix}_DELETE_FAILURE`;
export const USERS_DELETE_STARTED = `${prefix}_DELETE_STARTED`;
export const USERS_DELETE_SUCCESS = `${prefix}_DELETE_SUCCESS`;

export const USERS_FETCH_FAILURE = `${prefix}_FETCH_FAILURE`;
export const USERS_FETCH_STARTED = `${prefix}_FETCH_STARTED`;
export const USERS_FETCH_SUCCESS = `${prefix}_FETCH_SUCCESS`;

// Reducers
export const USERS_CLOSE_CREATE_REDUCER = `${prefix}_CLOSE_CREATE_REDUCER`;
export const USERS_CLOSE_DELETE_REDUCER = `${prefix}_CLOSE_DELETE_REDUCER`;
export const USERS_CLOSE_RESULT_REDUCER = `${prefix}_CLOSE_RESULT_REDUCER`;
export const USERS_DETAILS_CLOSE_REDUCER = `${prefix}_DETAILS_CLOSE_REDUCER`;
export const USERS_DETAILS_OPEN_REDUCER = `${prefix}_DETAILS_OPEN_REDUCER`;
export const USERS_SET_ACTIVE_REDUCER = `${prefix}_SET_ACTIVE_REDUCER`;
