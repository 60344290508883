import { call, put, takeLatest } from 'redux-saga/effects';

import { User } from './../../../models/users';
import { getAllUsers } from '../../../services/api/user';
import * as userActions from '../actions';
import * as userConstants from '../constants';

export function* fetchUser() {
  try {
    let data = [];
    const response = yield call(getAllUsers);

    if (response?.status === 200) {
      data = response.data.payload.map((s) => new User(s));
      yield put(userActions.handleFetchUserSuccess(data));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(userActions.handleFetchUserFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchFetchUser() {
  yield takeLatest(userConstants.USERS_FETCH_STARTED, fetchUser);
}
