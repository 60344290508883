export const RETURN_KEY_CODE = 13;

// TODO : Create a theme for those standard colors
export const SUCCESS_LIGHT = '#ECFBF6';
export const SUCCESS_MEDIUM = '#4CC29B';
export const SUCCESS_DARK = '#177758';
export const INFO_LIGHT = '#bbdefb';
export const INFO_MEDIUM = '#42a5f5';
export const INFO_DARK = '#0d47a1';
export const ATTENTION_LIGHT = '#fdf5e4';
export const ATTENTION_MEDIUM = '#ecb018';
export const ATTENTION_DARK = '#846312';
export const ERROR_LIGHT = '#fff6f6';
export const ERROR_MEDIUM = '#f05762';
export const ERROR_DARK = '#b71c32';
