/* eslint-disable no-prototype-builtins */

export function RequestError(error) {
  this.name = 'RequestError';
  this.url = error.request.url;
  this.status = error.response.status;
  this.stack = error.stack;

  let message = '';

  if (error.message !== undefined && error.message !== null) message += error.message;

  if (error.response.data.message !== undefined && error.response.data.message !== null)
    message += formatMessage(message, error.response.data.message);

  if (error.response.statusText !== undefined) message += formatMessage(message, error.response.statusText);

  if (typeof error.response.data === 'object') {
    if (
      error.response.data.exception !== undefined &&
      error.response.data.exception !== null &&
      error.response.data.exception.message !== undefined &&
      error.response.data.exception.message !== null
    ) {
      message += formatMessage(message, error.response.data.exception.message);

      if (
        error.response.data.exception.innerException !== undefined &&
        error.response.data.exception.innerException !== null &&
        error.response.data.exception.innerException.message !== undefined &&
        error.response.data.exception.innerException.message !== null
      ) {
        message += formatMessage(message, error.response.data.exception.innerException.message);
      }
    }
  }

  this.message = message;
  this.data = error.response.data;
}

export function NetworkError(error) {
  this.name = 'Network Error';
  this.message = undefined;
  this.url = undefined;
  this.stack = undefined;

  if (error.request) {
    this.url = error.request.url || undefined;
    this.message = error.message || 'Error making call to the server';
    if (window.NODE_ENV !== 'production' && typeof error.response.data === 'object') {
      this.stack = error.stack || undefined;
    }
  }

  if (error.config) {
    this.url = error.config.url;
  }
}

export function SecurityError(error) {
  this.name = 'SecurityError';
  this.url = error.request.url;
  this.status = error.response.status;

  this.message = error.response.message || `User attempted an action the user was unable to do due to a ${error.response.status}`;
  this.data = error.response.data;
}

export function CustomError(error) {
  this.name = 'CustomError';
  this.customError = true;
  this.message = error.message || `Oops something went wrong on the server`;
  this.data = error.data;
}

export function ValidationError(error) {
  this.name = 'ValidationError';
  this.url = error.request.url;
  this.status = error.response.status;

  let message = '';

  if (error.response.message !== undefined) message += error.response.message;

  this.data = {};
  error.response.data.errors.forEach((element) => {
    if (element.hasOwnProperty('property')) {
      this.data[element.property] = element.messages.join('\n');
      message += formatMessage(message, element.messages.join('\n'));
    } else {
      this.data.FormError = 'Error validating data';
      message += formatMessage(message, 'Error validating data');
    }
  });
  this.message = message;
}

function formatMessage(msg, additionalMsg) {
  const resutl = msg.trim() === '' ? additionalMsg : `\n\n${additionalMsg}`;

  return resutl;
}
