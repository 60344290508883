export const mapListToOptions = (list) => {
  if (!list) return [];

  if (Array.isArray(list) && list.length === 0) return [];

  return list
    .map((item) => {
      return { value: item, name: item };
    })
    .filter((l) => typeof l !== 'undefined');
};

export const mapStringToList = (string, param) => {
    if(string === undefined) return [];

    return string.split(param);
};