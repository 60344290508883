import * as COLORS from '../shared/sharedConstants';

export const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    padding: '1em'
  },
  row: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(2),
    width: '80%'
  },
  gridItem: {
    padding: '1em'
  },
  error: {
    color: COLORS.ERROR_MEDIUM
  },
  select: {
    marginLeft: '40px !important'
  },
  jsonTitle: {
    marginLeft: '20px',
    marginBottom: '20px'
  }
});
