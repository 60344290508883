export const styles = {
  button: {
    margin: '7px',
    textTransform: 'none',
    color: '#0768fd'
  },
  primary: {
    color: 'white',
    backgroundColor: '#0768fd'
  },
  default: {
    color: '#131111',
    backgroundColor: '#d5d5d566'
  }
};
