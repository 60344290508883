import * as COLORS from '../../sharedConstants';
import { styles as ButtonStyle } from '../CustomButtonStyle';

export const styles = ({ palette, shape, spacing }) => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0'
    },
    '100%': {
      backgroundPosition: '-70px 0'
    }
  },
  root: {
    position: 'relative',
    width: 'auto',
    backgroundColor: palette.background.paper,
    border: 'dashed',
    borderColor: palette.divider,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  active: {
    animation: '$progress 2s linear infinite !important',
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${COLORS.SUCCESS_LIGHT}, ${COLORS.SUCCESS_LIGHT} 25px, ${COLORS.SUCCESS_MEDIUM} 25px, ${COLORS.SUCCESS_MEDIUM} 50px)`,
    backgroundSize: '150% 100%',
    border: 'solid',
    borderColor: COLORS.SUCCESS_DARK
  },
  invalid: {
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${COLORS.ERROR_LIGHT}, ${COLORS.ERROR_LIGHT} 25px, ${COLORS.ERROR_MEDIUM} 25px, ${COLORS.ERROR_MEDIUM} 50px)`,
    borderColor: COLORS.ERROR_DARK
  },
  textContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: '10px',
    textAlign: 'center',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
  },
  text: {
    marginBottom: spacing(3),
    marginTop: spacing(3),
    color: 'gray'
  },
  labelText: {
    color: '#444444',
    marginBottom: spacing(1),
    fontSize: '14px',
    fontFamily: 'Proxima Nova, Nunito Sans, sans-serif'
  },
  button: {
    ...ButtonStyle.button,
    maxWidth: '100px',
    boxShadow: '0px 6px 14px 0px #4444443d',
    '&:hover': {
      border: 'solid 1px #808080a8'
    }
  }
});

export const defaultSnackbarAnchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom'
};
