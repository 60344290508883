import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import { User as UserModel } from '../../models/users';
import * as usersConstants from './constants';

export const initialState = {
  data: [],
  isLoading: false,
  activeUser: '',
  createdUser: null,
  error: { hasError: false, message: '' },
  isCreateModalOpen: false,
  isDeleteModalOpen: false,
  isResetModalOpen: false,
  isDetailsPanelOpen: false,
  isResultModalOpen: false,
  resultModalMode: ''
};

const actionStartedHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = true;
  });

const fetchSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.data = action.payload;
  });

const createSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = initialState.isLoading;
    draft.error = initialState.error;
    draft.data = [action.payload].concat(state.data);
    draft.isCreateModalOpen = initialState.isCreateModalOpen;
    draft.isResultModalOpen = true;
    draft.createdUser = action.payload;
  });

const deleteSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    const filteredData = state.data.filter((e) => e.clientId !== action.payload);
    const hasPanelState = state.isDetailsPanelOpen && filteredData.length > 0;

    draft.isLoading = false;
    draft.error = initialState.error;
    draft.activeUser = hasPanelState ? filteredData[0].clientId : initialState.activeUser;
    draft.isDetailsPanelOpen = hasPanelState;
    draft.data = filteredData;
  });

const failureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.isResultModalOpen = true;
    draft.createdUser = initialState.createdUser;
    draft.isDetailsPanelOpen = initialState.isDetailsPanelOpen;
    draft.isCreateModalOpen = initialState.isCreateModalOpen;
    draft.isDeleteModalOpen = initialState.isDeleteModalOpen;
    draft.isResetModalOpen = initialState.isResetModalOpen;
    draft.error = {
      hasError: true,
      message
    };
  });

const openDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = true;
  });

const closeDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = false;
  });

const closeCreateModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.activeUser = initialState.activeUser;
    draft.createdUser = initialState.createdUser;
    draft.isDetailsPanelOpen = initialState.isDetailsPanelOpen;
    draft.isCreateModalOpen = !action.payload;
    draft.isResultModalOpen = false;
  });

const closeDeleteModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isDeleteModalOpen = !action.payload;
  });

const closeResultModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isResultModalOpen = !action.payload;
    draft.resultModalMode = initialState.resultModalMode;
  });

const setActiveHandler = (state, action) =>
  produce(state, (draft) => {
    draft.error = initialState.error;
    draft.activeUser = action.payload;
  });

export const userReducer = handleActions(
  {
    [usersConstants.USERS_CLOSE_DELETE_REDUCER]: closeDeleteModalHandler,
    [usersConstants.USERS_CLOSE_RESULT_REDUCER]: closeResultModalHandler,
    [usersConstants.USERS_CLOSE_CREATE_REDUCER]: closeCreateModalHandler,
    [usersConstants.USERS_DETAILS_OPEN_REDUCER]: openDetailsPanelHandler,
    [usersConstants.USERS_DETAILS_CLOSE_REDUCER]: closeDetailsPanelHandler,
    [usersConstants.USERS_SET_ACTIVE_REDUCER]: setActiveHandler,
    [usersConstants.USERS_FETCH_STARTED]: actionStartedHandler,
    [usersConstants.USERS_FETCH_SUCCESS]: fetchSuccessHandler,
    [usersConstants.USERS_FETCH_FAILURE]: failureHandler,
    [usersConstants.USERS_CREATE_STARTED]: actionStartedHandler,
    [usersConstants.USERS_CREATE_SUCCESS]: createSuccessHandler,
    [usersConstants.USERS_CREATE_FAILURE]: failureHandler,
    [usersConstants.USERS_DELETE_STARTED]: actionStartedHandler,
    [usersConstants.USERS_DELETE_SUCCESS]: deleteSuccessHandler,
    [usersConstants.USERS_DELETE_FAILURE]: failureHandler
  },
  initialState
);
