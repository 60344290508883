import * as request from './genericRequest';

const ListRoute = 'lists';
const CustomerRoute = 'customers';
const ProductRoute = 'products';
const SponsorRoute = 'sponsors';
const StudyRoute = 'studies';

export const getAllCustomers = async () => request.getApi(`${ListRoute}/${CustomerRoute}`);
export const getAllProducts = async () => request.getApi(`${ListRoute}/${ProductRoute}`);
export const getAllSponsors = async () => request.getApi(`${ListRoute}/${SponsorRoute}`);
export const getAllStudies = async () => request.getApi(`${ListRoute}/${StudyRoute}`);
export const getStudiesBySponsor = async (sponsor) => request.getApi(`${ListRoute}/${StudyRoute}`, { sponsor });
export const getStudiesByCustomer = async (customer) => request.getApi(`${ListRoute}/${StudyRoute}`, { customer });
export const getSponsorsByCustomer = async (customer) => request.getApi(`${ListRoute}/sponsorsByCustomer`, { customer });
export const getStudiesByCustomerAndSponsor = async ({ customer, sponsor }) => request.getApi(`${ListRoute}/studiesByCustomerAndSponsor`, { customer, sponsor });
