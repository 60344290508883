import * as authConstants from './constants';

export function startGetTokenMappingWorkFlow(payload) {
  return {
    type: authConstants.GET_TOKEN_MAPPING_WORKFLOW_STARTED,
    payload
  };
}

export function handleGetTokenMappingWorkFlowSuccess(payload) {
  return {
    type: authConstants.GET_TOKEN_MAPPING_WORKFLOW_SUCCESED,
    payload
  };
}

export function handleGetTokenMappingWorkFlowError(payload) {
  return {
    type: authConstants.GET_TOKEN_MAPPING_WORKFLOW_ERROR,
    payload
  };
}

export function handleGetTokenMappingWorkFlowFinished(payload) {
  return {
    type: authConstants.GET_TOKEN_MAPPING_WORKFLOW_FINISHED,
    payload
  };
}

export function updateGetTokenMappingState(payload) {
  return {
    type: authConstants.GET_TOKEN_MAPPING_STATE_UPDATE,
    payload
  };
}

export function startGetCurrentUserWorkFlow(payload) {
  return {
    type: authConstants.GET_CURRENT_USER_WORKFLOW_STARTED,
    payload
  };
}

export function handleGetCurrentUserWorkFlowSuccess(payload) {
  return {
    type: authConstants.GET_CURRENT_USER_WORKFLOW_SUCCESED,
    payload
  };
}

export function handleGetCurrentUserWorkFlowError(payload) {
  return {
    type: authConstants.GET_CURRENT_USER_WORKFLOW_ERROR,
    payload
  };
}

export function handleGetCurrentUserWorkFlowFinished(payload) {
  return {
    type: authConstants.GET_CURRENT_USER_WORKFLOW_FINISHED,
    payload
  };
}

export function updateGetCurrentUserState(payload) {
  return {
    type: authConstants.GET_CURRENT_USER_STATE_UPDATE,
    payload
  };
}

export function updateUserFoundState(payload) {
  return {
    type: authConstants.USER_FOUND_STATE_UPDATE,
    payload
  };
}
