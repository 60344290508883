const prefix = 'TOPICS';

// Sagas
export const TOPICS_FETCH_FAILURE = `${prefix}_FETCH_FAILURE`;
export const TOPICS_FETCH_STARTED = `${prefix}_FETCH_STARTED`;
export const TOPICS_FETCH_SUCCESS = `${prefix}_FETCH_SUCCESS`;

export const MESSAGES_FETCH_FAILURE = `${prefix}_MESSAGES_FETCH_FAILURE`;
export const MESSAGES_FETCH_STARTED = `${prefix}_MESSAGES_FETCH_STARTED`;
export const MESSAGES_FETCH_SUCCESS = `${prefix}_MESSAGES_FETCH_SUCCESS`;

// Reducers
export const TOPICS_DETAILS_CLOSE_REDUCER = `${prefix}_DETAILS_CLOSE_REDUCER`;
export const TOPICS_DETAILS_OPEN_REDUCER = `${prefix}_DETAILS_OPEN_REDUCER`;
export const TOPICS_FETCH_REDUCER = `${prefix}_FETCH_REDUCER`;
