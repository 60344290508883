import * as consumersConstants from './constants';

// Started
export const handleFetchConsumerStarted = (payload) => ({
  type: consumersConstants.CONSUMERS_FETCH_STARTED,
  payload
});

export const handleCreateConsumerStarted = (payload) => ({
  type: consumersConstants.CONSUMERS_CREATE_STARTED,
  payload
});

export const handleUpdateConsumerStarted = (payload) => ({
  type: consumersConstants.CONSUMERS_UPDATE_STARTED,
  payload
});

export const handleDeleteConsumerStarted = (payload) => ({
  type: consumersConstants.CONSUMERS_DELETE_STARTED,
  payload
});

export const handleFetchSubscribedEventsStarted = (payload) => ({
  type: consumersConstants.CONSUMERS_SUBSCRIBED_EVENTS_FETCH_STARTED,
  payload
});

// Success
export const handleFetchConsumerSuccess = (payload) => ({
  type: consumersConstants.CONSUMERS_FETCH_SUCCESS,
  payload
});

export const handleCreateConsumerSuccess = (payload) => ({
  type: consumersConstants.CONSUMERS_CREATE_SUCCESS,
  payload
});

export const handleUpdateConsumerSuccess = (payload) => ({
  type: consumersConstants.CONSUMERS_UPDATE_SUCCESS,
  payload
});

export const handleDeleteConsumerSuccess = (payload) => ({
  type: consumersConstants.CONSUMERS_DELETE_SUCCESS,
  payload
});

export const handleFetchSubscribedEventsSuccess = (payload) => ({
  type: consumersConstants.CONSUMERS_SUBSCRIBED_EVENTS_FETCH_SUCCESS,
  payload
});

// Failure
export const handleFetchConsumerFailure = (payload) => ({
  type: consumersConstants.CONSUMERS_FETCH_FAILURE,
  payload
});

export const handleCreateConsumerFailure = (payload) => ({
  type: consumersConstants.CONSUMERS_CREATE_FAILURE,
  payload
});

export const handleUpdateConsumerFailure = (payload) => ({
  type: consumersConstants.CONSUMERS_UPDATE_FAILURE,
  payload
});

export const handleDeleteConsumerFailure = (payload) => ({
  type: consumersConstants.CONSUMERS_DELETE_FAILURE,
  payload
});

export const handleFetchSubscribedEventsFailure = (payload) => ({
  type: consumersConstants.CONSUMERS_SUBSCRIBED_EVENTS_FETCH_FAILURE,
  payload
});

export const handleCloseCreateModal = (payload) => ({
  type: consumersConstants.CONSUMERS_CLOSE_EDIT_REDUCER,
  payload
});

export const handleOpenDetailsPanel = (payload) => ({
  type: consumersConstants.CONSUMERS_DETAILS_OPEN_REDUCER,
  payload
});

export const handleCloseDetailsPanel = (payload) => ({
  type: consumersConstants.CONSUMERS_DETAILS_CLOSE_REDUCER,
  payload
});

export const handleCloseDeleteModal = (payload) => ({
  type: consumersConstants.CONSUMERS_CLOSE_DELETE_REDUCER,
  payload
});

export const handleCloseResultModal = (payload) => ({
  type: consumersConstants.CONSUMERS_CLOSE_RESULT_REDUCER,
  payload
});

export const handleSetActiveConsumer = (payload) => ({
  type: consumersConstants.CONSUMERS_SET_ACTIVE_REDUCER,
  payload
});
