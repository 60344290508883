import * as COLORS from '../../sharedConstants';

export const styles = (theme) => ({
  success: {
    backgroundColor: COLORS.SUCCESS_LIGHT,
    color: COLORS.SUCCESS_MEDIUM
  },
  error: {
    backgroundColor: COLORS.ERROR_LIGHT,
    color: COLORS.ERROR_MEDIUM
  },
  info: {
    backgroundColor: COLORS.INFO_LIGHT,
    color: COLORS.INFO_MEDIUM
  },
  warning: {
    backgroundColor: COLORS.ATTENTION_LIGHT,
    color: COLORS.ATTENTION_MEDIUM
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  closeButton: {}
});
