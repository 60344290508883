import { call, put, takeLatest } from 'redux-saga/effects';

import { resetConsumerGroupById } from '../../../services/api/consumerGroup';
import * as consumerGroupActions from '../actions';
import * as consumerGroupConstants from '../constants';

function* resetConsumerGroup(action) {
  const { payload } = action;
  try {
    const response = yield call(resetConsumerGroupById, payload);
    if (response?.status === 200) {
      yield put(consumerGroupActions.handleResetConsumerGroupSuccess(payload));
      return;
    }
    throw new Error(response?.statusText);
  } catch (error) {
    yield put(consumerGroupActions.handleResetConsumerGroupFailure(error));
    // TODO: navigate to error page
  }
}
export function* watchResetConsumerGroup() {
  yield takeLatest(consumerGroupConstants.CONSUMERGROUPS_RESET_STARTED, resetConsumerGroup);
}
