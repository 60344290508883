import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReplayIcon from '@material-ui/icons/Replay';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { ConsumersGroupTableColumns, transform } from '../../models/consumerGroups';
import { consumerGroupSaga } from '../../redux/consumerGroups';
import * as actions from '../../redux/consumerGroups/actions';
import * as selectors from '../../redux/consumerGroups/selectors';
import { CONSUMERGROUP_RESETED_SUCCESS_MESSAGE } from '../../utils/constants';
import { injectSaga } from '../../utils/injectSaga';
import { ResultModal } from '../shared/modals/ResultModal';
import { CustomTable } from '../shared/table/customTable';
import { ResetConsumerGroupModal } from './modals/ResetConsumerGroupModal';
import { styles } from './styles';

const ConsumerGroupPage = (props) => {
  const { classes, selectedConsumer } = props;

  // Hooks
  const dispatch = useDispatch();

  // Redux Selectors
  const consumerGroups = useSelector(selectors.getConsumerGroups);
  const isLoading = useSelector(selectors.getIsLoading);
  const isResetModalOpen = useSelector(selectors.getIsResetModalOpen);
  const activeConsumerGroupId = useSelector(selectors.getActiveConsumerGroupId);
  const error = useSelector(selectors.getError);
  const isResultModalOpen = useSelector(selectors.getIsResultModalOpen);

  useEffect(() => {
    handleRefresh();
  }, [selectedConsumer]);

  // Interaction handlers Redux
  const handleRefresh = () => {
    dispatch(actions.handleFetchConsumerGroupsByConsumerIdStarted(selectedConsumer.id));
  };

  const handleResetStart = ({ id = null }) => {
    if (id) dispatch(actions.handleSetActiveConsumer(id));
    handleCloseResetModal(false);
  };

  const handleReset = () => {
    dispatch(actions.handleResetConsumerGroupStarted(activeConsumerGroupId));
  };

  const handleCloseResetModal = (payload) => {
    dispatch(actions.handleCloseResetModal(payload));
  };

  const handleCloseResultModal = (payload) => {
    dispatch(actions.handleCloseResultModal(payload));
  };

  // Static fields
  const columns = ConsumersGroupTableColumns();
  const actionTableButtons = createActionsButtons(handleResetStart);

  const tableStaticDefinition = {
    title: 'ConsumerGroups',
    defaultSortedColumnName: 'groupName',
    defaultSortOrder: 'asc',
    columns,
    isLoading,
    onClickFunc: () => {},
    isButtonDisabled: true,
    hideHeader: true,
    arrayOfActions: actionTableButtons,
    onRowClick: () => {}
  };

  return (
    <>
      <ResetConsumerGroupModal openDialog={isResetModalOpen} onCloseDialog={() => handleCloseResetModal(true)} onReset={handleReset} />
      <ResultModal
        openDialog={isResultModalOpen}
        onCloseDialog={() => handleCloseResultModal(true)}
        hasError={error.hasError}
        message={!error.hasError ? CONSUMERGROUP_RESETED_SUCCESS_MESSAGE : error.message}
      />
      <Grid item className={classes.gridItem}>
        <CustomTable
          key="main-consumer-table"
          {...tableStaticDefinition}
          data={consumerGroups.map((s) => transform(s))}
          dataCount={consumerGroups?.length ? consumerGroups.length : 10}
        />
      </Grid>
    </>
  );
};

// Setup functions
const handleResetWrapperStart = (event, row, handleResetStart) => {
  event.preventDefault();
  event.stopPropagation();
  handleResetStart(row);
};

const createActionsButtons = (handleResetStart) => [
  {
    icon: <ReplayIcon />,
    title: 'Reset Consumer Offset',
    ariaLabel: 'reset',
    action: (event, row) => handleResetWrapperStart(event, row, handleResetStart)
  }
];

// HOCs and Export
const styledConsumerGroup = withStyles(styles);
const withConsumerGroupSaga = injectSaga({ key: 'consumerGroupSaga', saga: consumerGroupSaga });

export const ConsumerGroup = compose(styledConsumerGroup, withConsumerGroupSaga)(ConsumerGroupPage);
