import { call, put, takeLatest } from 'redux-saga/effects';

import { Consumer } from './../../../models/consumers';
import { SubscribedEvent } from './../../../models/consumers/subscribedEvent';
import { getAllConsumers, getSubscribedEventsByTopicName } from '../../../services/api/consumer';
import * as consumerActions from '../actions';
import * as consumerConstants from '../constants';

function* fetchConsumer() {
  try {
    let data = [];
    const response = yield call(getAllConsumers);

    if (response?.status === 200) {
      data = response.data.payload.map((s) => new Consumer(s));
      yield put(consumerActions.handleFetchConsumerSuccess(data));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(consumerActions.handleFetchConsumerFailure(error));
    // TODO: navigate to error page
  }
}

function* fetchConsumerSubscribedEvents(action) {
  try {
    let data = [];
    const response = yield call(getSubscribedEventsByTopicName, action.payload);

    if (response?.status === 200) {
      data = response.data.payload.map((s) => new SubscribedEvent(s));
      yield put(consumerActions.handleFetchSubscribedEventsSuccess(data));

      return;
    }
  } catch (error) {
    yield put(consumerActions.handleFetchSubscribedEventsFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchFetchConsumer() {
  yield takeLatest(consumerConstants.CONSUMERS_FETCH_STARTED, fetchConsumer);
  yield takeLatest(consumerConstants.CONSUMERS_SUBSCRIBED_EVENTS_FETCH_STARTED, fetchConsumerSubscribedEvents);
}
