import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent, OidcProvider, loadUser } from 'redux-oidc';

import { store } from '../../configureStore';
import { startGetTokenMappingWorkFlow } from '../../redux/auth/actions';
import { styles } from './styles';
import { currentUserManager } from './userManager';

class CallbackView extends React.Component {
  constructor(props) {
    super(props);
    this.userManager = currentUserManager;
  }

  successCallback = (user) => {
    loadUser(store, this.userManager);
    this.props.dispatch(startGetTokenMappingWorkFlow(user));
  };

  errorCallback = (error) => {
    console.log('Error callback...');
    this.props.dispatch(push('/'));
    // eslint-disable-next-line no-console
    console.error(error);
  };

  render() {
    return (
      <OidcProvider store={store} userManager={this.userManager}>
        <CallbackComponent userManager={this.userManager} successCallback={this.successCallback} errorCallback={this.errorCallback}>
          <center>
            <CircularProgress variant="indeterminate" disableShrink className={this.props.classes.spinner} size={51} thickness={4} />
          </center>
        </CallbackComponent>
      </OidcProvider>
    );
  }
}

const StyledCallback = withStyles(styles)(CallbackView);

export const Callback = connect()(StyledCallback);
