import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CustomComboBox } from '../../shared';
import { styles } from '../styles';

const NoneValue = '';
const isNullOrEmpty = (s) => !s || s.trim() === '';

const SubscriptionSourceCheckBoxGroup = ({
  classes,
  handleSelectionChange,
  subscription = { customer: null, sponsor: null, study: null },
  sponsors,
  studies
}) => {
  const initialState = {
    customer: false,
    sponsor: false,
    study: false
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      ...state,
      customer: !isNullOrEmpty(subscription.customer),
      sponsor: !isNullOrEmpty(subscription.sponsor),
      study: !isNullOrEmpty(subscription.study)
    });

    return () => {
      setState(initialState);
    };
  }, [subscription]);

  const handleCheckboxChange = ({ target }) => {
    const { name, checked } = target;

    setState({ ...state, [name]: checked });
    if (!checked) handleSelectionChange(name, null);
  };

  const { sponsor, study } = state;

  return (
    <>
      <FormControl
        key="source-control_subscription"
        component="fieldset"
        style={{ width: '100%' }}>
        <FormGroup>
          <FormControlLabel key="sponsor" control={<Checkbox disabled={!subscription.customer} checked={sponsor} onChange={handleCheckboxChange} name="sponsor" />} label="Sponsor" />
          {state.sponsor && (
            <CustomComboBox
              id="sponsor"
              key="sponsor-select"
              enableLabelText={false}
              placeholderText="Select or Add..."
              visible
              options={sponsors}
              onChangeEvent={(s) => handleSelectionChange('sponsor', s.value)}
              value={state.sponsor ? subscription.sponsor ?? NoneValue : NoneValue}
            />
          )}
          <FormControlLabel key="study" control={<Checkbox disabled={!subscription.sponsor} checked={study} onChange={handleCheckboxChange} name="study" />} label="Study" />
          {state.study && (
            <CustomComboBox
              id="study"
              key="study-select"
              enableLabelText={false}
              placeholderText="Select or Add..."
              visible
              options={studies}
              onChangeEvent={(s) => handleSelectionChange('study', s.value)}
              value={state.study ? subscription.study ?? NoneValue : NoneValue}
            />
          )}
        </FormGroup>
      </FormControl>
    </>
  );
};

SubscriptionSourceCheckBoxGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  handleSelectionChange: PropTypes.func,
  products: PropTypes.array,
  sponsors: PropTypes.array,
  studies: PropTypes.array
};

export const SourceCheckBoxGroup = withStyles(styles)(SubscriptionSourceCheckBoxGroup);
