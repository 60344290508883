import { Container, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomModal } from '../../shared';
import { styles } from './styles';

const deleteEventModal = (props) => {
  const { openDialog, onCloseDialog, onDelete, classes } = props;

  return (
    <Grid container spacing={2}>
      <CustomModal
        className={classes.root}
        dialogTitle="Delete Event"
        openDialog={openDialog}
        onCloseDialog={onCloseDialog}
        arrayOfButtons={[
          {
            label: 'Cancel',
            color: 'default',
            enabled: true,
            action: () => {
              onCloseDialog();
            }
          },
          {
            label: 'Yes',
            variant: 'contained',
            color: 'primary',
            enabled: true,
            action: () => {
              onDelete();
              onCloseDialog();
            }
          }
        ]}>
        <Grid key="eventName" item xs={12} className={classes.row}>
          <Container>
            <Typography paragraph>Are you sure?</Typography>
          </Container>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

deleteEventModal.defaultProps = {
  openDialog: false
};

deleteEventModal.propTypes = {
  /**
   * @ignore
   */
  classes: PropTypes.object.isRequired,
  /**
   * openDialog indicate is the dialog is open or closed
   */
  openDialog: PropTypes.bool,
  /**
   * onCloseDialog fired when the user hits the close button from dialog component
   */
  onCloseDialog: PropTypes.func,
  /**
   * onCreateUpdate fired when the user hits the ok button from dialog component
   */
  onDelete: PropTypes.func
};

export const DeleteEventModal = withStyles(styles)(deleteEventModal);
