/* eslint-disable no-nested-ternary */
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';

export const FormError = ({ message }) => (
  <Grid key="form-error" item xs={12}>
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <strong>
        {typeof message === 'string'
          ? message
          : typeof message === 'object'
          ? JSON.stringify(message.errors ?? 'Unexpected error...')
          : 'something went wrong...'}
      </strong>
    </Alert>
  </Grid>
);

FormError.propTypes = {
  message: PropTypes.string.isRequired
};
