export const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start'
  },
  row: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3)
  },
  gridItem: {
    padding: '1em'
  },
  select: {}
});
