import { IconButton } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

export const CustomActionCell = ({ column, arrayOfActions, row }) => {
  return (
    <TableCell key={column.id} align={column.align}>
      {arrayOfActions.map((currentButton, index) => (
        <Tooltip key={index} title={currentButton.title} aria-label={currentButton.ariaLabel}>
          <IconButton
            disabled={currentButton.isButtonDisabled}
            size="small"
            component="span"
            aria-describedby={row.id}
            onClick={(event) => currentButton.action(event, row)}>
            {currentButton.icon}
          </IconButton>
        </Tooltip>
      ))}
    </TableCell>
  );
};
