import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import logo from '../../../assets/IQVIALogoWhite.png';
import { getTokenMappingStatus } from '../../redux/auth/selectors';
import { currentUserManager } from '../auth/userManager';
import { styles } from './headerStyle';

const getUserState = ({ oidc }) => {
  const { user } = oidc;
  if (!user) return null;
  const { profile } = user;
  if (!profile) return null;
  // eslint-disable-next-line camelcase
  const { first_name, last_name, username, email } = profile;
  return {
    // eslint-disable-next-line camelcase
    displayName: `${first_name} ${last_name}`,
    userName: username,
    email
  };
};
const getUser = (state) => getUserState(state) ?? { displayName: '', userName: '', email: '' };
const getDisplayName = (state) => `${getUser(state).displayName}`;
const getUserName = (state) => getUser(state).userName;
const getEmail = (state) => getUser(state).email;

const CustomNavBar = ({ history, location: { pathname }, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpenMenu] = React.useState(false);
  const logoProps = {
    src: logo,
    text: 'Event Notification Service',
    onClick: () => history.push('/')
  };

  const displayName = useSelector(getDisplayName);
  const userName = useSelector(getUserName);
  const email = useSelector(getEmail);
  const tokenMappingStatus = useSelector(getTokenMappingStatus);

  const onLogoutButtonClick = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    currentUserManager.signoutRedirect();
  };

  const profileMenuProps = {
    // eslint-disable-next-line camelcase
    name: displayName,
    icon: React.createElement(PersonOutlineIcon),
    title: userName,
    email,
    onClick: onLogoutButtonClick,
    menuItems: []
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  return (
    tokenMappingStatus === 'OK' && (
      <div className={classes.root}>
        <AppBar position="static" className={classes.headerRoot}>
          <Toolbar className={classes.toolbar}>
            <img onClick={logoProps.onClick} src={logoProps.src} alt="logo" className={classes.logo} />
            <Typography onClick={logoProps.onClick} variant="h4" className={classes.textLogo}>
              {logoProps.text}
            </Typography>
            <div>
              <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                {profileMenuProps.icon}
                <span className={classes.displayName}>{profileMenuProps.name}</span>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={handleClose}>
                <div className={classes.menuInformation}>
                  <h4>{profileMenuProps.title}</h4>
                  <p>{profileMenuProps.email}</p>
                  <hr></hr>
                </div>
                <button onClick={profileMenuProps.onClick} className={classes.logoutButton}>
                  Log out
                </button>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  );
};

export const Header = compose(withStyles(styles))(withRouter(CustomNavBar));
