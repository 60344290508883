import * as usersConstants from './constants';

// Started
export const handleFetchUserStarted = (payload) => ({
  type: usersConstants.USERS_FETCH_STARTED,
  payload
});

export const handleCreateUserStarted = (payload) => ({
  type: usersConstants.USERS_CREATE_STARTED,
  payload
});

export const handleDeleteUserStarted = (payload) => ({
  type: usersConstants.USERS_DELETE_STARTED,
  payload
});

// Success
export const handleFetchUserSuccess = (payload) => ({
  type: usersConstants.USERS_FETCH_SUCCESS,
  payload
});

export const handleCreateUserSuccess = (payload) => ({
  type: usersConstants.USERS_CREATE_SUCCESS,
  payload
});

export const handleDeleteUserSuccess = (payload) => ({
  type: usersConstants.USERS_DELETE_SUCCESS,
  payload
});

// Failure
export const handleFetchUserFailure = (payload) => ({
  type: usersConstants.USERS_FETCH_FAILURE,
  payload
});

export const handleCreateUserFailure = (payload) => ({
  type: usersConstants.USERS_CREATE_FAILURE,
  payload
});

export const handleDeleteUserFailure = (payload) => ({
  type: usersConstants.USERS_DELETE_FAILURE,
  payload
});

export const handleOpenDetailsPanel = (payload) => ({
  type: usersConstants.USERS_DETAILS_OPEN_REDUCER,
  payload
});

export const handleCloseCreateModal = (payload) => ({
  type: usersConstants.USERS_CLOSE_CREATE_REDUCER,
  payload
});

export const handleCloseDetailsPanel = (payload) => ({
  type: usersConstants.USERS_DETAILS_CLOSE_REDUCER,
  payload
});

export const handleCloseDeleteModal = (payload) => ({
  type: usersConstants.USERS_CLOSE_DELETE_REDUCER,
  payload
});

export const handleCloseResultModal = (payload) => ({
  type: usersConstants.USERS_CLOSE_RESULT_REDUCER,
  payload
});

export const handleSetActiveUser = (payload) => ({
  type: usersConstants.USERS_SET_ACTIVE_REDUCER,
  payload
});
