import TableCell from '@material-ui/core/TableCell';
import React from 'react';

export const CustomCell = ({ column, value }) => {
  return (
    <TableCell key={column.id} align={column.align}>
      {column.format && typeof value === 'number' ? column.format(value) : value}
    </TableCell>
  );
};
