import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Subscription } from '../../../models/subscriptions';
import { putSubscriptionById } from '../../../services/api/subscription';
import * as subscriptionsActions from '../actions';
import * as subscriptionsConstants from '../constants';
import { getSubscriptions } from '../selectors';

const checkDuplicates = (data, payload) => {
  const subscription = new Subscription(payload);
  let result;

  for (let index = 0; index < data.length; index++) {
    const current = data[index];

    result = subscription.isDuplicate(current);
    if (result.isDuplicate) break;
  }

  return result;
};

export function* updateSubscription(action) {
  const { payload } = action;

  try {
    if (!payload) throw new Error('Incorrect Subscription.');

    const subscriptions = yield select(getSubscriptions);
    const result = yield call(checkDuplicates, subscriptions, payload);

    if (result.isDuplicate) {
      throw new Error(result.message);
    }

    const response = yield call(putSubscriptionById, payload.id, payload);

    if (response?.status === 200) {
      yield put(subscriptionsActions.handleUpdateSubscriptionSuccess(new Subscription(payload)));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    const formattedError = { message: `${error?.message}. ${error?.data ?? ''}` };

    yield put(subscriptionsActions.handleUpdateSubscriptionFailure(formattedError));
  }
}

export function* watchUpdateSubscription() {
  yield takeLatest(subscriptionsConstants.SUBSCRIPTIONS_UPDATE_STARTED, updateSubscription);
}
