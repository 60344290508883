export const getUsers = (state) => state.users.data;
export const getError = (state) => state.users.error;
export const getIsCreateModalOpen = (state) => state.users.isCreateModalOpen;
export const getIsDetailsPanelOpen = (state) => state.users.isDetailsPanelOpen;
export const getIsDeleteModalOpen = (state) => state.users.isDeleteModalOpen;
export const getIsLoading = (state) => state.users.isLoading;
export const getActiveUser = (state) => state.users.activeUser;
export const getClientIds = (state) => state.users.data.map((s)=> s.clientId);
export const getSelectedUser = (state) => state.users.data.find((s) => s.clientId === getActiveUser(state));
export const getCreatedUser = (state) => state.users.createdUser;
export const getIsResultModalOpen = (state) => state.users.isResultModalOpen;
export const getResultModalMode = (state) => state.users.resultModalMode;