import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteForever from '@material-ui/icons/DeleteForever';
import ReplayIcon from '@material-ui/icons/Replay';
import React, { Fragment, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { ConsumersTableColumns } from '../../models/consumers';
import * as commonActions from '../../redux/common/actions';
import * as commonSelectors from '../../redux/common/selectors';
import { consumerSaga } from '../../redux/consumers';
import * as actions from '../../redux/consumers/actions';
import * as selectors from '../../redux/consumers/selectors';
import * as subscriptionActions from '../../redux/subscriptions/actions';
import * as subscriptionSelectors from '../../redux/subscriptions/selectors';
import * as userActions from '../../redux/users/actions';
import { CONSUMER_SUCCESS_MESSAGE, CONSUMER_UPDATED_SUCCESS_MESSAGE } from '../../utils/constants';
import { injectSaga } from '../../utils/injectSaga';
import { mapListToOptions } from '../../utils/utilityFunctions';
import { DetailsPanel, JsonViewer } from '../shared';
import { ResultModal } from '../shared/modals/ResultModal';
import { CustomTable } from '../shared/table/customTable';
import { ConsumerGroup } from './ConsumerGroups';
import { ConsumerForm } from './forms/ConsumerForm';
import { CreateConsumerModal } from './modals/CreateConsumerModal';
import { DeleteConsumerModal } from './modals/DeleteConsumerModal';
import { styles } from './styles';

const ConsumerPage = (props) => {
  const { classes } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [enableAddSubscriptions, setEnableAddSubscriptions] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const mapSubscriptionsListToOptions = (list, nameProp = 'eventSource') => {
    if (!list) return [];

    if (Array.isArray(list) && list.length === 0) return [];

    const findValueFunc = (item) => {
      const value = typeof item === 'string' ? item : item.id;
      const foundSubscription = subscriptionList.find((s) => s.id === value);
      return foundSubscription ? foundSubscription.getViewModel() : null;
    };

    return list
      .map((item) => {
        const value = findValueFunc(item);
        if (!value) return;
        return { value: value.id, name: value[nameProp] };
      })
      .filter((l) => typeof l !== 'undefined'); // TODO: Delete subscriptionId from consumer on backend side.
  };

  const onValidationChange = (isFormValid) => {
    setEnableButton(isFormValid);
  };

  const getSubscriptionsByTopicName = (topicName) => {
    if (!topicName) {
      setEnableAddSubscriptions(false);
      return [];
    }

    if (consumers.some((c) => c.topicName === topicName.value)) {
      const subscriptionsByTopicName = consumers.find((c) => c.topicName === topicName.value).subscriptionIds;
      setEnableAddSubscriptions(false);
      return mapSubscriptionsListToOptions(subscriptionsByTopicName);
    } else {
      setEnableAddSubscriptions(true);
      return [];
    }
  };

  // Hooks
  const dispatch = useDispatch();

  // Redux Selectors
  const consumers = useSelector(selectors.getConsumers);
  const subscriptionList = useSelector(subscriptionSelectors.getSubscriptions);
  const isCreateModalOpen = useSelector(selectors.getIsCreateModalOpen);
  const isDeleteModalOpen = useSelector(selectors.getIsDeleteModalOpen);
  const isLoading = useSelector(selectors.getIsLoading);
  const activeUserName = useSelector(selectors.getActiveUserName);
  const selectedConsumer = useSelector(selectors.getSelectedConsumer);
  const isDetailsPanelOpen = useSelector(selectors.getIsDetailsPanelOpen);
  const error = useSelector(selectors.getError);
  const isResultModalOpen = useSelector(selectors.getIsResultModalOpen);
  const resultModalMode = useSelector(selectors.getResultModalMode);
  const subscribedEventsByConsumer = useSelector(selectors.getSubscribedEventsByConsumer);
  const selectedUsers = useSelector(selectors.getSelectedUsers);
  const clientIds = useSelector(commonSelectors.getClientIds);

  useEffect(() => {
    handleRefresh();
  }, []);

  // Interaction handlers Redux
  const handleDeleteStart = ({ userName = null }) => {
    if (userName) dispatch(actions.handleSetActiveConsumer(userName));
    handleCloseDeleteModal(false);
  };

  const handleCloseCreateModal = (payload) => {
    dispatch(actions.handleCloseCreateModal(payload));
  };

  const handleCloseDetailsPanel = () => {
    dispatch(actions.handleCloseDetailsPanel());
  };

  const handleRefresh = () => {
    batch(() => {
      dispatch(actions.handleFetchConsumerStarted());
      dispatch(subscriptionActions.handleFetchSubscriptionStarted());
      dispatch(userActions.handleFetchUserStarted());
      dispatch(commonActions.handleFetchClientIdStarted());
    });
  };

  const handleCreate = (payload) => {
    if (!payload) return;

    dispatch(actions.handleCreateConsumerStarted(payload));
  };

  const handleUpdate = (payload) => {
    if (!payload) return;

    dispatch(actions.handleUpdateConsumerStarted(payload));
  };

  const handleDelete = () => {
    dispatch(actions.handleDeleteConsumerStarted(activeUserName));
  };

  const handleOpenDetailsPanel = ({ userName = null, topicName }) => {
    if (userName) dispatch(actions.handleSetActiveConsumer(userName));
    dispatch(actions.handleOpenDetailsPanel());
    dispatch(actions.handleFetchSubscribedEventsStarted(topicName));
  };

  const handleCloseResultModal = (payload) => {
    dispatch(actions.handleCloseResultModal(payload));
  };

  const handleCloseDeleteModal = (payload) => {
    dispatch(actions.handleCloseDeleteModal(payload));
  };

  const handleCancelEdit = () => {
    handleCloseCreateModal(true);
  };

  // Static fields
  const columns = ConsumersTableColumns();
  const actionTableButtons = createActionsButtons(handleDeleteStart);
  const tableStaticDefinition = {
    title: 'Consumers',
    defaultSortedColumnName: 'userName',
    defaultSortOrder: 'asc',
    columns,
    onClickFunc: () => handleCloseCreateModal(false),
    isButtonDisabled: isLoading,
    isLoading,
    arrayOfActions: actionTableButtons,
    onRowClick: handleOpenDetailsPanel
  };
  const detailsPanelButtons = createDetailsPanelButtons(enableButton, setIsSaving, handleCancelEdit);

  const resultModalOutput = () => {
    if (!error.hasError) {
      if (resultModalMode !== 'update') return CONSUMER_SUCCESS_MESSAGE;

      return CONSUMER_UPDATED_SUCCESS_MESSAGE;
    }

    return error.message;
  };

  return (
    <Fragment>
      {isCreateModalOpen && (
        <CreateConsumerModal
          key="main-modal-create-consumer"
          openDialog={isCreateModalOpen}
          consumers={consumers}
          getSubscriptionsByTopicName={getSubscriptionsByTopicName}
          subscriptionList={mapSubscriptionsListToOptions(subscriptionList)}
          enableAddSubscriptions={enableAddSubscriptions}
          onCloseDialog={() => handleCancelEdit()}
          selectedUsers={selectedUsers}
          users={mapListToOptions(clientIds.filter((s) => !selectedUsers.includes(s)))}
          onSubmit={handleCreate}
        />
      )}
      <DeleteConsumerModal openDialog={isDeleteModalOpen} onCloseDialog={() => handleCloseDeleteModal(true)} onDelete={handleDelete} />
      <ResultModal
        openDialog={isResultModalOpen}
        onCloseDialog={() => handleCloseResultModal(true)}
        hasError={error.hasError}
        message={resultModalOutput()}
      />
      <Grid key="main-consumer-table-grid-wrapper" container spacing={0} alignItems="flex-start" justifyContent="center">
        <Grid item xs={isDetailsPanelOpen ? 8 : 12} className={classes.gridItem}>
          <CustomTable
            {...tableStaticDefinition}
            data={consumers.map((s) => s.getViewModel())}
            dataCount={consumers?.length ? consumers.length : 10}
          />
        </Grid>
        {isDetailsPanelOpen && (
          <Grid key="consumer-panel-grid" item xs={4} className={classes.gridItem}>
            <DetailsPanel
              key="consumer-panel"
              hideDefaultCancelButton={enableButton}
              title="Consumer Details"
              closePanel={handleCloseDetailsPanel}
              arrayOfButtons={detailsPanelButtons}>
              <ConsumerForm
                classes={classes}
                isSaving={isSaving}
                consumers={consumers}
                subscriptionList={mapSubscriptionsListToOptions(subscriptionList)}
                readOnly={isDetailsPanelOpen}
                isEditMode={isDetailsPanelOpen}
                enableAddSubscriptions={enableAddSubscriptions}
                selectedUsers={selectedUsers}
                users={mapListToOptions(clientIds)}
                initialValues={{
                  userName: { value: selectedConsumer.userName, name: selectedConsumer.userName },
                  topicName: { value: selectedConsumer.topicName, name: selectedConsumer.topicName },
                  consumerSubscriptions: mapSubscriptionsListToOptions(selectedConsumer.subscriptionIds)
                }}
                onSave={() => setIsSaving(false)}
                onValidationChange={onValidationChange}
                onSubmit={handleUpdate}
              />
              <ConsumerGroup selectedConsumer={selectedConsumer} />
              <br />
              <Typography color="textSecondary" className={classes.jsonTitle}>
                Subscribed Events:
              </Typography>
              <JsonViewer error={error} json={subscribedEventsByConsumer} />
            </DetailsPanel>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

// Setup functions
const handleDeleteWrapperStart = (event, row, handleDeleteStart) => {
  event.preventDefault();
  event.stopPropagation();
  handleDeleteStart(row);
};

const createDetailsPanelButtons = (enableButton, setIsSaving, handleCancelEdit) =>
  enableButton
    ? [
        {
          label: 'Cancel',
          variant: 'outlined',
          enabled: true,
          action: () => handleCancelEdit()
        },
        {
          label: 'Save',
          variant: 'contained',
          color: 'primary',
          enabled: enableButton,
          action: () => setIsSaving(true)
        }
      ]
    : [];

const createActionsButtons = (handleDeleteStart) => [
  {
    icon: <DeleteForever />,
    title: 'Delete Consumer',
    ariaLabel: 'delete',
    action: (event, row) => handleDeleteWrapperStart(event, row, handleDeleteStart)
  }
];

// HOCs and Export
const styledConsumer = withStyles(styles);
const withConsumerSaga = injectSaga({ key: 'consumerSaga', saga: consumerSaga });

export const Consumer = compose(styledConsumer, withConsumerSaga)(ConsumerPage);
