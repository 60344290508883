import * as commonConstants from './constants';

// Started
export const handleFetchCustomerStarted = (payload) => ({
  type: commonConstants.COMMON_CUSTOMERS_FETCH_STARTED,
  payload
});
export const handleFetchProductStarted = (payload) => ({
  type: commonConstants.COMMON_PRODUCTS_FETCH_STARTED,
  payload
});
export const handleFetchSponsorStarted = (payload) => ({
  type: commonConstants.COMMON_SPONSORS_FETCH_STARTED,
  payload
});
export const handleFetchStudyStarted = (payload) => ({
  type: commonConstants.COMMON_STUDIES_FETCH_STARTED,
  payload
});
export const handleFetchClientIdStarted = (payload) => ({
  type: commonConstants.COMMON_CLIENTIDS_FETCH_STARTED,
  payload
});

// Success
export const handleFetchCustomerSuccess = (payload) => ({
  type: commonConstants.COMMON_CUSTOMERS_FETCH_SUCCESS,
  payload
});
export const handleFetchProductSuccess = (payload) => ({
  type: commonConstants.COMMON_PRODUCTS_FETCH_SUCCESS,
  payload
});
export const handleFetchSponsorSuccess = (payload) => ({
  type: commonConstants.COMMON_SPONSORS_FETCH_SUCCESS,
  payload
});
export const handleFetchStudySuccess = (payload) => ({
  type: commonConstants.COMMON_STUDIES_FETCH_SUCCESS,
  payload
});
export const handleFetchClientIdSuccess = (payload) => ({
  type: commonConstants.COMMON_CLIENTIDS_FETCH_SUCCESS,
  payload
});

// Failure
export const handleFetchCustomerFailure = (payload) => ({
  type: commonConstants.COMMON_CUSTOMERS_FETCH_FAILURE,
  payload
});
export const handleFetchProductFailure = (payload) => ({
  type: commonConstants.COMMON_PRODUCTS_FETCH_FAILURE,
  payload
});
export const handleFetchSponsorFailure = (payload) => ({
  type: commonConstants.COMMON_SPONSORS_FETCH_FAILURE,
  payload
});
export const handleFetchStudyFailure = (payload) => ({
  type: commonConstants.COMMON_STUDIES_FETCH_FAILURE,
  payload
});
export const handleFetchClientIdFailure = (payload) => ({
  type: commonConstants.COMMON_CLIENTIDS_FETCH_FAILURE,
  payload
});
// Reducer

export const handleSetCustomer = (payload) => ({
  type: commonConstants.COMMON_CUSTOMERS_SET_REDUCER,
  payload
});
export const handleSelectedCustomer = (payload) => ({
  type: commonConstants.COMMON_SELECTED_CUSTOMER_SET_REDUCER,
  payload
});
export const handleSetProduct = (payload) => ({
  type: commonConstants.COMMON_PRODUCTS_SET_REDUCER,
  payload
});
export const handleSetSponsor = (payload) => ({
  type: commonConstants.COMMON_SPONSORS_SET_REDUCER,
  payload
});
export const handleSetStudy = (payload) => ({
  type: commonConstants.COMMON_STUDIES_SET_REDUCER,
  payload
});
