import { Box, FormControl, FormHelperText, InputLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
/* eslint-disable no-use-before-define */
import React from 'react';

import { styles } from './CustomComboBoxStyle';

const filter = createFilterOptions({
  trim: true
});

const CustomComboBoxComponent = (props) => {
  const {
    id,
    enableLabelText,
    labelText,
    defaultValue,
    placeholderText,
    isRequired,
    options,
    onChangeEvent,
    visible,
    classes,
    value,
    ...rest
  } = props;
  const [isNoneValueSelected, setIsNoneValueSelected] = React.useState(false);

  const error = isRequired && isNoneValueSelected;

  const handleOnChange = (event, newValue) => {
    let selectedValue = null;

    if (typeof newValue === 'string' && newValue !== '') {
      selectedValue = newValue;
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      selectedValue = newValue.inputValue;
    } else if (newValue && newValue.value) {
      selectedValue = newValue.value;
    }

    setIsNoneValueSelected(!selectedValue || selectedValue === '');
    onChangeEvent({ value: selectedValue });
  };

  return (
    <>
      {visible ? (
        <Box component="div" className={classes.root}>
          {enableLabelText ? (
            <InputLabel className={classes.inputLabel} htmlFor={id}>
              {labelText}
            </InputLabel>
          ) : null}
          <FormControl required={isRequired} className={classes.formControl} error={error}>
            <Autocomplete
              {...rest}
              freeSolo
              value={value}
              className={classes.autoComplete}
              onChange={handleOnChange}
              size="small"
              filterOptions={(ops, params) => {
                const filtered = filter(ops, params);
                const { inputValue } = params;
                const valueExist = filtered.some( ({ value }) => value.toLowerCase() == inputValue.trim().toLowerCase() );
                // Suggest the creation of a new value
                if ( inputValue.trim() !== '' && !valueExist ) {
                  filtered.push({
                    inputValue,
                    name: `Add "${inputValue.trim()}"`
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id={id}
              options={options}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }

                // Regular option
                return option.value;
              }}
              renderOption={(option) => option.name}
              renderInput={(params) => <TextField variant="outlined" placeholder={placeholderText} {...params} />}
              style={{ width: '100%' }}
            />
            {error && <FormHelperText className={classes.labelControl}>One option must be selected or created</FormHelperText>}
          </FormControl>
        </Box>
      ) : null}
    </>
  );
};

// Typed props
CustomComboBoxComponent.propTypes = {
  id: PropTypes.string,
  enableLabelText: PropTypes.bool,
  labelText: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholderText: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChangeEvent: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

export const CustomComboBox = withStyles(styles)(CustomComboBoxComponent);
