// const userUrl = '/root/users';

export const logOut = async (reason) => {
  let queryString = '';

  if (reason) {
    queryString = `?${reason}=1`;
  }

  const relativePath = global.location.pathname
    .split('/')
    .filter((value) => value !== '')
    .map((_) => '..')
    .join('/');

  const redirect = `${relativePath}/logout.aspx${queryString}`;

  // need to redirect to parent app to handle logout, which we do
  // by changing the browsers url as we need to go outside of react site
  // using location.assign rather than location.href for testability
  // see https://github.com/facebook/jest/issues/890#issuecomment-295939071
  window.location.assign(redirect);
};

// TODO: Implement server side
export const getTokenMapping = (user) => {
  const { profile } = user;
  if (!profile || !profile.groups.find((g) => g.toLowerCase() === '/ens/admins')) {
    return {
      mapping: undefined,
      status: 'error'
    };
  }
  return {
    mapping: 'true',
    status: 'OK'
  };
};
