const prefix = 'CONSUMERGROUPS';

// Sagas
export const CONSUMERGROUPS_FETCH_FAILURE = `${prefix}_FETCH_FAILURE`;
export const CONSUMERGROUPS_FETCH_STARTED = `${prefix}_FETCH_STARTED`;
export const CONSUMERGROUPS_FETCH_SUCCESS = `${prefix}_FETCH_SUCCESS`;

export const CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_FAILURE = `${prefix}_FETCH_BY_CONSUMER_ID_FAILURE`;
export const CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_STARTED = `${prefix}_FETCH_BY_CONSUMER_ID_STARTED`;
export const CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_SUCCESS = `${prefix}_FETCH_BY_CONSUMER_ID_SUCCESS`;

export const CONSUMERGROUPS_RESET_FAILURE = `${prefix}_RESET_FAILURE`;
export const CONSUMERGROUPS_RESET_STARTED = `${prefix}_RESET_STARTED`;
export const CONSUMERGROUPS_RESET_SUCCESS = `${prefix}_RESET_SUCCESS`;

export const CONSUMERGROUPS_SET_ACTIVE_REDUCER = `${prefix}_SET_ACTIVE_REDUCER`;
export const CONSUMERGROUPS_CLOSE_RESET_REDUCER = `${prefix}_CLOSE_RESET_REDUCER`;
export const CONSUMERGROUPS_CLOSE_RESULT_REDUCER = `${prefix}_CLOSE_RESULT_REDUCER`;
