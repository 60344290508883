export const getEvents = (state) => state.events.data;
export const getIsCreateModalOpen = (state) => state.events.isCreateModalOpen;
export const getIsDeleteModalOpen = (state) => state.events.isDeleteModalOpen;
export const getIsLoading = (state) => state.events.isLoading;
export const getActiveEventId = (state) => state.events.activeEventId;
export const getSelectedEvent = (state) => state.events.data.find((s) => s.id === getActiveEventId(state));
export const getChanges = (state) => state.events.changes;
export const getIsDetailsPanelOpen = (state) => state.events.isDetailsPanelOpen;
export const getError = (state) => state.events.error;
export const getProducts = () => [{ value: "econsent", name: "econsent" }];
export const getIsResultModalOpen = (state) => state.events.isResultModalOpen;
export const getJsonSelector = (state) => {
  const foundEvent = getSelectedEvent(state);

  return foundEvent ? { name: foundEvent.eventTypeName, json: foundEvent.json } : {};
};

export const getIsEditableMode = (state) => state.events.isEditableMode;
export const getIsMajorUpdate = (state) => state.events.isMajorUpdate;
export const getResultModalMode = (state) => state.events.resultModalMode;
