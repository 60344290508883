export class HttpClientConfig {
  tokenCallback = () => null;
  baseURL = '';
  standardErrorResponseHandler;
  cookieAuth = false;

  constructor(props) {
    if (props) {
      this.tokenCallback = props.tokenCallback || this.tokenCallback;
      this.baseURL = props.baseURL || this.baseURL;
      this.standardErrorResponseHandler = props.standardErrorResponseHandler || this.standardErrorResponseHandler;
      this.cookieAuth = props.cookieAuth || this.cookieAuth;
    }
  }
}
