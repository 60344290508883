import { call, put, takeLatest } from 'redux-saga/effects';

import { Consumer } from './../../../models/consumers';
import { postConsumer } from '../../../services/api/consumer';
import * as consumerActions from '../actions';
import * as consumerConstants from '../constants';

function* createConsumer(action) {
  const { payload } = action;

  try {
    if (!payload) throw new Error('Incorrect Consumer.');

    const request = Object.keys(payload).reduce((acc, current) => {
      if (payload[current] !== null) {
        return { ...acc, [current]: payload[current] };
      }

      return acc;
    }, {});

    const response = yield call(postConsumer, request);

    if (response?.status === 200) {
      const consumer = new Consumer(response.data.payload);

      yield put(consumerActions.handleCreateConsumerSuccess(consumer));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(consumerActions.handleCreateConsumerFailure(error));
  }
}

export function* watchCreateConsumer() {
  yield takeLatest(consumerConstants.CONSUMERS_CREATE_STARTED, createConsumer);
}
