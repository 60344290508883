import { CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { TopicsTableColumns } from '../../models/topics';
import * as actions from '../../redux/topics/actions';
import { topicSaga } from '../../redux/topics/sagas';
import * as selectors from '../../redux/topics/selectors';
import { injectSaga } from '../../utils/injectSaga';
import { DetailsPanel, JsonViewer } from '../shared';
import { CustomTable } from '../shared/table/customTable';
import { styles } from './styles';

const TopicPage = ({ classes }) => {
  // Hooks
  const dispatch = useDispatch();

  // Redux Selectors
  const topicMetadata = useSelector(selectors.getAdminData);
  const adminData = topicMetadata.getViewModel();
  const isLoading = useSelector(selectors.getIsLoading);
  const isLoadingMessages = useSelector(selectors.getIsLoadingMessages);
  const isDetailsPanelOpen = useSelector(selectors.getIsDetailsPanelOpen);
  const messages = useSelector(selectors.getMessages);
  const error = useSelector(selectors.getError);
  const selectedTopic = useSelector(selectors.getSelectedTopic);

  useEffect(() => {
    handleRefresh();
  }, []);

  // Interaction handlers
  const handleRefresh = () => {
    dispatch(actions.handleFetchTopicStarted());
  };
  const handleOpenDetailsPanel = ({ name }) => {
    dispatch(actions.handleFetchMessagesStarted(name));
    dispatch(actions.handleOpenDetailsPanel({ name }));
  };
  const handleCloseDetailsPanel = () => {
    dispatch(actions.handleCloseDetailsPanel());
  };

  // Static fields
  const columns = TopicsTableColumns();
  const tableStaticDefinition = {
    title: 'Topics',
    defaultSortedColumnName: 'name',
    defaultSortOrder: 'asc',
    columns,
    showRefresh: true,
    onClickFunc: handleRefresh,
    isButtonDisabled: isLoading,
    isLoading,
    onRowClick: handleOpenDetailsPanel
  };

  return (
    <>
      <Grid container spacing={0} alignItems="flex-start" justifyContent="center">
        <Grid item xs={isDetailsPanelOpen ? 8 : 12} className={classes.gridItem}>
          <CustomTable {...tableStaticDefinition} data={adminData} dataCount={adminData?.length ? adminData.length : 10} />
        </Grid>
        {isDetailsPanelOpen && (
          <Grid item xs={4} className={classes.gridItem}>
            <DetailsPanel title="Topic Details" closePanel={handleCloseDetailsPanel}>
              <Typography color="textSecondary" className={classes.topicTitle}>
                <strong>All topics metadata</strong>
              </Typography>
              <JsonViewer error={error} json={{ groups: topicMetadata.groups }} />
              <Divider className={classes.divider} />
              <Typography color="textSecondary" className={classes.topicTitle}>
                <strong>TopicName: </strong>
                {selectedTopic?.name}
              </Typography>
              <Typography color="textSecondary" className={classes.topicTitle}>
                <strong>Messages</strong>
              </Typography>
              {isLoadingMessages ? (
                <center>
                  <CircularProgress />
                </center>
              ) : (
                <JsonViewer error={error} json={messages} />
              )}
            </DetailsPanel>
          </Grid>
        )}
      </Grid>
    </>
  );
};

// Setup functions

// HOCs and Export
const styledTopic = withStyles(styles);
const withTopicSaga = injectSaga({ key: 'topicSaga', saga: topicSaga });

export const Topic = compose(styledTopic, withTopicSaga)(TopicPage);
