import MuiTextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

import { TextField } from './textField';

export const FormTextField = ({ value, touched, error, readOnly, helperText, ...rest }) => {
  const hasError = touched && Boolean(error);
  const helper = hasError ? error : helperText;
  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      value={value || ''}
      error={hasError}
      helperText={helper}
      disabled={readOnly}
      readOnly={readOnly}
      {...rest}
    />
  );
};

export const FormAutocompleteField = ({ value, touched, error, name, label, required, readOnly, form, helperText, ...rest }) => {
  const { setFieldValue, setTouched } = form;
  const hasError = touched && Boolean(error);
  const helper = hasError ? error : helperText;
  return (
    <Autocomplete
      {...rest}
      onChange={(_, newValue) => {
        if (newValue && newValue.inputValue) {
          setFieldValue(name, { value: newValue.inputValue, name: newValue.inputValue });
          return;
        }
        setFieldValue(name, newValue);
      }}
      fullWidth
      freeSolo
      value={value || ''}
      selectOnFocus
      clearOnBlur
      disabled={readOnly}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      onBlur={() => setTouched({ [name]: true })}
      renderInput={(rest) => (
        <MuiTextField
          {...rest}
          label={label || ''}
          required={required}
          fullWidth
          margin="normal"
          variant="outlined"
          helperText={helper}
          error={hasError}
        />
      )}
    />
  );
};
