import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Refresh from '@material-ui/icons/Refresh';
import React from 'react';

import { CustomButton } from '../ui/CustomButton';
import { headerStyles } from './tableHeaderStyles';

const customAddTableHeader = (props) => {
  const { classes, onClickFunc, isButtonDisabled, title, showRefresh, hideHeader } = props;

  return (
    <>
      {!hideHeader ? (
        <Grid className={classes.root} container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {title}
          </Typography>
          {showRefresh ? (
            <CustomButton
              label="Refresh"
              variant="outlined"
              startIcon={<Refresh />}
              size="medium"
              disabled={isButtonDisabled}
              onClick={() => onClickFunc()}
            />
          ) : (
            <CustomButton
              label="Create new"
              variant="outlined"
              startIcon={<AddIcon />}
              size="medium"
              disabled={isButtonDisabled}
              onClick={() => onClickFunc()}
            />
          )}
        </Grid>
      ) : (
        <Grid className={classes.root} container direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {title}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export const CustomTableHeader = withStyles(headerStyles)(customAddTableHeader);
