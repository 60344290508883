import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import React from 'react';

const styles = {
  optional: {
    color: '#808080'
  },
  iconClickable: {
    cursor: 'pointer'
  }
};

const _textField = ({
  classes,
  InputLabelProps,
  InputProps,
  multiline,
  optional,
  rows = 4,
  sizeAdjustable = false,
  minWidth = 176,
  minHeight = 76,
  label,
  ...rest
}) => {
  return (
    <MuiTextField
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps
      }}
      InputProps={{
        inputProps: {
          style: {
            resize: sizeAdjustable ? 'both' : 'none',
            minWidth: sizeAdjustable ? minWidth : 'auto',
            minHeight: sizeAdjustable ? minHeight : 'auto',
            ...(InputProps && InputProps.inputProps && InputProps.inputProps.style)
          },
          ...(InputProps && InputProps.inputProps)
        },
        ...InputProps
      }}
      margin="normal"
      multiline={multiline || sizeAdjustable}
      rows={multiline && rows}
      label={
        label !== undefined ? (
          <React.Fragment>
            {label}
            {optional && <span className={classes.optional}>{' (optional)'}</span>}
          </React.Fragment>
        ) : null
      }
      {...rest}
    />
  );
};

export const TextField = withStyles(styles)(_textField);
