import * as COLORS from '../../sharedConstants';

export const styles = {
  divBox: {
    height: '5px'
  },
  default: {
    backgroundImage: 'linear-gradient(to right, #250056, #0076ae)'
  },
  success: {
    backgroundColor: COLORS.SUCCESS_MEDIUM
  },
  error: {
    backgroundColor: COLORS.ERROR_MEDIUM
  },
  warning: {
    backgroundColor: COLORS.ATTENTION_MEDIUM
  },
  successLogo: {
    color: COLORS.SUCCESS_MEDIUM,
    margin: '0.1em 0.3em 0em 0em',
    float: 'left'
  },
  errorLogo: {
    color: COLORS.ERROR_MEDIUM,
    margin: '0.1em 0.3em 0em 0em',
    float: 'left'
  },
  dialogTitle: {
    fontWeight: 'bold',
    float: 'left',
    '&[class*="CustomModalComponent-default"]': {
      backgroundImage: 'linear-gradient(to right, #FFF, #FFF)'
    },
    '&[class*="CustomModalComponent-success"]': {
      color: COLORS.SUCCESS_MEDIUM,
      backgroundColor: 'white'
    },
    '&[class*="CustomModalComponent-error"]': {
      color: COLORS.ERROR_MEDIUM,
      backgroundColor: 'white'
    },
    '&[class*="CustomModalComponent-warning"]': {
      color: COLORS.ATTENTION_MEDIUM,
      backgroundColor: 'white'
    }
  },
  closeButton: { float: 'right', padding: '0px' }
};
