export const styles = {
  root: {
    alignItems: 'center',
    border: 'solid 1px #d9d8da',
    display: 'flex',
    padding: '1px 2px'
  },
  inputText: {
    flex: 1
  },
  iconButton: {
    color: '#d9d8da',
    padding: 3
  },
  spinnerStyle: {
    color: 'gray'
  }
};
