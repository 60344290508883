import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { put, takeLatest } from 'redux-saga/effects';

import * as eventsActions from '../actions';
import * as eventsConstants from '../constants';
import { parseJson } from './parseJson';

function* validateSchema(action) {
  const { field, value } = action.payload;

  if (!field || field !== 'json' || value === '') {
    yield put(eventsActions.handleFormChange(action.payload));
    yield put(eventsActions.handleValidationSuccess());

    return;
  }

  try {
    let ajv = new Ajv({ allErrors: true, verbose: true, async: true, format: 'fast' });
    addFormats(ajv);
    const jsonResult = !value ? '' : parseJson(value);

    ajv.compile(jsonResult);

    yield put(eventsActions.handleFormChange(action.payload));
    yield put(eventsActions.handleValidationSuccess());

    ajv = null;
  } catch (error) {
    let { message } = error;
    const errors = message.split(':');
    const hasDetails = errors.length === 2;

    if (hasDetails) {
      message = [].concat(errors[0], errors[1].split(','));
      message = message.join('\n -');
    }

    message = `This file is not a valid json schema. One or more errors ocurred: \n ${message}`;

    yield put(eventsActions.handleFormChange({ field, value: '' }));
    yield put(eventsActions.handleValidationFailure(message));
  }
}

export function* watchValidateSchema() {
  yield takeLatest(eventsConstants.EVENTS_VALIDATION_STARTED, validateSchema);
}
