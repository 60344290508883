/* eslint-disable no-prototype-builtins */
import { call, put, takeLatest } from 'redux-saga/effects';

import { deleteEventById } from '../../../services/api/event';
import * as eventsActions from '../actions';
import * as eventsConstants from '../constants';

function* deleteEvent(action) {
  const { payload } = action;

  try {
    const response = yield call(deleteEventById, payload);

    if (response?.status === 200) {
      yield put(eventsActions.handleDeleteEventSuccess(payload));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(eventsActions.handleDeleteEventFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchDeleteEvent() {
  yield takeLatest(eventsConstants.EVENTS_DELETE_STARTED, deleteEvent);
}
