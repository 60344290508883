export const styles = (theme) => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '70vh',
    marginTop: '25%'
  }
});
