import { call, put, takeLatest } from 'redux-saga/effects';

import { User } from './../../../models/users';
import { postUser } from '../../../services/api/user';
import * as userActions from '../actions';
import * as userConstants from '../constants';

export function* createUser(action) {
  const { payload } = action;

  try {
    if (!payload) throw new Error('Incorrect User.');

    const request = Object.keys(payload).reduce((acc, current) => {
      if (payload[current] !== null) {
        return { ...acc, [current]: payload[current] };
      }

      return acc;
    }, {});

    const response = yield call(postUser, request);

    if (response?.status === 200) {
      const user = new User(response.data);

      yield put(userActions.handleCreateUserSuccess(user));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(userActions.handleCreateUserFailure(error));
  }
}

export function* watchCreateUser() {
  yield takeLatest(userConstants.USERS_CREATE_STARTED, createUser);
}
