import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomButton } from '../CustomButton';
// We set up the material for styling
import { styles } from './CustomModalStyle';

const CustomModalComponent = (props) => {
  const { openDialog, dialogTitle, children, variant, onCloseDialog, arrayOfButtons, classes, maxWidth } = props;

  return (
    <>
      <Dialog maxWidth={maxWidth} open={openDialog} onClose={onCloseDialog} aria-labelledby="form-dialog-title">
        <Box component="div" className={clsx(classes.divBox, classes[variant])}></Box>
        <DialogTitle id="form-dialog-title" disableTypography>
          {variant === 'success' && <CheckCircleIcon className={classes.successLogo}></CheckCircleIcon>}
          {variant === 'error' && <CancelIcon className={classes.errorLogo}></CancelIcon>}
          <Typography className={clsx(classes.dialogTitle)} variant="h6" align="left">
            {dialogTitle}
          </Typography>
          <IconButton
            size="small"
            key={`close_${dialogTitle}`}
            aria-label="Close"
            color="inherit"
            className={classes.closeButton}
            onClick={onCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {arrayOfButtons.map((currentButton, i) => (
            <CustomButton
              key={`${currentButton.label}_${i}`}
              label={currentButton.label}
              handleOnClickAction={currentButton.action}
              variant={currentButton.variant}
              typeColor={currentButton.color}
              enabled={currentButton.enabled}
              ref={currentButton.ref}
            />
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

CustomModalComponent.defaultProps = {
  variant: 'default',
  maxWidth: 'xs'
};

CustomModalComponent.propTypes = {
  /** @ignore */
  classes: PropTypes.object.isRequired,
  /**
   * @requires Title for the modal
   */
  dialogTitle: PropTypes.string.isRequired,
  /**
   * variant for the modal's style [default, success, error, warning]
   */
  variant: PropTypes.oneOf(['default', 'success', 'error', 'warning', 'info']),
  maxWidth: PropTypes.oneOf(['xs', 'md', 'lg']),

  /**
   * dialogContent represent the modal content to show up, the information must be a function.
   */
  children: PropTypes.any,
  /**
   * Requires an objet, every objetc is passed is going to be a button on the bottom of the Modal, so you have
   * to pass an object with the label, variant (primary, default, outlined) and finally pass the action (function)
   */
  arrayOfButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(['contained', 'outlined']),
      color: PropTypes.oneOf(['default', 'primary', 'secondary']),
      action: PropTypes.func.isRequired,
      enabled: PropTypes.bool
    })
  ),
  /**
   * opened indicates if the modal is visible or not
   */
  openDialog: PropTypes.bool,
  /**
   * onCloseDialog closes the dialog modal
   */
  onCloseDialog: PropTypes.func.isRequired
};

export const CustomModal = withStyles(styles)(CustomModalComponent);
