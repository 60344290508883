import { Divider, Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { PropTypes } from 'prop-types';
import React from 'react';

import { CustomButton } from '../ui/CustomButton';
import { CustomPanelHeader } from './panelHeader';
import { styles } from './styles';

const detailsPanel = ({ children, title, classes, closePanel, arrayOfButtons, hideDefaultCancelButton }) => (
  <Paper key="details-panel-main-wrapper" elevation={3} variant="outlined" className={classes.container}>
    <Grid key="details-panel-main-grid" container spacing={0} alignItems="center" justifyContent="center">
      <Grid key="details-panel-header-grid" item xs={12}>
        <CustomPanelHeader key="details-panel-header" title={title}>
          {arrayOfButtons.map((currentButton) => (
            <CustomButton
              key={`${currentButton.label}_details-header-button`}
              label={currentButton.label}
              handleOnClickAction={currentButton.action}
              variant={currentButton.variant}
              typeColor={currentButton.color}
              enabled={currentButton.enabled}
              ref={currentButton.ref}
              startIcon={currentButton.startIcon}
            />
          ))}
          {!hideDefaultCancelButton && (
            <CustomButton
              key="close_details-header-button"
              label="Close"
              variant="outlined"
              startIcon={<Close />}
              size="medium"
              enabled
              handleOnClickAction={closePanel}
            />
          )}
        </CustomPanelHeader>
        <Divider className={classes.divider} />
      </Grid>
      <Grid key="details-panel-body-grid" item xs={12} className={classes.panel}>
        {React.Children.map(children, (c, i) => (
          <Grid key={`detail_child_${i}`} item xs={12}>
            {c}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Paper>
);

detailsPanel.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  closePanel: PropTypes.func,
  hideDefaultCancelButton: PropTypes.bool,
  arrayOfButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(['contained', 'outlined']),
      color: PropTypes.oneOf(['default', 'primary', 'secondary']),
      action: PropTypes.func.isRequired,
      enabled: PropTypes.bool,
      startIcon: PropTypes.component
    })
  )
};

detailsPanel.defaultProps = {
  arrayOfButtons: [],
  hideDefaultCancelButton: false
};

export const DetailsPanel = withStyles(styles)(detailsPanel);
