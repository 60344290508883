import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { CustomModal } from '../../shared';
import { ConsumerForm } from '../forms/ConsumerForm';
import { styles } from './styles';

const CreateConsumer = ({ openDialog, onCloseDialog, classes, subscriptionList, consumers, onSubmit, enableAddSubscriptions, getSubscriptionsByTopicName, users }) => {
  const [ isSaving, setIsSaving ] = useState(false);
  const [ enableButton, setEnableButton ] = useState(false);

  const onValidationChange = (isFormValid) => {
    setEnableButton(isFormValid);
  };

  return (
      <Grid container spacing={2}>
        <CustomModal
          key="modal-create-consumer"
          className={classes.root}
          dialogTitle="Create Consumer"
          openDialog={openDialog}
          onCloseDialog={onCloseDialog}
          arrayOfButtons={[
            {
              label: 'Cancel',
              color: 'default',
              enabled: true,
              action: () => onCloseDialog()
            },
            {
              label: 'Ok',
              variant: 'contained',
              color: 'primary',
              enabled: enableButton,
              action: () => setIsSaving(true)
            }
          ]}>
          <Grid item xs={12} key="modal-form-wrapper">
            <ConsumerForm
              classes={classes}
              isSaving={isSaving}
              consumers={consumers}
              subscriptionList={subscriptionList}
              onSave={() => setIsSaving(false)}
              getSubscriptionsByTopicName={getSubscriptionsByTopicName}
              enableAddSubscriptions={enableAddSubscriptions}
              onValidationChange={onValidationChange}
              users={users}
              onSubmit={onSubmit}
            />
          </Grid>
        </CustomModal>
      </Grid>
  );
};

export const CreateConsumerModal = withStyles(styles)(CreateConsumer);
