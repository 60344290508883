import { Field, getIn } from 'formik';
import React from 'react';

import { FormAutocompleteField, FormTextField } from './formInput';

export const createFormikInput = (WrappedComponent) => {
  const component = ({ field, form: { touched, errors, ...formRest }, ...rest }) => (
    <WrappedComponent touched={getIn(touched, field.name)} error={getIn(errors, field.name)} form={formRest} {...field} {...rest} />
  );

  const FormikField = (props) => <Field name={props.name} component={component} {...props} />;

  FormikField.displayName = `createFormikInput(${WrappedComponent.displayName || 'WrappedComponent'})`;

  return FormikField;
};

export const FormikInputField = createFormikInput(FormTextField);
export const FormikAutocompleteField = createFormikInput(FormAutocompleteField);
