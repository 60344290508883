/* eslint-disable no-prototype-builtins */
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Event } from '../../../models/events';
import { postEvent } from '../../../services/api/event';
import * as eventsActions from '../actions';
import * as eventsConstants from '../constants';
import { getEvents } from '../selectors';
import { parseJson } from './parseJson';

export const hasDuplicatesFunc = (data, payload) => {
  if (!data || !payload) return { hasError: true, message: 'Incorrect EventType.' };
  const { eventTypeName, productName, active = true } = payload;
  const foundDuplicate = data.find(
    (d) =>
      d.eventTypeName.trim().toLowerCase() === eventTypeName.trim().toLowerCase() &&
      d.productName.trim().toLowerCase() === productName.trim().toLowerCase()
  );

  if (foundDuplicate) return { hasError: true, message: `An Event type with name: ${eventTypeName} and product: ${productName} already exists.` };

  return { hasError: false, message: '' };
};

function* createEvent(action) {
  const { payload } = action;

  try {
    const events = yield select(getEvents);
    const hasDuplicates = yield call(hasDuplicatesFunc, events, payload);

    if (hasDuplicates.hasError) {
      throw new Error(hasDuplicates.message);
    }

    const request = Object.keys(payload).reduce((acc, current) => {
      if (payload[current] !== null) {
        return { ...acc, [current]: payload[current] };
      }

      return acc;
    }, {});

    const response = yield call(postEvent, request);

    if (response?.status === 200) {
      const { json, ...rest } = response.data.payload;
      const jsonResult = parseJson(json);

      const event = new Event({ ...rest, json: jsonResult });

      yield put(eventsActions.handleCreateEventSuccess(event));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(eventsActions.handleCreateEventFailure(error));
  }
}

export function* watchCreateEvent() {
  yield takeLatest(eventsConstants.EVENTS_CREATE_STARTED, createEvent);
}
