import { Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForever from '@material-ui/icons/DeleteForever';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { UsersTableColumns } from '../../models/users';
import { userSaga } from '../../redux/users';
import * as actions from '../../redux/users/actions';
import * as selectors from '../../redux/users/selectors';
import { SCOPES_OPTIONS_LIST, USER_SUCCESS_MESSAGE } from '../../utils/constants';
import { injectSaga } from '../../utils/injectSaga';
import { mapListToOptions, mapStringToList } from '../../utils/utilityFunctions';
import { DetailsPanel } from '../shared';
import { ResultModal } from '../shared/modals/ResultModal';
import { CustomTable } from '../shared/table/customTable';
import { UserForm } from './forms/UserForm';
import { CreateUserModal } from './modals/CreateUserModal';
import { DeleteUserModal } from './modals/DeleteUserModal';
import { styles } from './styles';

const UserPage = (props) => {
  const { classes } = props;

  // Hooks
  const dispatch = useDispatch();

  // Redux Selectors
  const users = useSelector(selectors.getUsers);
  const error = useSelector(selectors.getError);
  const isDetailsPanelOpen = useSelector(selectors.getIsDetailsPanelOpen);
  const isCreateModalOpen = useSelector(selectors.getIsCreateModalOpen);
  const isDeleteModalOpen = useSelector(selectors.getIsDeleteModalOpen);
  const isResultModalOpen = useSelector(selectors.getIsResultModalOpen);
  const selectedUser = useSelector(selectors.getSelectedUser);
  const createdUser = useSelector(selectors.getCreatedUser);
  const activeUser = useSelector(selectors.getActiveUser);
  const isLoading = useSelector(selectors.getIsLoading);

  useEffect(() => {
    handleRefresh();
  }, []);

  // Interaction handlers Redux
  const handleDeleteStart = ({ id = null }) => {
    if (id) dispatch(actions.handleSetActiveUser(id));
    handleCloseDeleteModal(false);
  };

  const handleCloseCreateModal = (payload) => {
    dispatch(actions.handleCloseCreateModal(payload));
  };

  const handleCloseDetailsPanel = () => {
    dispatch(actions.handleCloseDetailsPanel());
  };

  const handleRefresh = () => {
    dispatch(actions.handleFetchUserStarted());
  };

  const handleCreate = (payload) => {
    if (!payload) return;
    dispatch(actions.handleCreateUserStarted(payload));
  };

  const handleDelete = () => {
    dispatch(actions.handleDeleteUserStarted(activeUser));
  };

  const handleOpenDetailsPanel = ({ id = null }) => {
    if (id) dispatch(actions.handleSetActiveUser(id));
    dispatch(actions.handleOpenDetailsPanel());
  };

  const handleCloseResultModal = (payload) => {
    dispatch(actions.handleCloseResultModal(payload));
  };

  const handleCloseDeleteModal = (payload) => {
    dispatch(actions.handleCloseDeleteModal(payload));
  };

  const handleCancelEdit = () => {
    handleCloseCreateModal(true);
  };

  // Static fields
  const columns = UsersTableColumns();
  const actionTableButtons = createActionsButtons(handleDeleteStart);
  const tableStaticDefinition = {
    title: 'Users',
    defaultSortedColumnName: 'clientId',
    defaultSortOrder: 'asc',
    columns,
    onClickFunc: () => handleCloseCreateModal(false),
    isButtonDisabled: isLoading,
    isLoading,
    arrayOfActions: actionTableButtons,
    onRowClick: handleOpenDetailsPanel
  };
  const detailsPanelButtons = createDetailsPanelButtons(handleCancelEdit);

  const resultModalOutput = () => {
    if (!error.hasError && createdUser) {
      return `${USER_SUCCESS_MESSAGE} ClientId: ${createdUser.clientId} ; ClientSecret: ${createdUser.clientSecret}`;
    }

    return error.message;
  };

  return (
    <Fragment>
      {isCreateModalOpen && (
        <CreateUserModal
          key="main-modal-create-user"
          openDialog={isCreateModalOpen}
          users={users}
          scopesList={mapListToOptions(SCOPES_OPTIONS_LIST)}
          onCloseDialog={() => handleCloseCreateModal(true)}
          onSubmit={handleCreate}
        />
      )}
      <DeleteUserModal openDialog={isDeleteModalOpen} onCloseDialog={() => handleCloseDeleteModal(true)} onDelete={handleDelete} />
      <ResultModal
        openDialog={isResultModalOpen}
        onCloseDialog={() => handleCloseResultModal(true)}
        hasError={error.hasError}
        message={resultModalOutput()}
        createdUser={createdUser}
      />
      <Grid key="main-user-table-grid-wrapper" container spacing={0} alignItems="flex-start" justifyContent="center">
        <Grid item xs={isDetailsPanelOpen ? 8 : 12} className={classes.gridItem}>
          <CustomTable {...tableStaticDefinition} data={users.map((s) => s.getViewModel())} dataCount={users?.length ? users.length : 10} />
        </Grid>
        {isDetailsPanelOpen && (
          <Grid key="user-panel-grid" item xs={4} className={classes.gridItem}>
            <DetailsPanel
              key="user-panel"
              hideDefaultCancelButton={true}
              title="User Details"
              closePanel={handleCloseDetailsPanel}
              arrayOfButtons={detailsPanelButtons}>
              <UserForm
                classes={classes}
                users={users}
                scopesList={mapListToOptions(SCOPES_OPTIONS_LIST)}
                initialValues={{
                  clientId: selectedUser.clientId,
                  clientSecret: selectedUser.clientSecret,
                  scopesArray: mapListToOptions(mapStringToList(selectedUser.scopes, ','))
                }}
                readOnly={true}
                isEditMode={true}
              />
            </DetailsPanel>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

// Setup functions
const handleDeleteWrapperStart = (event, row, handleDeleteStart) => {
  event.preventDefault();
  event.stopPropagation();
  handleDeleteStart(row);
};

const createDetailsPanelButtons = (handleCancelEdit) => [
  {
    label: 'Cancel',
    variant: 'outlined',
    enabled: true,
    action: () => handleCancelEdit()
  }
];

const createActionsButtons = (handleDeleteStart) => [
  {
    icon: <DeleteForever />,
    title: 'Delete User',
    ariaLabel: 'delete',
    action: (event, row) => handleDeleteWrapperStart(event, row, handleDeleteStart)
  }
];

// HOCs and Export
const styledUser = withStyles(styles);
const withUserSaga = injectSaga({ key: 'userSaga', saga: userSaga });

export const User = compose(styledUser, withUserSaga)(UserPage);
