import * as topicsConstants from './constants';

// Started
export const handleFetchTopicStarted = (payload) => ({
  type: topicsConstants.TOPICS_FETCH_STARTED,
  payload
});

export const handleFetchMessagesStarted = (payload) => ({
  type: topicsConstants.MESSAGES_FETCH_STARTED,
  payload
});

// Success
export const handleFetchTopicSuccess = (payload) => ({
  type: topicsConstants.TOPICS_FETCH_SUCCESS,
  payload
});
export const handleFetchMessagesSuccess = (payload) => ({
  type: topicsConstants.MESSAGES_FETCH_SUCCESS,
  payload
});

// Failure
export const handleFetchTopicFailure = (payload) => ({
  type: topicsConstants.TOPICS_FETCH_FAILURE,
  payload
});
export const handleFetchMessagesFailure = (payload) => ({
  type: topicsConstants.MESSAGES_FETCH_FAILURE,
  payload
});
// Reducers

export const handleOpenDetailsPanel = (payload) => ({
  type: topicsConstants.TOPICS_DETAILS_OPEN_REDUCER,
  payload
});

export const handleCloseDetailsPanel = (payload) => ({
  type: topicsConstants.TOPICS_DETAILS_CLOSE_REDUCER,
  payload
});
