import { call, put, takeLatest } from 'redux-saga/effects';

import { Event } from '../../../models/events';
import { getAllEvents } from '../../../services/api/event';
import * as eventsActions from '../actions';
import * as eventsConstants from '../constants';
import { parseJson } from './parseJson';

function* fetchEvent() {
  try {
    let data = [];

    const events = yield call(getAllEvents);

    if (events?.status === 200) {
      data = events.data.payload.map((e) => {
        const { json, ...rest } = e;
        const jsonResult = parseJson(json);

        return new Event({ ...rest, json: jsonResult });
      });
    }

    yield put(eventsActions.handleFetchEventSuccess(data));
  } catch (error) {
    yield put(eventsActions.handleFetchEventFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchFetchEvent() {
  yield takeLatest(eventsConstants.EVENTS_FETCH_STARTED, fetchEvent);
}
