export const getConsumers = (state) => state.consumers.data;
export const getError = (state) => state.consumers.error;
export const getIsCreateModalOpen = (state) => state.consumers.isCreateModalOpen;
export const getIsDetailsPanelOpen = (state) => state.consumers.isDetailsPanelOpen;
export const getIsDeleteModalOpen = (state) => state.consumers.isDeleteModalOpen;
export const getIsResetModalOpen = (state) => state.consumers.isResetModalOpen;
export const getIsLoading = (state) => state.consumers.isLoading;
export const getActiveUserName = (state) => state.consumers.activeUserName;
export const getSelectedConsumer = (state) => state.consumers.data.find((s) => s.userName === getActiveUserName(state));
export const getSelectedUsers = (state) => state.consumers.data.map((s) => s.userName);
export const getSubscribedEventsByConsumer = (state) => state.consumers.subscribedEvents;
export const getIsResultModalOpen = (state) => state.consumers.isResultModalOpen;
export const getResultModalMode = (state) => state.consumers.resultModalMode;