export const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    marginTop: '10px'
  },
  row: {
    margin: theme.spacing(1),
    minWidth: '20vw'
  },
  formControl: {
    margin: theme.spacing(2)
  },
  select: {
    marginLeft: '40px !important'
  }
});
