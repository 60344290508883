import { createUserManager } from 'redux-oidc';

import { storage } from '../../utils/storage';

let _currentUserManager;

const clientConfiguration = {
  client_id: window.REACT_APP_SSO_CLIENT_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback/`,
  response_type: window.REACT_APP_SSO_RESPONSE_TYPE,
  scope: window.REACT_APP_SSO_SCOPE,
  authority: `${window.REACT_APP_SSO_DOMAIN}/auth/realms/${window.REACT_APP_SSO_CLIENT_REALM}`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/index.html?resource=renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false
};

function setRealm(realm) {
  if (storage.isSupported()) {
    const issuer = storage.get(AuthRealmKey);

    // if no issuer stored or it is stored but isn't the realm passed in
    if ((!issuer && realm) || (realm && issuer && issuer !== realm)) {
      // if the realm isn't one we support exit
      if (realm !== Realm) {
        return;
      }

      storage.set(AuthRealmKey, realm);
    }
  }
}

export const initializeUserManager = (realm) => {
  if (realm) setRealm(realm);

  _currentUserManager = createUserManager(clientConfiguration);
  return _currentUserManager;
};

export const Realm = 'drugdev';
export const AuthRealmKey = 'auth-realm';
export const currentUserManager = _currentUserManager ? _currentUserManager : initializeUserManager();
