import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import { AdminData } from '../../models/topics';
import * as topicsConstants from './constants';

export const initialState = {
  data: new AdminData({}),
  isLoading: false,
  error: { hasError: false, message: '' },
  isDetailsPanelOpen: false,
  selectedTopic: {},
  messages: [],
  isLoadingMessages: false
};

const failureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.isLoadingMessages = false;
    draft.error = {
      hasError: true,
      message
    };
  });

const fetchSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    const refreshedItem =
      state.isDetailsPanelOpen ? action.payload?.topics?.find((x) => x?.name === state.selectedTopic?.name) : state.selectedTopic;

    draft.isLoading = false;
    draft.error = initialState.error;
    draft.data = action.payload;
    draft.selectedTopic = { name : refreshedItem?.name };
  });

const fetchMessagesSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoadingMessages = false;
    draft.error = initialState.error;
    draft.messages = action.payload;
  });

const actionStartedHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = true;
  });

const actionMessagesStartedHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoadingMessages = true;
  });

const openSchemaPanelHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = true;
    draft.selectedTopic = action.payload;
  });

const closeSchemaPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = false;
    draft.selectedTopic = {};
  });

export const topicReducer = handleActions(
  {
    [topicsConstants.TOPICS_DETAILS_CLOSE_REDUCER]: closeSchemaPanelHandler,
    [topicsConstants.TOPICS_DETAILS_OPEN_REDUCER]: openSchemaPanelHandler,
    [topicsConstants.TOPICS_FETCH_FAILURE]: failureHandler,
    [topicsConstants.TOPICS_FETCH_STARTED]: actionStartedHandler,
    [topicsConstants.TOPICS_FETCH_SUCCESS]: fetchSuccessHandler,
    [topicsConstants.MESSAGES_FETCH_STARTED]: actionMessagesStartedHandler,
    [topicsConstants.MESSAGES_FETCH_FAILURE]: failureHandler,
    [topicsConstants.MESSAGES_FETCH_SUCCESS]: fetchMessagesSuccessHandler
  },
  initialState
);
