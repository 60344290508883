import { call, put, takeLatest } from 'redux-saga/effects';

import { deleteConsumerByUserName } from '../../../services/api/consumer';
import * as consumerActions from '../actions';
import * as consumerConstants from '../constants';

function* deleteConsumer(action) {
  const { payload } = action;

  try {
    const response = yield call(deleteConsumerByUserName, payload);

    if (response?.status === 200) {
      yield put(consumerActions.handleDeleteConsumerSuccess(payload));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(consumerActions.handleDeleteConsumerFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchDeleteConsumer() {
  yield takeLatest(consumerConstants.CONSUMERS_DELETE_STARTED, deleteConsumer);
}
