import * as consumerGroupsConstants from './constants';

// Started
export const handleFetchConsumerGroupStarted = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_FETCH_STARTED,
  payload
});

export const handleFetchConsumerGroupsByConsumerIdStarted = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_STARTED,
  payload
});

export const handleResetConsumerGroupStarted = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_RESET_STARTED,
  payload
});

// Success
export const handleFetchConsumerGroupSuccess = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_FETCH_SUCCESS,
  payload
});

export const handleFetchConsumerGroupsByConsumerIdSuccess = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_SUCCESS,
  payload
});

export const handleResetConsumerGroupSuccess = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_RESET_SUCCESS,
  payload
});

// Failure
export const handleFetchConsumerGroupFailure = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_FETCH_FAILURE,
  payload
});

export const handleFetchConsumerGroupsByConsumerIdFailure = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_FETCH_BY_CONSUMER_ID_FAILURE,
  payload
});

export const handleResetConsumerGroupFailure = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_RESET_FAILURE,
  payload
});

export const handleCloseResultModal = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_CLOSE_RESULT_REDUCER,
  payload
});

export const handleCloseResetModal = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_CLOSE_RESET_REDUCER,
  payload
});

export const handleSetActiveConsumer = (payload) => ({
  type: consumerGroupsConstants.CONSUMERGROUPS_SET_ACTIVE_REDUCER,
  payload
});
