import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomActionCell } from './customActionCell';
import { CustomCell } from './customCell';
import { CustomTableHeader } from './customTableHeader';
import { styles } from './customTableStyles';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          return !column.isAction ? (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === column.id ? order : false}>
              <TableSortLabel active={orderBy === column.id} direction={orderBy === column.id ? order : 'asc'} onClick={createSortHandler(column.id)}>
                {column.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} padding={column.disablePadding ? 'none' : 'normal'}>
              {column.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

const CustomTableBase = (props) => {
  const {
    data,
    columns,
    dataCount,
    onClickFunc,
    isButtonDisabled,
    title,
    defaultSortedColumnName,
    defaultSortOrder,
    onRowClick,
    showRefresh,
    arrayOfActions,
    isLoading,
    hideHeader,
    classes
  } = props;
  const [order, setOrder] = React.useState(defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState(defaultSortedColumnName);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value !== -1) {
      setRowsPerPage(parseInt(event.target.value, 10));
    } else {
      setRowsPerPage(parseInt(dataCount, 10));
    }
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <CustomTableHeader
        onClickFunc={onClickFunc}
        isButtonDisabled={isButtonDisabled}
        showRefresh={showRefresh}
        title={title}
        hideHeader={hideHeader}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            columns={columns}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={dataCount}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow onClick={() => onRowClick(row)} hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return !column.isAction ? (
                        <CustomCell column={column} value={value} key={column.id} />
                      ) : (
                        <CustomActionCell column={column} arrayOfActions={arrayOfActions} row={row} key={column.id} />
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, { value: -1, label: 'All' }]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export const CustomTable = withStyles(styles)(CustomTableBase);
