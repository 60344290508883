export const theme = {
  base00: 'white',
  base01: '#fff',
  base02: '#fff',
  base03: '#000000',
  base04: '#0B0000',
  base05: '#000000',
  base06: '#000000',
  base07: '#000000',
  base08: '#0000ff',
  base09: '#000000',
  base0A: '#000000',
  base0B: '#000000',
  base0C: '#000000',
  base0D: '#000000',
  base0E: '#000000',
  base0F: '#000000'
};
