import React from 'react';

function sendUnexpectedError() {
  // TODO: Log error
}

export const withErrorBoundary = (Component) =>
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        hasError: false
      };
    }

    componentDidCatch(err) {
      sendUnexpectedError(err);
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    render() {
      return !this.state.hasError && <Component />;
    }
  };
