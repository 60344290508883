import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Consumer } from './../../../models/consumers';
import { putConsumerByUserName } from '../../../services/api/consumer';
import * as consumerActions from '../actions';
import * as consumerConstants from '../constants';

function* updateConsumer(action) {
  const { payload } = action;

  try {
    if (!payload) throw new Error('Incorrect Consumer.');

    const response = yield call(putConsumerByUserName, payload.userName, payload);

    if (response?.status === 200) {
      yield put(consumerActions.handleUpdateConsumerSuccess(new Consumer(payload)));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(consumerActions.handleUpdateConsumerFailure(error));
  }
}

export function* watchUpdateConsumer() {
  yield takeLatest(consumerConstants.CONSUMERS_UPDATE_STARTED, updateConsumer);
}
