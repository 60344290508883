import { call, put, select, takeLatest } from 'redux-saga/effects';

import { AdminData } from '../../../models/topics';
import { getAdminData, getMessagesByTopic } from '../../../services/api/topic';
import * as topicsActions from '../actions';
import * as topicsConstants from '../constants';
import { getCustomers } from '../selectors';

function* fetchAdminData() {
  try {
    let data = [];
    const response = yield call(getAdminData);

    if (response?.status === 200) {
      data = new AdminData(response.data.payload);
    }

    yield put(topicsActions.handleFetchTopicSuccess(data));
  } catch (error) {
    yield put(topicsActions.handleFetchTopicFailure(error));
    // TODO: navigate to error page
  }
}

function* fetchMessages(action) {
  try {
    const { payload } = action;
    let data = [];
    let selectedTopicName = payload;
    const requestParams = {
      topicName: selectedTopicName
    };

    const response = yield call(getMessagesByTopic, requestParams);

    if (response?.status === 200) {
      data = response.data.payload;
    }

    yield put(topicsActions.handleFetchMessagesSuccess(data));
  } catch (error) {
    yield put(topicsActions.handleFetchMessagesFailure(error));
  }
}

export function* watchFetchTopic() {
  yield takeLatest(topicsConstants.TOPICS_FETCH_STARTED, fetchAdminData);
  yield takeLatest(topicsConstants.MESSAGES_FETCH_STARTED, fetchMessages);
}
