import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import * as commonConstants from './constants';

export const initialState = {
  customers: [],
  products: [],
  sponsors: [],
  studies: [],
  clientIds: [],
  selectedCustomer: null,
  isSponsorCheckboxEnable: false,
  isStudyCheckboxEnable: false,
  isLoading: false,
  error: { hasError: false, message: '' }
};

const fetchSuccessHandler = (state, action) => {
  const { field, value } = action.payload;

  return produce(state, (draft) => {
    draft.isLoading = initialState.isLoading;
    draft[field] = value;
  });
};

const fetchFailureHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = {
      hasError: true,
      message: action.payload.error
    };
  });

const setSelectedCustomerHandler = (state, action) => {
  const { payload } = action;

  return produce(state, (draft) => {
    draft.selectedCustomer = payload;
  });
};

export const commonReducer = handleActions(
  {
    [commonConstants.COMMON_PRODUCTS_FETCH_FAILURE]: fetchFailureHandler,
    [commonConstants.COMMON_PRODUCTS_FETCH_SUCCESS]: fetchSuccessHandler,
    [commonConstants.COMMON_CUSTOMERS_FETCH_FAILURE]: fetchFailureHandler,
    [commonConstants.COMMON_CUSTOMERS_FETCH_SUCCESS]: fetchSuccessHandler,
    [commonConstants.COMMON_SELECTED_CUSTOMER_SET_REDUCER]: setSelectedCustomerHandler,
    [commonConstants.COMMON_STUDIES_FETCH_FAILURE]: fetchFailureHandler,
    [commonConstants.COMMON_STUDIES_FETCH_SUCCESS]: fetchSuccessHandler,
    [commonConstants.COMMON_SPONSORS_FETCH_FAILURE]: fetchFailureHandler,
    [commonConstants.COMMON_SPONSORS_FETCH_SUCCESS]: fetchSuccessHandler,
    [commonConstants.COMMON_CLIENTIDS_FETCH_SUCCESS]: fetchSuccessHandler,
    [commonConstants.COMMON_CLIENTIDS_FETCH_FAILURE]: fetchFailureHandler,
  },
  initialState
);
