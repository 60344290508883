import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import { Consumer as ConsumerModel } from '../../models/consumers';
import * as consumersConstants from './constants';

export const initialState = {
  data: [],
  isLoading: false,
  error: { hasError: false, message: '' },
  isCreateModalOpen: false,
  isDeleteModalOpen: false,
  activeUserName: null,
  isDetailsPanelOpen: false,
  isResultModalOpen: false,
  resultModalMode: '',
  subscribedEvents: []
};

const actionStartedHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = true;
  });

const fetchSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.data = action.payload;
  });

const createSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = initialState.isLoading;
    draft.error = initialState.error;
    draft.data = [action.payload].concat(state.data);
    draft.isCreateModalOpen = initialState.isCreateModalOpen;
    draft.isResultModalOpen = true;
  });

const updateSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    const { topicName } = action.payload;
    const consumerIndexes = state.data.reduce((c, v, i) => (v.topicName === topicName ? c.concat(i) : c), []);
    const dataClone = [...state.data];

    consumerIndexes.forEach((element) => {
      let consumerClone = dataClone[element];
      consumerClone.subscriptionIds = action.payload.subscriptionIds;
      dataClone.splice(element, 1, consumerClone);
    });

    draft.isLoading = false;
    draft.error = initialState.error;
    draft.resultModalMode = 'update';
    draft.isResultModalOpen = true;
    draft.data = dataClone;
  });

const deleteSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    const filteredData = state.data.filter((e) => e.userName !== action.payload);
    const hasPanelState = state.isDetailsPanelOpen && filteredData.length > 0;

    draft.isLoading = false;
    draft.error = initialState.error;
    draft.activeUserName = hasPanelState ? filteredData[0].userName : initialState.activeUserName;
    draft.isDetailsPanelOpen = hasPanelState;
    draft.data = filteredData;
  });

const failureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.isResultModalOpen = true;
    draft.isDetailsPanelOpen = initialState.isDetailsPanelOpen;
    draft.isCreateModalOpen = initialState.isCreateModalOpen;
    draft.isDeleteModalOpen = initialState.isDeleteModalOpen;
    draft.error = {
      hasError: true,
      message
    };
  });

const fetchSubscribedEventsSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.subscribedEvents = action.payload;
  });

const openDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = true;
  });

const closeDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = false;
  });

const closeCreateModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.activeUserName = initialState.activeUserName;
    draft.isDetailsPanelOpen = initialState.isDetailsPanelOpen;
    draft.isCreateModalOpen = !action.payload;
    draft.isResultModalOpen = false;
  });

const closeDeleteModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isDeleteModalOpen = !action.payload;
  });

const closeResultModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isResultModalOpen = !action.payload;
    draft.resultModalMode = initialState.resultModalMode;
  });

const setActiveHandler = (state, action) =>
  produce(state, (draft) => {
    draft.error = initialState.error;
    draft.activeUserName = action.payload;
  });

export const consumerReducer = handleActions(
  {
    [consumersConstants.CONSUMERS_CLOSE_DELETE_REDUCER]: closeDeleteModalHandler,
    [consumersConstants.CONSUMERS_CLOSE_EDIT_REDUCER]: closeCreateModalHandler,
    [consumersConstants.CONSUMERS_CLOSE_RESULT_REDUCER]: closeResultModalHandler,
    [consumersConstants.CONSUMERS_DETAILS_OPEN_REDUCER]: openDetailsPanelHandler,
    [consumersConstants.CONSUMERS_DETAILS_CLOSE_REDUCER]: closeDetailsPanelHandler,
    [consumersConstants.CONSUMERS_SET_ACTIVE_REDUCER]: setActiveHandler,
    [consumersConstants.CONSUMERS_FETCH_STARTED]: actionStartedHandler,
    [consumersConstants.CONSUMERS_FETCH_SUCCESS]: fetchSuccessHandler,
    [consumersConstants.CONSUMERS_FETCH_FAILURE]: failureHandler,
    [consumersConstants.CONSUMERS_CREATE_STARTED]: actionStartedHandler,
    [consumersConstants.CONSUMERS_CREATE_SUCCESS]: createSuccessHandler,
    [consumersConstants.CONSUMERS_CREATE_FAILURE]: failureHandler,
    [consumersConstants.CONSUMERS_UPDATE_STARTED]: actionStartedHandler,
    [consumersConstants.CONSUMERS_UPDATE_SUCCESS]: updateSuccessHandler,
    [consumersConstants.CONSUMERS_UPDATE_FAILURE]: failureHandler,
    [consumersConstants.CONSUMERS_DELETE_STARTED]: actionStartedHandler,
    [consumersConstants.CONSUMERS_DELETE_SUCCESS]: deleteSuccessHandler,
    [consumersConstants.CONSUMERS_DELETE_FAILURE]: failureHandler,
    [consumersConstants.CONSUMERS_SUBSCRIBED_EVENTS_FETCH_SUCCESS]: fetchSubscribedEventsSuccessHandler,
    [consumersConstants.CONSUMERS_SUBSCRIBED_EVENTS_FETCH_FAILURE]: failureHandler
  },
  initialState
);
