export const customTabStyles = {
  root: {
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '1.0rem',
    fontFamily: ['Proxima Nova', 'Nunito Sans', 'sans-serif'].join(','),
    letterSpacing: '0em',
    lineHeight: '0',
    minWidth: '80px',
    paddingLeft: '1em',
    marginRight: '1em',
    justifyContent: 'flex-end',
    minHeight: '40px',
    '&$selected': {
      fontWeight: '700'
    }
  },
  selected: {}
};

export const customTabsStyles = {
  root: {
    borderBottom: '3px solid #d9d9d9',
    minHeight: '0px',
    '& .MuiTabs-fixed': {
      marginLeft: '30px',
      position: 'inherit'
    }
  },
  indicator: {
    backgroundColor: '#0768fd',
    height: '5px',
    marginLeft: 'inherit'
  }
};

export const navTabsStyles = {
  root: {
    flexGrow: 1,
    left: 'auto',
    right: 0,
    position: 'relative',
    width: '100%',
    marginTop: '25px',
    marginBottom: '25px'
  }
};
