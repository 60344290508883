import { all } from 'redux-saga/effects';

import { watchCreateEvent } from './create';
import { watchDeleteEvent } from './delete';
import { watchDownloadSchema } from './download';
import { watchFetchEvent } from './fetch';
import { watchUpdateEvent } from './update';
import { watchValidateSchema } from './validate';

export function* eventSaga() {
  yield all([watchFetchEvent(), watchDeleteEvent(), watchCreateEvent(), watchDownloadSchema(), watchValidateSchema(), watchUpdateEvent()]);
}
