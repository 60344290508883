import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteForever from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { Event as EventModel, EventsTableColumns, hasError as hasModelError } from '../../models/events';
import * as commonActions from '../../redux/common/actions';
import * as actions from '../../redux/events/actions';
import { eventSaga } from '../../redux/events/sagas';
import { hasDuplicatesFunc } from '../../redux/events/sagas/create';
import * as selectors from '../../redux/events/selectors';
import { EVENT_SUCCESS_MESSAGE, EVENT_UPDATED_SUCCESS_MESSAGE } from '../../utils/constants';
import { injectSaga } from '../../utils/injectSaga';
import { DetailsPanel, JsonViewer } from '../shared';
import { FormValidatorContext } from '../shared/forms/FormValidatorContext';
import { ResultModal } from '../shared/modals';
import { CustomTable } from '../shared/table/customTable';
import { EventForm } from './forms/EventForm';
import { CreateEventModal } from './modals/CreateEventModal';
import { DeleteEventModal } from './modals/DeleteEventModal';
import { styles } from './styles';

const EventPage = (props) => {
  const InitialState = {
    isNotValid: false,
    hasFormChanged: false,
    hasErrorFunc: hasModelError,
    formValues: { eventTypeName: '', productName: '' },
    duplicatedFormat: { hasError: false, message: '' }
  };

  const { classes } = props;
  // Hooks
  const dispatch = useDispatch();
  const [formValidatorState, setFormValidatorState] = useState(InitialState);

  // Redux Selectors
  const events = useSelector(selectors.getEvents);
  const isCreateModalOpen = useSelector(selectors.getIsCreateModalOpen);
  const isDeleteModalOpen = useSelector(selectors.getIsDeleteModalOpen);
  const isLoading = useSelector(selectors.getIsLoading);
  const activeEventId = useSelector(selectors.getActiveEventId);
  const selectedEvent = useSelector(selectors.getSelectedEvent);
  const isDetailsPanelOpen = useSelector(selectors.getIsDetailsPanelOpen);
  const changes = useSelector(selectors.getChanges);
  const error = useSelector(selectors.getError);
  const products = useSelector(selectors.getProducts);
  const isResultModalOpen = useSelector(selectors.getIsResultModalOpen);
  const isEditableMode = useSelector(selectors.getIsEditableMode);
  const isMajorupdate = useSelector(selectors.getIsMajorUpdate);
  const resultModalMode = useSelector(selectors.getResultModalMode);

  useEffect(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    if (events && events.length) {
      setFormValidatorState({ ...formValidatorState, duplicatedFormat: hasDuplicatesFunc(events, formValidatorState.formValues) });
    }
  }, [formValidatorState.formValues, events]);

  // Interaction handlers Redux
  const handleDeleteStart = ({ id = null }) => {
    if (id) dispatch(actions.handleSetActiveEvent(id));
    handleCloseDeleteModal(false);
  };

  const handleCloseCreateModal = (payload) => {
    setFormValidatorState(InitialState);
    dispatch(actions.handleCloseCreateModal(payload));
  };

  const handleCloseDeleteModal = (payload) => {
    dispatch(actions.handleCloseDeleteModal(payload));
  };

  const handleCloseResultModal = (payload) => {
    batch(() => {
      dispatch(actions.handleCloseResultModal(payload));
      dispatch(commonActions.handleFetchProductStarted());
    });
  };

  const handleCreate = (payload) => {
    if (!payload) return;

    dispatch(actions.handleCreateEventStarted(payload));
  };

  const handleDelete = () => {
    dispatch(actions.handleDeleteEventStarted(activeEventId));
  };

  const handleRefresh = () => {
    dispatch(actions.handleFetchEventStarted());
  };

  const handleOpenDetailsPanel = ({ id = null }) => {
    if (id) dispatch(actions.handleSetActiveEvent(id));
    dispatch(actions.handleOpenDetailsPanel());
  };

  const handleCloseDetailsPanel = () => {
    dispatch(actions.handleCloseDetailsPanel());
  };

  const handleChange = (field, value) => {
    const cleanValue = value === undefined || value === null ? '' : value;
    const payload = { field, value: cleanValue };

    dispatch(actions.handleStartValidation(payload));

    setFormValidatorState({
      ...formValidatorState,
      formValues: { ...formValidatorState.formValues, [field]: payload.value },
      hasFormChanged: true
    });
  };

  const handleDownload = () => {
    dispatch(actions.handleDownloadSchemaStarted());
  };

  const handleSetEventEditable = () => {
    dispatch(actions.handleSetEventEditable());
  };

  const handleUpdate = (payload) => {
    dispatch(actions.handleUpdateEventStarted(payload));
  };

  const handleCancelEdit = () => {
    dispatch(actions.handleCancelEditStarted());
    setFormValidatorState(InitialState);
  };

  // Static fields
  const eventUnderEdit = new EventModel({ ...selectedEvent, ...changes });
  const columns = EventsTableColumns();
  const actionTableButtons = createActionsButtons(handleDeleteStart);
  const tableStaticDefinition = {
    title: 'Events',
    defaultSortedColumnName: 'name',
    defaultSortOrder: 'asc',
    columns,
    onClickFunc: () => handleCloseCreateModal(false),
    isButtonDisabled: isLoading,
    isLoading,
    arrayOfActions: actionTableButtons,
    onRowClick: handleOpenDetailsPanel
  };
  const detailsPanelButtons = createDetailsPanelButtons(
    handleDownload,
    handleSetEventEditable,
    isEditableMode,
    handleUpdate,
    handleCancelEdit,
    formValidatorState.hasFormChanged,
    formValidatorState.hasErrorFunc,
    eventUnderEdit,
    isMajorupdate
  );

  const resultModalOutput = () => {
    if (!error.hasError) {
      if (resultModalMode !== 'update') return EVENT_SUCCESS_MESSAGE;

      return EVENT_UPDATED_SUCCESS_MESSAGE;
    }

    return error.message;
  };

  return (
    <FormValidatorContext.Provider value={[formValidatorState, setFormValidatorState]}>
      <CreateEventModal
        key="main-modal-create-event"
        handleChange={handleChange}
        event={eventUnderEdit}
        openDialog={isCreateModalOpen}
        onCloseDialog={() => handleCloseCreateModal(true)}
        onCreate={handleCreate}
        hasFormChanged={formValidatorState.hasFormChanged}
        hasErrorFunc={formValidatorState.hasErrorFunc}
        duplicatedFormat={formValidatorState.duplicatedFormat}
        products={products}
      />
      <DeleteEventModal openDialog={isDeleteModalOpen} onCloseDialog={() => handleCloseDeleteModal(true)} onDelete={handleDelete} />
      <ResultModal
        maxWidth="md"
        key="result-modal-event"
        openDialog={isResultModalOpen}
        onCloseDialog={() => handleCloseResultModal(true)}
        hasError={error.hasError}
        message={resultModalOutput()}
      />
      <Grid key="main-event-table-grid-wrapper" container spacing={0} alignItems="flex-start" justifyContent="center">
        <Grid item xs={isDetailsPanelOpen ? 8 : 12} className={classes.gridItem}>
          <CustomTable {...tableStaticDefinition} data={events.map((s) => s.getViewModel())} dataCount={events?.length ? events.length : 10} />
        </Grid>
        {isDetailsPanelOpen && (
          <Grid item xs={4} className={classes.gridItem}>
            <DetailsPanel
              key="main-event-details-panel"
              hideDefaultCancelButton={isEditableMode}
              title="Event Details"
              closePanel={handleCloseDetailsPanel}
              arrayOfButtons={detailsPanelButtons}>
              <EventForm
                key="event-details-panel-form"
                readOnly={{ textField: true, comboBox: true, revision: false, checkbox: !isEditableMode, uploadFile: !isEditableMode }}
                event={eventUnderEdit}
                handleChange={handleChange}
                classes={classes}
                products={products}
                error={error}
                isMajorUpdate={isMajorupdate}
              />
              {!isEditableMode && (
                <div>
                  <Typography color="textSecondary" style={{ padding: '20px' }}>
                    Schema:
                  </Typography>
                  <JsonViewer json={selectedEvent?.json ?? { error: 'Json file not found' }} error={error} />
                </div>
              )}
            </DetailsPanel>
          </Grid>
        )}
      </Grid>
    </FormValidatorContext.Provider>
  );
};

// Setup functions
const handleDeleteWrapperStart = (event, row, handleDeleteStart) => {
  event.preventDefault();
  event.stopPropagation();
  handleDeleteStart(row);
};

const createDetailsPanelButtons = (
  handleDownload,
  handleSetEventEditable,
  isEditableMode,
  handleUpdate,
  handleCancelEdit,
  hasFormChanged,
  hasErrorFunc,
  eventUnderEdit,
  isMajorUpdate
) =>
  isEditableMode
    ? [
        {
          label: 'Cancel',
          variant: 'outlined',
          enabled: true,
          action: () => handleCancelEdit()
        },
        {
          label: 'Save',
          variant: 'contained',
          color: 'primary',
          enabled: isEditableMode && hasFormChanged && !hasErrorFunc(eventUnderEdit),
          action: () => handleUpdate({ ...eventUnderEdit, isMajorUpdate })
        }
      ]
    : [
        {
          label: 'Edit',
          variant: 'outlined',
          startIcon: <EditIcon />,
          enabled: eventUnderEdit && eventUnderEdit.revision === 'LATEST' ? true : false,
          size: 'small',
          action: () => {
            handleSetEventEditable();
          }
        },
        {
          label: 'Download',
          variant: 'outlined',
          startIcon: <CloudDownloadIcon />,
          enabled: true,
          size: 'small',
          action: () => {
            handleDownload();
          }
        }
      ];

const createActionsButtons = (handleDeleteStart) => [
  {
    icon: <DeleteForever />,
    title: 'Delete Event',
    ariaLabel: 'delete',
    action: (event, row) => handleDeleteWrapperStart(event, row, handleDeleteStart)
  }
];

// HOCs and Export
const styledEvent = withStyles(styles);
const withEventSaga = injectSaga({ key: 'eventSaga', saga: eventSaga });

export const EventType = compose(styledEvent, withEventSaga)(EventPage);
