import { Container, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { CustomModal } from '../../shared';
import { styles } from './styles';

const DeleteConsumer = (props) => {
  const { openDialog, onCloseDialog, onDelete, classes } = props;

  return (
    <Grid container spacing={2}>
      <CustomModal
        className={classes.root}
        dialogTitle="Delete Consumer"
        openDialog={openDialog}
        onCloseDialog={onCloseDialog}
        arrayOfButtons={[
          {
            label: 'Cancel',
            color: 'default',
            enabled: true,
            action: () => {
              onCloseDialog();
            }
          },
          {
            label: 'Yes',
            variant: 'contained',
            color: 'primary',
            enabled: true,
            action: () => {
              onDelete();
              onCloseDialog();
            }
          }
        ]}>
        <Grid key="topicName" item xs={12} className={classes.row}>
          <Container>
            <Typography paragraph>Are you sure?</Typography>
          </Container>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export const DeleteConsumerModal = withStyles(styles)(DeleteConsumer);