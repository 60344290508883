/* eslint-disable no-prototype-builtins */
import axios, { AxiosRequestConfig } from 'axios';

import { CustomError, NetworkError, RequestError, SecurityError, ValidationError } from '../../models/errorModels';
import { HttpClientConfig } from './httpClientConfig';

const httpClient = {};
let initialized = false;

const axiosClient = axios.create({
  adapter: cacheResponseAdapter(axios.defaults.adapter)
});

function validateConfig(config) {
  if (config == null || Object.keys(config).length === 0) {
    return 'No config provided';
  }

  if (!config.method) {
    return 'No method provided';
  }

  if (!config.url) {
    return 'No url provided';
  }

  if (config.method.toUpperCase() === 'POST' || config.method.toUpperCase() === 'PUT') {
    if (config.data == null) {
      return `No data provided for a ${config.method} request`;
    }
  }

  return null;
}

const errorsAppCanHandle = [401, 403, 500];

function errorWithResponse(error) {
  const errorResult = { ...error };

  if (error.response.status === 400 && isValidationResponse(error.response)) {
    throw new ValidationError(errorResult);
  }

  if (errorsAppCanHandle.includes(errorResult.response.status) && standardErrorResponseHandler) {
    errorResult.response.referenceId = getServerErrorId(error.response);
    standardErrorResponseHandler(errorResult.response);

    // for 401 & 403 we throw a security error and exit here
    if (errorResult.response.status !== 500) {
      throw new SecurityError(errorResult);
    }
  }

  // default action is to throw a RequestError to allow the
  // client application to do what it believes is best
  throw new RequestError(errorResult);
}

function makeRequest(config) {
  let configuration = { ...config };

  if (!initialized) {
    throw new Error('httpClient must be initialized before it can be used please call initialize and pass a httpClientConfig object');
  }

  const invalidReason = validateConfig(configuration);

  if (invalidReason) {
    return Promise.reject(new Error(invalidReason));
  }

  configuration = { ...configuration, ...initializeHeaders(configuration) };

  if (configuration.isTokenRequired && !configuration.headers?.Authorization) {
    return Promise.reject(new SecurityError('no token found'));
  }

  return axiosClient(configuration)
    .then((response) => preProcessResponse(response))
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { cancelled: true, data: {} };
      }

      // The request was made and the server responded with a
      // status code that falls out of the range of 2xx

      if (error.response) {
        errorWithResponse(error);
      } else if (error?.request) {
        throw new NetworkError(error);
      } else if (error?.customError){
        throw new CustomError(error);
      }

      // We shouldn't be able to get here, means something
      // happened in setting up the request that triggered an Error
      console.log('Error message: ', error.message);
      console.log('Error config: ', error.config);
      throw new Error('Request finished with errors');
    });
}

let xsrfTokenHeader = '';
let headerListeners = [];
let tokenCallback = () => null;
let standardErrorResponseHandler;
let cookieAuth;

function initializeHeaders(config) {
  const currentConfig = { ...config };

  if (!currentConfig.headers) currentConfig.headers = {};

  if (!cookieAuth) {
    const token = tokenCallback();

    if (token) {
      currentConfig.headers.Authorization = `Bearer ${token}`;
    }
  }

  currentConfig.headers['x-xsrf-token'] = xsrfTokenHeader;

  return currentConfig;
}

function processHeaderListeners(headers) {
  headerListeners.forEach((listener) => {
    if (headers.hasOwnProperty(listener.headerName)) {
      listener.callback(headers[listener.headerName]);
    }
  });
}

function preProcessResponse(response) {
  if (response?.data?.hasException) {
    throw new CustomError(response.data.exception);
  }

  xsrfTokenHeader = response.headers['x-xsrf-token'];

  processHeaderListeners(response.headers);

  return response;
}

function isValidationResponse(response) {
  return response.hasOwnProperty('data') && response.data.hasOwnProperty('errors') && Array.isArray(response.data.errors);
}

function getServerErrorId(response) {
  let referenceId;

  if (response.hasOwnProperty('data')) {
    referenceId = response.data.referenceId;
  }

  return referenceId;
}

httpClient.initialize = (config) => {
  tokenCallback = config.tokenCallback;
  standardErrorResponseHandler = config.standardErrorResponseHandler;
  cookieAuth = config.cookieAuth;

  if (cookieAuth) axiosClient.defaults.withCredentials = true;

  axiosClient.defaults.baseURL = config.baseURL;
  axiosClient.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest';

  initialized = true;
};

httpClient.createCancellationSource = () => {
  const { CancelToken } = axios;

  return CancelToken.source();
};

httpClient.onHeadersReceived = (callback, headerName, listenerName) => {
  // check we don't have a listener for the same header
  const existingListeners = headerListeners.filter((x) => x.headerName === headerName);

  if (existingListeners.length) {
    const sameName = existingListeners.filter((x) => x.listenerName === listenerName);

    if (sameName.length) {
      throw new Error(`Listener ${listenerName} already registered for ${headerName}`);
    }
  }

  headerListeners.push({
    headerName,
    callback,
    listenerName
  });
};

httpClient.removeHeaderListener = (listenerName, headerName) => {
  if (listenerName && !headerName) {
    headerListeners = headerListeners.filter((x) => x.listenerName !== listenerName);
  }

  if (!listenerName && headerName) {
    headerListeners = headerListeners.filter((x) => x.headerName !== headerName);
  }
};

httpClient.clearHeaderListeners = () => {
  headerListeners.length = 0;
};

httpClient.request = makeRequest;

httpClient.head = (url, options = null) => makeRequest({ ...options, method: 'HEAD', url }).then((response) => response);

httpClient.get = (url, options = null) => makeRequest({ ...options, method: 'GET', url }).then((response) => response);

httpClient.put = (url, data, options) => makeRequest({ ...options, method: 'PUT', url, data }).then((response) => response);

httpClient.post = (url, data, options) =>
  makeRequest({
    ...options,
    method: 'POST',
    url,
    data
  }).then((response) => response);

httpClient.delete = (url, config) =>
  makeRequest({
    ...config,
    method: 'DELETE',
    url
  });

function cacheResponseAdapter(adapter) {
  const cache = new Map();

  const sortedParams = (params) => {
    const a = [];
    let qs = '';
    const keys = params ? Object.keys(params) : [];

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];

      a.push(key);
    }

    a.sort();

    for (let i = 0; i < a.length; i++) {
      if (a[i] && params[a[i]]) qs += `${a[i].toLowerCase()}=${params[a[i]].toLowerCase()}`;
    }

    return qs;
  };

  const addToCache = (index, cacheTimeout, config) => {
    const responsePromise = (async () => {
      try {
        const response = await adapter(config);

        cache.set(index, Promise.resolve(response));

        setTimeout(() => cache.delete(index), cacheTimeout);

        return { ...response };
      } catch (error) {
        cache.delete(index);

        return Promise.reject(error);
      }
    })();

    cache.set(index, responsePromise);

    return responsePromise;
  };

  return (config) => {
    const { url, method, cacheTimeout, params } = config;

    const useAdapter = method === 'get' || method === 'head';

    if (!cacheTimeout || !useAdapter) return adapter(config);

    const index = `${url}${sortedParams(params)}`;
    const cachedRequest = cache.get(index);

    if (cachedRequest) {
      return cachedRequest;
    }

    return addToCache(index, cacheTimeout, config);
  };
}

export { httpClient, HttpClientConfig, AxiosRequestConfig as RequestConfig };
