import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import * as authConstants from './constants';

export const initialState = {
  id: undefined,
  name: undefined,
  sessionStatus: {
    status: undefined,
    fetching: false
  },
  claims: [],
  tokenMapping: {
    mapping: undefined,
    status: undefined
  }
};

const setTokenMappingHandler = (state, action) =>
  produce(state, (draft) => {
    draft.tokenMapping = action.payload;
  });

const setUserHandler = (state, action) =>
  produce(state, (draft) => ({
    ...draft,
    ...action.payload
  }));

export const authReducer = handleActions(
  {
    [authConstants.GET_CURRENT_USER_STATE_UPDATE]: setUserHandler,
    [authConstants.GET_TOKEN_MAPPING_STATE_UPDATE]: setTokenMappingHandler
  },
  initialState
);
