import { call, put, takeLatest } from 'redux-saga/effects';

import { deleteUserByClientId } from '../../../services/api/user';
import * as userActions from '../actions';
import * as userConstants from '../constants';

export function* deleteUser(action) {
  const { payload } = action;

  try {
    const response = yield call(deleteUserByClientId, payload);

    if (response?.status === 200) {
      yield put(userActions.handleDeleteUserSuccess(payload));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(userActions.handleDeleteUserFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchDeleteuser() {
  yield takeLatest(userConstants.USERS_DELETE_STARTED, deleteUser);
}
