import * as eventsConstants from './constants';

// Started
export const handleCreateEventStarted = (payload) => ({
  type: eventsConstants.EVENTS_CREATE_STARTED,
  payload
});

export const handleFetchEventStarted = (payload) => ({
  type: eventsConstants.EVENTS_FETCH_STARTED,
  payload
});

export const handleDeleteEventStarted = (payload) => ({
  type: eventsConstants.EVENTS_DELETE_STARTED,
  payload
});

export const handleDownloadSchemaStarted = (payload) => ({
  type: eventsConstants.EVENTS_DOWNLOAD_STARTED,
  payload
});

export const handleUpdateEventStarted = (payload) => ({
  type: eventsConstants.EVENTS_UPDATE_STARTED,
  payload
});

// Success
export const handleCreateEventSuccess = (payload) => ({
  type: eventsConstants.EVENTS_CREATE_SUCCESS,
  payload
});

export const handleFetchEventSuccess = (payload) => ({
  type: eventsConstants.EVENTS_FETCH_SUCCESS,
  payload
});

export const handleDeleteEventSuccess = (payload) => ({
  type: eventsConstants.EVENTS_DELETE_SUCCESS,
  payload
});

export const handleDownloadSchemaSuccess = (payload) => ({
  type: eventsConstants.EVENTS_DOWNLOAD_SUCCESS,
  payload
});

export const handleUpdateEventSuccess = (payload) => ({
  type: eventsConstants.EVENTS_UPDATE_SUCCESS,
  payload
});

// Failure
export const handleCreateEventFailure = (payload) => ({
  type: eventsConstants.EVENTS_CREATE_FAILURE,
  payload
});

export const handleFetchEventFailure = (payload) => ({
  type: eventsConstants.EVENTS_FETCH_FAILURE,
  payload
});

export const handleUpdateEventailure = (payload) => ({
  type: eventsConstants.EVENTS_UPDATE_FAILURE,
  payload
});

export const handleDeleteEventFailure = (payload) => ({
  type: eventsConstants.EVENTS_DELETE_FAILURE,
  payload
});

export const handleCloseCreateModal = (payload) => ({
  type: eventsConstants.EVENTS_CLOSE_EDIT_REDUCER,
  payload
});

export const handleCloseDeleteModal = (payload) => ({
  type: eventsConstants.EVENTS_CLOSE_DELETE_REDUCER,
  payload
});

export const handleCloseResultModal = (payload) => ({
  type: eventsConstants.EVENTS_CLOSE_RESULT_REDUCER,
  payload
});

export const handleDownloadSchemaFailure = (payload) => ({
  type: eventsConstants.EVENTS_DOWNLOAD_FAILURE,
  payload
});

export const handleOpenDetailsPanel = (payload) => ({
  type: eventsConstants.EVENTS_DETAILS_OPEN_REDUCER,
  payload
});

export const handleCloseDetailsPanel = (payload) => ({
  type: eventsConstants.EVENTS_DETAILS_CLOSE_REDUCER,
  payload
});

export const handleSetActiveEvent = (payload) => ({
  type: eventsConstants.EVENTS_SET_ACTIVE_REDUCER,
  payload
});

export const handleFormChange = (payload) => ({
  type: eventsConstants.EVENTS_FORM_CHANGE_REDUCER,
  payload
});

export const handleStartValidation = (payload) => ({
  type: eventsConstants.EVENTS_VALIDATION_STARTED,
  payload
});

export const handleValidationSuccess = (payload) => ({
  type: eventsConstants.EVENTS_VALIDATION_SUCCESS,
  payload
});

export const handleValidationFailure = (payload) => ({
  type: eventsConstants.EVENTS_VALIDATION_FAILURE,
  payload
});

export const handleSetEventEditable = (payload) => ({
  type: eventsConstants.EVENTS_SET_EDITABLE_REDUCER,
  payload
});

export const handleCancelEditStarted = (payload) => ({
  type: eventsConstants.EVENTS_FORM_CANCEL_EDIT_REDUCER,
  payload
});
