import { Button, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';

import { store } from '../../configureStore';
import { currentUserManager } from './userManager';

class HomeView extends Component {
  constructor(props) {
    super(props);
    this.onLogoutButtonClick = this.onLogoutButtonClick.bind(this);
    this.userManager = currentUserManager;
  }

  componentWillMount() {
    if (this.props.user) {
      this.props.onTokenMapping();
    }
  }

  onLogoutButtonClick(event) {
    event.preventDefault();
    this.userManager.signoutRedirect();
  }

  render() {
    const { user } = this.props;

    if (!this.props.user || this.props.user.expired) {
      return <Redirect to="/login" />;
    }

    return (
      <OidcProvider store={store} userManager={this.userManager}>
        <>
          <Typography align="center" variant="title" gutterBottom></Typography>
          <Button onClick={this.onLogoutButtonClick} color="primary">
            Logout
          </Button>
          <div>{user && user.profile && `${user.profile.first_name} ${user.profile.last_name}`}</div>
        </>
      </OidcProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.rootui.oidc.user,
  claims: state.rootui.user.claims,
  token: state.rootui.user.tokenMapping
});

const mapPropsToDispatch = (dispatch) => ({
  onLoggedIn: () => {
    dispatch();
  },
  onTokenMapping: () => {
    dispatch();
  }
});

export const Home = connect(mapStateToProps, mapPropsToDispatch)(HomeView);
