import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomComboBox, CustomFileUpload, CustomTextField } from '../../shared';
import { FormError } from '../../shared/forms';
import { styles } from '../styles';
import { CustomVersionChangeForm } from './CustomVersionChangeForm';

const noFunc = () => {};
const CreateUpdateEventForm = ({
  classes,
  event,
  handleChange,
  readOnly = { textField: false, comboBox: false, revision: true, checkbox: true, uploadFile: false },
  products,
  isMajorUpdate,
  duplicatedFormat = { hasError: false, message: '' },
  error
}) => {
  const { eventTypeName, productName, revision } = event;

  return (
    <>
      {duplicatedFormat.hasError && <Alert severity="error">{duplicatedFormat.message}</Alert>}
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={1}>
          <Grid key="event-name" item xs={12} className={classes.row}>
            <CustomTextField
              disabled={readOnly.textField}
              id="event-name"
              inputName="eventTypeName"
              label="Name"
              placeholderText="Enter a name..."
              inputType="text"
              enableLabel
              isRequired
              value={eventTypeName ?? ''}
              onChangeEvent={(s) => (readOnly.textField ? noFunc() : handleChange('eventTypeName', s.eventTypeName))}
              onBlurEvent={(s) => (readOnly.textField ? noFunc() : handleChange('eventTypeName', s.eventTypeName))}
            />
          </Grid>
          <Grid key="event-product" item xs={12} className={classes.row}>
            <CustomComboBox
              disabled={readOnly.comboBox}
              id="event-product"
              enableLabelText
              labelText="Product"
              placeholderText={productName ?? 'Select One...'}
              visible
              isRequired
              options={products}
              value={productName ?? ''}
              onChangeEvent={(s) => (readOnly.comboBox ? noFunc() : handleChange('productName', s.value))}
            />
          </Grid>
          {!readOnly.revision && (
            <Grid key="event-revision" item xs={12} className={classes.row}>
              <CustomTextField
                disabled
                id="event-revision"
                inputName="eventRevision"
                label="Revision"
                placeholderText=""
                inputType="text"
                enableLabel
                isRequired
                value={revision ?? ''}
                onChangeEvent={noFunc}
                onBlurEvent={noFunc}
              />
            </Grid>
          )}

          {!readOnly.checkbox && (
            <Grid key="event-typeOfChange" item xs={12} className={classes.row}>
              <CustomVersionChangeForm handleChange={handleChange} value={isMajorUpdate} />
            </Grid>
          )}

          {!readOnly.uploadFile && (
            <Grid key="event-upload" item xs={12} className={classes.row}>
              <CustomFileUpload
                onChange={(file) => {
                  const hasError = !file || file.length === 0;
                  const jsonData = hasError ? null : file[0].data;

                  handleChange('json', jsonData);
                }}
              />
            </Grid>
          )}
          {error?.hasError && <FormError message={error.message} classes={classes} />}
        </Grid>
      </form>
    </>
  );
};

CreateUpdateEventForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  readOnly: PropTypes.object,
  event: PropTypes.object.isRequired,
  products: PropTypes.array,
  isMajorUpdate: PropTypes.bool,
  error: PropTypes.object
};

export const EventForm = withStyles(styles)(CreateUpdateEventForm);
