import { CircularProgress, IconButton, InputBase, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { PropTypes } from 'prop-types';
import React from 'react';

import { RETURN_KEY_CODE } from '../sharedConstants';
import { styles } from './SearchBoxStyle';

const CustomSearchBox = (props) => {
  const { id, inputName, placeholderText, inputType, onChangeEvent, onBlurEvent, onKeyUpEvent, onKeyDownEvent, isfetching, classes, ...rest } = props;

  const handleOnChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { name } = event.target;
    const { value } = event.target;

    if (value.length >= 3) {
      onChangeEvent({ [name]: value });
    }
  };

  const handleOnBlur = (event) => {
    if (onBlurEvent) {
      onBlurEvent(event.target.value);
    }
  };

  const handleOnKeyDown = (event) => {
    // We validate the Enter key code to execute the prop event
    if (event.keyCode === RETURN_KEY_CODE) {
      onKeyDownEvent();
      event.preventDefault();
    }
  };

  const handleOnKeyUp = () => {
    onKeyUpEvent();
  };

  return (
    <>
      <Paper elevation={0} component="form" className={classes.root} {...rest}>
        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          id={id}
          name={inputName}
          className={classes.inputText}
          placeholder={placeholderText}
          type={inputType}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onKeyUp={handleOnKeyUp}
          onKeyDown={handleOnKeyDown}
        />
        {isfetching ? <CircularProgress variant="indeterminate" disableShrink className={classes.spinnerStyle} size={18} thickness={4} /> : null}
      </Paper>
    </>
  );
};

CustomSearchBox.propTypes = {
  id: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  onChangeEvent: PropTypes.func.isRequired,
  onBlurEvent: PropTypes.func.isRequired,
  onKeyUpEvent: PropTypes.func,
  onKeyDownEvent: PropTypes.func,
  isfetching: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

export const SearchBox = withStyles(styles)(CustomSearchBox);
