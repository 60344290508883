import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';

import { authReducer } from './auth/reducers';
import { commonReducer } from './common/reducers';
import { consumerGroupReducer } from './consumerGroups/reducers';
import { consumerReducer } from './consumers/reducers';
import { eventReducer } from './events/reducers';
import { subscriptionReducer } from './subscriptions/reducers';
import { topicReducer } from './topics/reducers';
import { userReducer } from './users/reducers';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers = {}, history) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    oidc: oidcReducer,
    subscriptions: subscriptionReducer,
    events: eventReducer,
    consumers: consumerReducer,
    consumerGroups: consumerGroupReducer,
    topics: topicReducer,
    users: userReducer,
    common: commonReducer,
    ...injectedReducers
  });

  return rootReducer;
}
