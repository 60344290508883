import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from '../styles';

const versionChangeForm = ({ handleChange, value }) => (
  <FormControlLabel
    control={<Checkbox checked={value} onChange={(s) => handleChange('isMajorUpdate', s.target.checked)} name="MajorChecked" color="primary" />}
    label="Is Major change?"
  />
);

versionChangeForm.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.bool.isRequired
};

export const CustomVersionChangeForm = withStyles(styles)(versionChangeForm);
