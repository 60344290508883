export const getSubscriptions = (state) => state.subscriptions.data;
export const getIsCreateModalOpen = (state) => state.subscriptions.isCreateModalOpen;
export const getIsDeleteModalOpen = (state) => state.subscriptions.isDeleteModalOpen;
export const getIsLoading = (state) => state.subscriptions.isLoading;
export const getActiveSubscriptionId = (state) => state.subscriptions.activeSubscriptionId;
export const getSelectedSubscription = (state) => state.subscriptions.data.find((s) => s.id === getActiveSubscriptionId(state));
export const getIsDetailsPanelOpen = (state) => state.subscriptions.isDetailsPanelOpen;
export const getChanges = (state) => state.subscriptions.changes;
export const getError = (state) => state.subscriptions.error;
export const getIsResultModalOpen = (state) => state.subscriptions.isResultModalOpen;
