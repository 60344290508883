import { Box, FormControl, FormHelperText, InputBase, InputLabel, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from './CustomTextFieldStyle';

const CustomTextFieldComponent = (props) => {
  const { id, inputName, label, placeholderText, inputType, onChangeEvent, onBlurEvent, classes, enableLabel, isRequired, ...rest } = props;
  const [inputFilled, setInputFilled] = React.useState(false);
  const error = isRequired && inputFilled;
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    if (onChangeEvent) {
      onChangeEvent({ [name]: value });
    }
  };
  const handleOnBlur = (event) => {
    setInputFilled(event.target.value.length === 0);
    if (onBlurEvent) {
      const { name, value } = event.target;

      onBlurEvent({ [name]: value });
    }
  };

  return (
    <>
      {enableLabel ? (
        <Box component="div" key="main-input-wrapper">
          {enableLabel ? <InputLabel className={classes.label}>{label}</InputLabel> : null}
          <FormControl key="main-input-from-control" required={isRequired} error={error} style={{ width: '100%' }}>
            <Paper elevation={0} className={classes.root}>
              <InputBase
                key="base-input"
                id={id}
                name={inputName}
                className={classes.inputText}
                placeholder={placeholderText}
                type={inputType}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                error
                {...rest}
              />
            </Paper>
            {error && <FormHelperText className={classes.labelControl}>{label} is required</FormHelperText>}
          </FormControl>
        </Box>
      ) : null}
    </>
  );
};

CustomTextFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  inputName: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholderText: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  onChangeEvent: PropTypes.func.isRequired,
  onBlurEvent: PropTypes.func,
  enableLabel: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  value: PropTypes.string
};

export const CustomTextField = withStyles(styles)(CustomTextFieldComponent);
