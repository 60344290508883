import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { currentUserManager } from '../../components/auth/userManager';
import { getTokenMapping } from '../../services/userService';
import * as authActions from './actions';
import * as authConstants from './constants';

function* handleGetTokenMappingStart(action) {
  try {
    const { payload } = action;
    const tokenMapping = yield call(getTokenMapping, payload);

    yield put(authActions.updateGetTokenMappingState(tokenMapping));
    yield put(authActions.handleGetTokenMappingWorkFlowSuccess());
    yield put(authActions.handleGetTokenMappingWorkFlowFinished());
    if (tokenMapping.status !== 'OK') yield put(push('/unauthorized'));
  } catch (error) {
    yield put(authActions.handleGetTokenMappingWorkFlowError(error));
    yield put(authActions.handleGetTokenMappingWorkFlowFinished());
  }
}

function* handleGetUserStart() {
  try {
    yield put(authActions.handleGetCurrentUserWorkFlowSuccess());
    if (window.location.pathname.includes('/callback')) {
      yield put(push('/subscriptions'));
    }

    yield put(authActions.handleGetCurrentUserWorkFlowFinished());
  } catch (error) {
    // TODO: CREATE ERROR PAGE
    // yield put(push('/error'));
    yield put(authActions.handleGetCurrentUserWorkFlowError(error));
    yield put(authActions.handleGetCurrentUserWorkFlowFinished());
  }
}

function* handleUpdateUser(action) {
  // create a cookie that is synchronized with the id_token
  const { payload } = action;
  const tokenMapping = yield call(getTokenMapping, payload);
  if (currentUserManager && tokenMapping.status !== 'OK') {
    const logoutEndpoint = `${window.REACT_APP_SSO_DOMAIN}/auth/realms/${window.REACT_APP_SSO_CLIENT_REALM}/protocol/openid-connect/logout`;
    const postLogoutRedirectUri = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`;
    setTimeout(() => { window.location.href = `${logoutEndpoint}?redirect_uri=${postLogoutRedirectUri}`; }, 5000);
    return;
  }
  const expires = yield call(() => new Date(1000 * action.payload.expires_at));

  document.cookie = `id_token=${action.payload.id_token}; expires=${expires.toUTCString()}; path=/`;
}

export function* authSaga() {
  yield takeLatest(authConstants.GET_TOKEN_MAPPING_WORKFLOW_STARTED, handleGetTokenMappingStart);
  // After finishing the getTokenMapping, it is requested the current user
  yield takeLatest(authConstants.GET_TOKEN_MAPPING_WORKFLOW_SUCCESED, handleGetUserStart);
  yield takeLatest(authConstants.USER_FOUND_STATE_UPDATE, handleUpdateUser);
}
