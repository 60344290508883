const prefix = 'COMMON';

// Saga
export const COMMON_PRODUCTS_FETCH_STARTED = `${prefix}_PRODUCTS_FETCH_STARTED`;
export const COMMON_CUSTOMERS_FETCH_STARTED = `${prefix}_CUSTOMERS_FETCH_STARTED`;
export const COMMON_STUDIES_FETCH_STARTED = `${prefix}_STUDIES_FETCH_STARTED`;
export const COMMON_SPONSORS_FETCH_STARTED = `${prefix}_SPONSORS_FETCH_STARTED`;
export const COMMON_CLIENTIDS_FETCH_STARTED = `${prefix}_CLIENTIDS_FETCH_STARTED`;

export const COMMON_PRODUCTS_FETCH_SUCCESS = `${prefix}_PRODUCTS_FETCH_SUCCESS`;
export const COMMON_CUSTOMERS_FETCH_SUCCESS = `${prefix}_CUSTOMERS_FETCH_SUCCESS`;
export const COMMON_STUDIES_FETCH_SUCCESS = `${prefix}_STUDIES_FETCH_SUCCESS`;
export const COMMON_SPONSORS_FETCH_SUCCESS = `${prefix}_SPONSORS_FETCH_SUCCESS`;
export const COMMON_CLIENTIDS_FETCH_SUCCESS = `${prefix}_CLIENTIDS_FETCH_SUCCESS`;

export const COMMON_PRODUCTS_FETCH_FAILURE = `${prefix}_PRODUCTS_FETCH_FAILURE`;
export const COMMON_CUSTOMERS_FETCH_FAILURE = `${prefix}_CUSTOMERS_FETCH_FAILURE`;
export const COMMON_STUDIES_FETCH_FAILURE = `${prefix}_STUDIES_FETCH_FAILURE`;
export const COMMON_SPONSORS_FETCH_FAILURE = `${prefix}_SPONSORS_FETCH_FAILURE`;
export const COMMON_CLIENTIDS_FETCH_FAILURE = `${prefix}_CLIENTIDS_FETCH_FAILURE`;

// Reducer

export const COMMON_PRODUCTS_SET_REDUCER = `${prefix}_PRODUCTS_SET_REDUCER`;
export const COMMON_CUSTOMERS_SET_REDUCER = `${prefix}_CUSTOMERS_SET_REDUCER`;
export const COMMON_SELECTED_CUSTOMER_SET_REDUCER = `${prefix}_SELECTED_CUSTOMER_SET_REDUCER`;
export const COMMON_STUDIES_SET_REDUCER = `${prefix}_STUDIES_SET_REDUCER`;
export const COMMON_SPONSORS_SET_REDUCER = `${prefix}_SPONSORS_SET_REDUCER`;
