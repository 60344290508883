export class Consumer {
  userName;
  topicName;
  subscriptionIds;
  id;

  constructor({ userName = '', topicName = '', id = '', subscriptionIds = [], active = null }) {
    this.userName = userName;
    this.topicName = topicName;
    this.subscriptionIds = subscriptionIds;
    this.id = id;
  }

  getViewModel() {
    let subscriptionsCount = !this.subscriptionIds.length ? 0 : this.subscriptionIds.length;

    return {
      id: this.id,
      userName: this.userName ?? '',
      topicName: this.topicName ?? '',
      consumerId: this.id ?? '',
      subscriptionsCount: subscriptionsCount
    };
  }
}
