import { httpClient } from '../../utils/httpClient';

const BaseApiUrl = window.REACT_APP_API_ROUTE;

export const getApi = async (route, params = {}) => {
  const url = `${BaseApiUrl}/${route}`;
  const result = await httpClient.get(url, { params, isTokenRequired: true });

  return result;
};

export const putApi = async (route, data, params = {}) => {
  const url = `${BaseApiUrl}/${route}`;

  const result = await httpClient.put(url, data, { params, isTokenRequired: true });

  return result;
};

export const postApi = async (route, data = {}, params = {}) => {
  const url = `${BaseApiUrl}/${route}`;

  const result = await httpClient.post(url, data, { params, isTokenRequired: true });

  return result;
};

export const deleteApi = async (route, params = {}) => {
  const url = `${BaseApiUrl}/${route}`;

  const result = await httpClient.delete(url, { params, isTokenRequired: true });

  return result;
};
