import { call, put, takeLatest } from 'redux-saga/effects';

import { Subscription } from '../../../models/subscriptions/subscription';
import { getAllSubscriptions } from '../../../services/api/subscription';
import * as subscriptionsActions from '../actions';
import * as subscriptionsConstants from '../constants';

export function* fetchSubscription() {
  try {
    let data = [];
    const response = yield call(getAllSubscriptions);

    if (response?.status === 200) {
      data = response.data.payload.map((s) => new Subscription(s));
      yield put(subscriptionsActions.handleFetchSubscriptionSuccess(data));

      return;
    }

    throw new Error(response?.statusText);
  } catch (error) {
    yield put(subscriptionsActions.handleFetchSubscriptionFailure(error));
    // TODO: navigate to error page
  }
}

export function* watchFetchSubscription() {
  yield takeLatest(subscriptionsConstants.SUBSCRIPTIONS_FETCH_STARTED, fetchSubscription);
}
