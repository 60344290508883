import { Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { FormikAutocompleteField, FormikInputField } from '../../shared/formikInput';

const filter = createFilterOptions({
  trim: true
});

export const ConsumerForm = ({
  classes,
  onSave,
  isSaving,
  onSubmit,
  getSubscriptionsByTopicName,
  onValidationChange,
  subscriptionList,
  consumers,
  enableAddSubscriptions,
  isEditMode = false,
  readOnly = false,
  users,
  initialValues = { userName: '', topicName: '', consumerSubscriptions: [] }
}) => {
  const [sameLevel, setSameLevel] = useState(subscriptionList);
  const showHierarchyAlert = isEditMode ? true : enableAddSubscriptions;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: (values) => {
      const errors = {};
      if (!values.userName) {
        errors.userName = 'The ClientId is required.';
      } else if (consumers.some((c) => c.userName === values.userName) && !readOnly) {
        errors.userName = 'The ClientId already exists.';
      }
      if (!values.topicName) {
        errors.topicName = 'The TopicName is required.';
      } else if (!/^[a-zA-Z0-9.]+$/i.test(values.topicName.value)) {
        errors.topicName = 'TopicName has invalid characters. Allowed character: \'.\'';
      }
      if (!values.consumerSubscriptions.length) {
        errors.consumerSubscriptions = 'You must select at least one subscription.';
      }
      return errors;
    },
    onSubmit: (values) => {
      const { userName, topicName, consumerSubscriptions } = values;
      onSubmit({ userName: userName.value, topicName: topicName.value, subscriptionIds: consumerSubscriptions.map((s) => s.value) });
    }
  });

  useEffect(() => {
    onValidationChange(formik.dirty && formik.isValid);
  }, [formik.dirty, formik.isValid]);

  useEffect(() => {
    if (isSaving) {
      formik.handleSubmit();
      onSave();
    }
  }, [isSaving]);

  useEffect(() => {
    if (!isEditMode) {
      const subscriptionsByTopicName = getSubscriptionsByTopicName(formik.values.topicName);
      formik.setFieldValue('consumerSubscriptions', subscriptionsByTopicName);
    }
  }, [formik.values.topicName]);

  useEffect(() => {
    const newValues = formik.values.consumerSubscriptions.map((x) => x.name);
    setSameLevel(newValues);
  }, [formik.values.consumerSubscriptions]);

  return (
    <>
      {Boolean(formik.values.consumerSubscriptions.length) && showHierarchyAlert && (
        <Alert severity="info">In order to select disabled subscriptions, remove the subscriptions with similar routing.</Alert>
      )}
      <FormikProvider value={formik}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={1}>
            <Grid key="consumerForm" item xs={12} className={classes.row}>
              <FormikAutocompleteField
                required={true}
                name="topicName"
                label="TopicName"
                filterOptions={(ops, params) => {
                  const filtered = filter(ops, params);
                  const { inputValue } = params;
                  const valueExist = filtered.some(({ value }) => value.toLowerCase() == inputValue.trim().toLowerCase());
                  // Suggest the creation of a new value
                  if (inputValue.trim() !== '' && !valueExist) {
                    filtered.push({
                      inputValue,
                      name: `Add "${inputValue.trim()}"`
                    });
                  }

                  return filtered;
                }}
                options={Array.from(new Set(consumers.map((c) => c.topicName))).map((topicName) => {
                  return {
                    value: topicName,
                    name: topicName
                  };
                })}
                readOnly={readOnly}
                defaultValue={initialValues.topicName}
                value={formik.values.topicName}
                touched={formik.touched.topicName}
              />
              <FormikAutocompleteField
                required={true}
                name="userName"
                label="ClientId"
                options={users}
                readOnly={isEditMode}
                defaultValue={initialValues.userName}
                value={formik.values.userName}
                touched={formik.touched.userName}
                filterSelectedOptions
              />
              <FormikAutocompleteField
                getOptionDisabled={(option) => !!sameLevel.find((x) => option.name.includes(x) || x.includes(option.name))}
                required={true}
                multiple
                name="consumerSubscriptions"
                label="Subscriptions"
                options={subscriptionList}
                readOnly={isEditMode ? false : !enableAddSubscriptions}
                defaultValue={initialValues.consumerSubscriptions}
                value={formik.values.consumerSubscriptions}
                touched={formik.touched.consumerSubscriptions}
                filterSelectedOptions
              />
              {!isEditMode ? (
                <Typography align="justify" variant="caption" color="textSecondary">
                  When an existent consumer is selected, its subscriptions will be loaded for the current consumer.
                </Typography>
              ) : (
                <Typography align="justify" variant="caption" color="textSecondary">
                  When consumers with the same consumer name are updated, their subscriptions will also be updated. As their subscriptions should be
                  the same.
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </>
  );
};
