export class User {
    clientId;
    clientSecret;
    scopes;

    constructor({ clientId = '', clientSecret = '', scopes = [], active = null }) {
        this.clientId = clientId;
        this.clientSecret = clientSecret;
        this.scopes = scopes.filter((s) => s.includes("scp:") ).join(",").replace(/scp:/g, '');
    }

    getViewModel() {
        return {
            id: this.clientId,
            clientId: this.clientId ?? '',
            scopesArray: this.scopes ?? ''
        };
    }
}