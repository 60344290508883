import { default as produce } from 'immer';
import { handleActions } from 'redux-actions';

import * as subscriptionsConstants from './constants';

export const initialState = {
  data: [],
  isLoading: false,
  error: { hasError: false, message: '' },
  changes: {},
  isCreateModalOpen: false,
  isDeleteModalOpen: false,
  activeSubscriptionId: null,
  isDetailsPanelOpen: false,
  isResultModalOpen: false
};

const failureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.error = {
      hasError: true,
      message
    };
  });

const deleteFailureHandler = (state, action) =>
  produce(state, (draft) => {
    const { payload } = action;
    const message = typeof payload === 'object' ? payload.message : payload;

    draft.isLoading = false;
    draft.isResultModalOpen = true;
    draft.error = {
      hasError: true,
      message
    };
  });

const deleteSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    const filteredData = state.data.filter((e) => e.id !== action.payload);
    const hasPanelState = state.isDetailsPanelOpen && filteredData.length > 0;

    draft.isLoading = false;
    draft.error = initialState.error;
    draft.activeSubscriptionId = hasPanelState ? filteredData[0].id : initialState.activeSubscriptionId;
    draft.isDetailsPanelOpen = hasPanelState;
    draft.data = filteredData;
  });

const updateSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    const { id } = action.payload;
    const subscriptionIndex = state.data.findIndex((s) => s.id === id);
    const dataClone = [...state.data];

    dataClone.splice(subscriptionIndex, 1, action.payload);
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.changes = initialState.changes;

    draft.data = dataClone;
  });

const createSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = initialState.isLoading;
    draft.error = initialState.error;
    draft.changes = initialState.changes;
    draft.data = [action.payload].concat(state.data);
    draft.isCreateModalOpen = initialState.isCreateModalOpen;
    draft.isResultModalOpen = true;
  });

const fetchSuccessHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.error = initialState.error;
    draft.data = action.payload;
  });

const actionStartedHandler = (state) =>
  produce(state, (draft) => {
    draft.isLoading = true;
  });

const closeCreateModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.activeSubscriptionId = initialState.activeSubscriptionId;
    draft.isDetailsPanelOpen = initialState.isDetailsPanelOpen;
    draft.isCreateModalOpen = !action.payload;
    draft.isResultModalOpen = false;
    draft.error = initialState.error;
  });

const closeResultModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isResultModalOpen = !action.payload;
  });

const closeDeleteModalHandler = (state, action) =>
  produce(state, (draft) => {
    draft.isDeleteModalOpen = !action.payload;
  });

const setActiveHandler = (state, action) =>
  produce(state, (draft) => {
    draft.changes = initialState.changes;
    draft.error = initialState.error;

    draft.activeSubscriptionId = action.payload;
  });

const openDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = true;
  });

const closeDetailsPanelHandler = (state) =>
  produce(state, (draft) => {
    draft.isDetailsPanelOpen = false;
  });

const formChangeHandler = (state, action) => {
  const { newFormState } = action.payload;

  return produce(state, (draft) => {
    draft.error = initialState.error;
    draft.changes = { ...state.changes, ...newFormState };
  });
};

const formCancelEdit = (state) =>
  produce(state, (draft) => {
    draft.changes = initialState.changes;
  });

export const subscriptionReducer = handleActions(
  {
    [subscriptionsConstants.SUBSCRIPTIONS_CLOSE_DELETE_REDUCER]: closeDeleteModalHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_CLOSE_EDIT_REDUCER]: closeCreateModalHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_CLOSE_RESULT_REDUCER]: closeResultModalHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_CREATE_FAILURE]: failureHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_CREATE_STARTED]: actionStartedHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_CREATE_SUCCESS]: createSuccessHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_DELETE_FAILURE]: deleteFailureHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_DELETE_STARTED]: actionStartedHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_DELETE_SUCCESS]: deleteSuccessHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_DETAILS_CLOSE_REDUCER]: closeDetailsPanelHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_DETAILS_OPEN_REDUCER]: openDetailsPanelHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_FETCH_FAILURE]: failureHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_FETCH_STARTED]: actionStartedHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_FETCH_SUCCESS]: fetchSuccessHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_FORM_CANCEL_EDIT_REDUCER]: formCancelEdit,
    [subscriptionsConstants.SUBSCRIPTIONS_FORM_CHANGE_REDUCER]: formChangeHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_SET_ACTIVE_REDUCER]: setActiveHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_UPDATE_FAILURE]: failureHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_UPDATE_STARTED]: actionStartedHandler,
    [subscriptionsConstants.SUBSCRIPTIONS_UPDATE_SUCCESS]: updateSuccessHandler
  },
  initialState
);
